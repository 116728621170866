export default [
  {
    key: 'background',
    label: 'Fondo',
    default: '#FFFFFF',
  },
  {
    key: 'text',
    label: 'Texto',
    default: '#222222',
  },
  {
    key: 'inputs',
    label: 'Números editables',
    default: '#222222',
  },
  {
    key: 'result',
    label: 'Resultado simulación',
    default: '#FF520F',
  },
  {
    key: 'highlights',
    label: 'Acento',
    default: '#FF520F',
  },
  {
    key: 'disclaimer',
    label: 'Letra chica',
    default: '#AAAAAA',
  },
  {
    key: 'separator',
    label: 'Borde separador',
    default: '#E4E4E4',
  },
  {
    key: 'bubble',
    label: 'Fondo de burbuja',
    default: '#DDDDDD',
  },
  {
    key: 'feedback_background',
    label: 'Fondo resultados',
    default: '#313745',
  },
  {
    key: 'feedback_text',
    label: 'Letra resultados',
    default: '#FAFAFA',
  },
  {
    key: 'feedback_back',
    label: 'Botón resultados',
    default: '#DA4453',
  },
];
