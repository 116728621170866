<template>
  <div class="feedback">
    <div class="feedback__greeting">
      <span class="feedback__hi">¡Hola!</span> {{ form.name }},
    </div>
    <div class="feedback__message">
      Necesitamos más información para completar tu evaluación.
    </div>
    <div class="feedback__message">
      ¡Acércate al concesionario más cercado de la marca "{{ form.vehicleBrandName }}" para que <br>
      un ejecutivo de AMICAR te ayude a gestionar y solicitar el crédito!
    </div>
    <button
      class="feedback__button"
      @click="find()"
    >
      Encuéntralo aquí
    </button>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    find() {
      this.$emit('find');
    },
  },
};
</script>

