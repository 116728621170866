class StrapiService {
  constructor() {
    this.headers = new Headers();
    this.defaultOptions = { headers: this.headers, method: 'GET' };
  }

  get_faqs() {
    return fetch(`${window.location.origin}/faq_info`, {
      ...this.defaultOptions,
    }).then(res => res.json());
  }

  get_brands() {
    return fetch(`${window.location.origin}/strapi_brands`, {
      ...this.defaultOptions,
    }).then(res => res.json());
  }
}

export default StrapiService;