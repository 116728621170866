<template>
  <div class="scenarios__component">
    <div class="scenario-overview">
      <div class="scenario-overview__head">
        <div class="scenarios__row scenarios__row--prominent">
          Condiciones del crédito
        </div>
        <div>
          Selecciona el precio del auto, el pie y el número de cuotas del crédito que quieres evaluar.
        </div>
      </div>
      <div class="scenario-overview__numbers">
        <div class="scenarios__row">
          <div>Elige el precio del auto</div>
          <div class="scenarios__select-arrows" />
          <select
            class="scenarios__input scenarios__input--select"
            v-model="amount"
          >
            <option
              v-for="amountOption in amountOptions"
              :key="amountOption"
              :value="amountOption"
            >
              {{ $n(amountOption, 'currency') }}
            </option>
          </select>
        </div>
        <div class="scenarios__row">
          <div>Elige el pie</div>
          <div class="scenarios__select-arrows" />
          <select
            class="scenarios__input scenarios__input--select"
            v-model="downPaymentPercentage"
          >
            <option
              v-for="downPaymentPercentageOption in downPaymentPercentageOptions"
              :key="downPaymentPercentageOption"
              :value="downPaymentPercentageOption"
            >
              {{ downPaymentPercentageOption }}%
            </option>
          </select>
        </div>
        <div class="scenarios__row scenarios__row--light">
          <div>Equivalente a</div>
          <div>{{ $n(downPayment, 'currency') }}</div>
        </div>
        <div class="scenarios__row">
          <div>Nº de Cuotas</div>
          <div class="scenarios__select-arrows" />
          <select
            class="scenarios__input scenarios__input--select"
            v-model="term"
          >
            <option
              v-for="termOption in termOptions"
              :key="termOption"
              :value="termOption"
            >
              {{ termOption }} Cuotas
            </option>
          </select>
        </div>
      </div>
      <div class="scenario-overview__picture" />
      <div class="scenario-overview__separator" />
      <div class="scenario-overview__head--middle">
        <div class="scenarios__row scenarios__row--prominent scenarios__row--justify-center">
          ¿Cuánto debo pagar mensualmente?
        </div>
        <div>
          Dependerá de las opciones de crédito que hayas tomado. Conoce las 2 modalidades:
        </div>
      </div>
      <div class="scenario-overview__monthly-payment">
        <div class="monthly-payment__container">
          <div class="monthly-payment__title">
            Crédito Convencional
          </div>
          <div class="monthly-payment__row">
            <div>Pagarás {{ term }} cuotas de</div>
            <div class="monthly-payment__amount">
              {{ $n(conventionalMonthlyPayment, 'currency') }}
            </div>
          </div>
        </div>
        <div class="monthly-payment__container">
          <div class="monthly-payment__title monthly-payment__title--smart">
            {{ $t('smartChoice') | titlecase }}
          </div>
          <div class="monthly-payment__row monthly-payment__row--smart">
            <div>Pagarás {{ term }} Cuotas de</div>
            <a
              href="#explicacion"
              class="monthly-payment__amount monthly-payment__amount--smart"
            >
              {{ $n(intelligentMonthlyPayment, 'currency') }}<span class="monthly-payment__asterisk">*</span>
            </a>
          </div>
          <div class="monthly-payment__row monthly-payment__row--smart monthly-payment__row--no-border">
            <a
              href="#explicacion"
              class="monthly-payment__extra-explanation"
            >
              + Cuota Nº {{ term + 1 }} de
              {{ $n(vfmg, 'currency') }}<span class="monthly-payment__asterisk">*</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="scenario-conclusions">
      <div
        id="explicacion"
        class="scenarios__row scenarios__row--prominent scenarios__row--justify-center"
      >
        *¿Por qué la cuota de la {{ $t('smartChoice') | lowercase }} es menor?
      </div>
      <div class="scenarios__row">
        <div class="scenarios__row--justify-center scenarios__row--small">
          Porque se pagan cuotas por una parte del crédito, postergando un % del valor del auto para
          la cuota {{ term + 1 }}. Por eso, en general se recomienda que una vez terminado el periodo del crédito optes
          por renovar tu vehículo. Con esto, además evitas mantenciones sobre los 50.000kms, revisiones técnicas
          y gastos en los que se incurre por el desgaste natural de un auto después de 2 años.
        </div>
      </div>

      <div class="scenarios-choices">
        <div
          class="scenarios-choices__choice"
          v-for="scenario in scenarios"
          :key="scenario.label"
        >
          <input
            type="radio"
            class="scenarios-choices__radio"
            :value="scenario"
            :id="scenario.label"
            v-model="selectedScenario"
          >
          <label
            :for="scenario.label"
          >
            {{ scenario.label }}
          </label>
        </div>
      </div>

      <div class="scenario">
        <div class="scenarios__row scenarios__row--prominent">
          {{ selectedScenario.label }}
        </div>
        <div class="scenario__text">
          {{ selectedScenario.text.replace('#term#', `${this.term + 1}`) }}
        </div>
        <div
          class="scenarios__row scenarios__row--spaced scenarios__row--justify-center"
          v-if="selectedScenario.sellLabel"
        >
          <div>Si mi auto tiene {{ this.selectedScenario.resaleLabel }}</div>
          <select
            class="scenarios__input scenarios__input--select scenarios__input--outstanding"
            v-model="resaleOption"
          >
            <option
              v-for="resale in resaleOptions"
              :key="resale.rate"
              :value="resale"
            >
              {{ resale.label }}
            </option>
          </select>
          <div class="bullet-point__icon bullet-point__icon--info bullet-point__icon--superscript" />
        </div>
        <div
          class="scenarios-balance"
          v-if="canReturnToDistributor"
        >
          <template v-if="selectedScenario.sellLabel">
            <div class="scenarios-balance__container">
              <div class="scenarios-balance__term">
                {{ $n(resalePrice, 'currency') }}
              </div>
              <transition name="fade">
                <div
                  class="scenarios-balance__label"
                  :key="selectedScenario.label"
                >
                  {{ selectedScenario.sellLabel }}
                </div>
              </transition>
            </div>
            <div
              class="scenarios-balance__term scenarios-balance__operation"
            >
              -
            </div>
            <div class="scenarios-balance__container scenarios-balance__container--last-term">
              <div class="scenarios-balance__term">
                {{ isBigScreen && selectedScenario.sellLabel ? '' : '-' }}{{ $n(vfmg, 'currency') }}
              </div>
              <div class="scenarios-balance__label">
                Cuota Nº{{ term + 1 }}
              </div>
            </div>
            <div class="scenarios-balance__term scenarios-balance__operation">
              =
            </div>
          </template>
          <div
            class="scenarios-balance__container"
            :class="{'scenarios-balance__container--full-size': !selectedScenario.sellLabel }"
          >
            <div class="scenarios-balance__term">
              {{ selectedScenario.sellLabel ? $n(remanent, 'currency') : `-${$n(vfmg, 'currency')}` }}
            </div>
            <transition name="fade">
              <div
                class="scenarios-balance__label"
                :key="selectedScenario.label"
              >
                {{ selectedScenario.sellLabel && remanent > 0 ?
                  selectedScenario.remanentLabel : negativeRemanentLabel + (term + 1) }}
              </div>
            </transition>
          </div>
        </div>
        <div
          class="scenarios-balance"
          v-if="!canReturnToDistributor"
        >
          Cuando un auto está en {{ resaleOption.label }}s condiciones, es muy difícil que el concesionario lo
          reciba para retoma. Deberás pagar la cuota Nº {{ term + 1 }} o repactar dicha deuda.
        </div>
        <div
          class="bullet-point"
          v-if="selectedScenario.sellLabel"
        >
          <div class="bullet-point__icon bullet-point__icon--info bullet-point__icon--small" />
          <div class="bullet-point__text scenarios-balance__explanation">
            {{ resaleOption.explanation }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*  eslint-disable no-magic-numbers */

import MonthlyPayments from '../utils/monthly-payments';

export default {
  props: {
    conventionalProduct: {
      type: Object,
      default: () => {},
    },
    intelligentProduct: {
      type: Object,
      default: () => {},
    },
    administrationCosts: {
      type: Array,
      default: () => [],
    },
    insuranceRates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      amount: 9000000,
      downPaymentPercentage: 25,
      term: 24,
      downPaymentPercentageOptions: Array.from({ length: 5 }, (v, k) => 20 + k * 5),
      amountOptions: Array.from({ length: 47 }, (v, k) => 7000000 + k * 500000),
      termOptions: [24, 36],
      resaleOptions: {
        excellent: {
          rate24: 0.78,
          rate36: 0.68,
          label: 'muy buena',
          explanation: `Es un caso excepcional, tu auto está en perfecto estado. La carrocería no presenta
          imperfecciones y las mantenciones están al día en el Concesionario autorizado. Su uso fue cercano
          a 10.000 km por año.`,
        },
        good: {
          rate24: 0.6,
          rate36: 0.5,
          label: 'buena',
          explanation: `Tu auto está en muy buen estado. La carrocería no presenta imperfecciones y las
          mantenciones están al día en el Concesionario autorizado. Su uso fue cercano a 15.000 km por año.`,
        },
        regular: {
          rate24: 0.5,
          rate36: 0.4,
          label: 'normal',
          explanation: `Tu auto está en buen estado. La carrocería podría tener rayones o topones menores y
          las mantenciones están al día en el Concesionario autorizado. Su uso fue cercano o superior a
          20.000 km por año.`,
        },
        bad: {
          rate24: 0.3,
          rate36: 0.2,
          label: 'mala',
          explanation: `Tu auto está en buen estado. La carrocería podría tener rayones o topones menores y
          tienes las mantenciones al día pero en talleres no autorizados. También su uso fue elevado,
          es decir, cerca de 20.000 km al año.`,
        },
        worst: {
          rate24: 0,
          rate36: 0,
          label: 'muy mala',
          explanation: `Tu auto no se encuentra en buen estado, fue chocado o tuvo problemas mecánicos,
          probablemente sus mantenciones no están al día y tuvo un uso excesivo, superior a los 25.000 kms al año.`,
        },
      },
      resaleOption: null,
      scenarios: {
        renew: {
          label: 'Renovar',
          text: `Lleva tu auto al concesionario y, cumpliendo las condiciones para ello, te darán un
            valor que contribuirá a saldar la última cuota (Nº#term#) y quedar con un remanente que se destinará
            a pagar el pie para tu próximo auto.`,
          sellLabel: 'Precio de retoma',
          remanentLabel: 'Pie para tu futuro auto',
          resaleLabel: 'retoma',
        },
        sell: {
          label: 'Vender',
          text: `Si no estas conforme con el precio de retoma del concesionario, tienes la opción de vender tu auto de
            manera independiente y tratar de conseguir un mejor precio, procediendo al pago de la última cuota
            del crédito.`,
          sellLabel: 'Valor de venta',
          remanentLabel: 'Saldo a favor',
          resaleLabel: 'reventa',
        },
        keep: {
          label: 'Conservar',
          text: `Si deseas conservar tu vehículo, puedes pagar o refinanciar la última cuota.`,
          sellLabel: false,
        },
      },
      negativeRemanentLabel: 'Saldo pendiente para completar pago de cuota Nº',
      selectedScenario: null,
      monthlyPayments: new MonthlyPayments(this.conventionalProduct, this.intelligentProduct,
        this.administrationCosts, this.insuranceRates),
    };
  },
  beforeMount() {
    this.resaleOption = this.resaleOptions.regular;
    this.selectedScenario = this.scenarios.renew;
  },
  computed: {
    downPayment() {
      return this.amount * this.downPaymentPercentage / 100;
    },
    conventionalMonthlyPayment() {
      return this.monthlyPayments.conventionalMonthlyPayment(this.amount, this.downPayment, this.term);
    },
    intelligentMonthlyPayment() {
      return this.monthlyPayments.intelligentMonthlyPayment(this.amount, this.downPayment, this.term);
    },
    vfmg() {
      return this.monthlyPayments.vfmg(this.amount, this.downPayment, this.term, true);
    },
    resalePrice() {
      const finalRate = this.resaleOption[`rate${this.term}`];

      return Math.round(this.amount * finalRate / 1000) * 1000;
    },
    remanent() {
      return this.resalePrice - this.vfmg;
    },
    isBigScreen() {
      return window.innerWidth >= 768;
    },
    canReturnToDistributor() {
      return this.selectedScenario.label !== 'Renovar' || this.resaleOption.label !== 'muy mala';
    },
  },
  watch: {
    downPaymentPercentage() {
      if (this.downPaymentPercentage > 50) this.downPaymentPercentage = 50;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 1s;
}

.fade-leave-active {
  transition: opacity 0s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
