<template>
  <div class="select-input">
    <span class="select-input__dropdown-arrow" />
    <select
      class="select-input__select"
      @change="onInput"
      :value="value"
      :disabled="disabled"
    >
      <option value="" disabled>{{ placeholder }}</option>
      <template v-if="optgroups.length > 0">
        <optgroup
          v-for="optgroup in optgroups"
          :key="optgroup.key"
          :label="optgroup.key | capitalize"
        >
          <select-option
            v-for="option in optgroup.options"
            :key="option && option.id || option"
            :selected-value="value"
            :option="option"
          />
        </optgroup>
      </template>
      <template v-else>
        <select-option
          v-for="option in options"
          :key="option && option.id || option"
          :selected-value="value"
          :option="option"
        />
      </template>
    </select>
  </div>
</template>

<script>
import SelectOption from './select-option';

export default {
  components: {
    SelectOption,
  },
  props: {
    value: {
      type: [String, Object],
      default: () => null,
    },
    optgroups: {
      type: Array,
      default: () => [],
    },
    options: {
      type: [Array, Object],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: () => 'Seleccione una opción'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    objectValue() {
      const option = this.optgroups.length > 0 ? this.optgroups[0].options[0] : this.options[0];

      return option && option.id;
    },
  },
  methods: {
    onInput(e) {
      if (this.objectValue) {
        this.$emit('input', JSON.parse(e.target.value));
      } else {
        this.$emit('input', e.target.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-input {
  position: relative;
  margin-top: calc(.4 * var(--spacing)) !important;

  &__select {
    font-size: .7em;
    height: 40px;
    padding-left: .6em;
    width: 100%;
    background-color: #fff;
    appearance: none;
    color: #7d7d7d;
    border-radius: 19.5px;
    padding: 11px;
    outline: 0;
    border: 1px solid #aaa;

    select::-ms-expand {
      display: none;
    }
  }

  &__dropdown-arrow {
    height: 40px;
    width: 40px;
    background: url('~images/arrow_drop_down.svg') no-repeat;
    background-size: cover;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
