import currencyToLocale from '../locale/currency-to-locale';
import vMoneyConfigByLocale from '../locale/v-money-config-by-locale';

const baseCurrencies = {
  'es-CL': 'CLP',
  'es-PE': 'PEN',
};

export default {
  computed: {
    vMoneyConfig() {
      const currencyLocale = currencyToLocale[this.currencyType];

      return vMoneyConfigByLocale[currencyLocale];
    },
    i18nCurrencyKey() {
      return `currency-${this.currencyType}`;
    },
    i18nOtherCurrencyKey() {
      if (!['USD', 'PEN'].includes(this.currencyType)) return '';

      return `currency-${this.currencyType === 'PEN' ? 'USD' : 'PEN'}`;
    },
    baseCurrency() {
      return baseCurrencies[this.$i18n.locale];
    },
  },
};
