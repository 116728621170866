<template>
  <!-- eslint-disable vue/html-self-closing vue/component-name-in-template-casing-->
  <div class="simulator-preview">
    <div class="simulator-preview__iframe-container">
      <iframe
        ref="iframe"
        scrolling="no"
        class="simulator-preview__iframe"
        :width="width"
        height="450"
        frameborder="0"
        :src="`/simulator?${queryString}`"
      />
    </div>
    <div
      class="simulator-preview__form"
      :class="{ 'simulator-preview__form--pending': queryString !== computedQueryString }"
    >
      <div class="simulator-preview__controls">
        <div class="simulator-preview__heading">
          Dimensiones
        </div>
        <div class="simulator-preview__control">
          <span>Ancho ({{ width }})</span>
          <div>
            <input
              type="range"
              v-model="width"
              max="1000"
              min="325"
              step="10"
            >
          </div>
        </div>
        <div class="simulator-preview__control">
          <span>Escala textos ({{ fontScale }}%)</span>
          <div>
            <input
              type="range"
              v-model="fontScale"
              max="200"
              min="50"
              step="5"
              @change="updateQueryString()"
            >
          </div>
        </div>
        <div class="simulator-preview__control">
          <span>Escala espaciado vertical ({{ spacingScale }}%)</span>
          <div>
            <input
              type="range"
              v-model="spacingScale"
              max="200"
              min="50"
              step="5"
              @change="updateQueryString()"
            >
          </div>
        </div>
        <template v-if="$i18n.locale === 'es-PE'">
          <div class="simulator-preview__heading">
            Configuración de Moneda
          </div>
          <div class="simulator-preview__control">
            <span>Montos en:</span>
            <div class="simulator-preview__input--block">
              <div>
                <label>Soles</label>
                <input
                  v-model="currencyType"
                  type="radio"
                  value="PEN"
                >
              </div>
              <div>
                <label>Dólares</label>
                <input
                  v-model="currencyType"
                  type="radio"
                  value="USD"
                >
              </div>
            </div>
          </div>
          <div class="simulator-preview__control">
            <span>Tasa de cambio USD a PEN:</span>
            <input
              type="number"
              v-model.number="usdPenExchangeRate"
            >
          </div>
          <div class="simulator-preview__control">
            <span>Permitir al usuario cambiar de moneda:</span>
            <input
              type="checkbox"
              v-model="allowCurrencyChange"
            >
          </div>
        </template>
        <div class="simulator-preview__heading">
          Valores iniciales
        </div>
        <div class="simulator-preview__control">
          <span>Precio del auto</span>
          <money
            v-model="price"
            v-bind="vMoneyConfig"
          />
        </div>
        <div class="simulator-preview__control">
          <span>Pie (%)</span>
          <input
            type="number"
            v-model="downPayment"
          >
        </div>
        <div class="simulator-preview__control">
          <span>Numero de cuotas</span>
          <select v-model="term">
            <option
              v-for="option in termOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div class="simulator-preview__heading">
          Parámetros
        </div>
        <div class="simulator-preview__control">
          <span>Precio mínimo</span>
          <money
            v-model="minPrice"
            v-bind="vMoneyConfig"
          />
        </div>
        <div class="simulator-preview__control">
          <span>Precio máximo</span>
          <money
            v-model="maxPrice"
            v-bind="vMoneyConfig"
          />
        </div>
        <div class="simulator-preview__control">
          <span>Tag manager id</span>
          <input v-model="tagManagerId">
        </div>
        <div class="simulator-preview__control">
          <span>Fuente</span>
          <input v-model="from">
        </div>
        <div class="simulator-preview__control">
          <span>URL Origen</span>
          <input v-model="originUrl">
        </div>
        <div class="simulator-preview__control">
          <span>Selector de pie según</span>
          <select v-model="downPaymentType">
            <option
              v-for="option in downPaymentOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
        <div class="simulator-preview__control">
          <span>Nombre opción inteligente</span>
          <input v-model="intelligentName">
        </div>
        <div class="simulator-preview__control">
          <span>Utilizar formulario</span>
          <input
            type="checkbox"
            v-model="showFormButton"
          >
        </div>
        <template v-if="showFormButton">
          <div class="simulator-preview__heading">
            Configuración de formulario
          </div>
          <div class="simulator-preview__control">
            <span>Usar formulario:</span>
            <div class="simulator-preview__input--block">
              <div>
                <label>Amicar</label>
                <input
                  v-model="useAmicarForm"
                  type="radio"
                  :value="true"
                >
              </div>
              <div>
                <label>Propio</label>
                <input
                  v-model="useAmicarForm"
                  type="radio"
                  :value="false"
                >
              </div>
            </div>
          </div>
          <div 
            v-if="!useAmicarForm"
            class="simulator-preview__control">
            <span>Origen formulario:</span>
            <div class="simulator-preview__input--block">
              <div>
                <label>Externo</label>
                <input
                  v-model="modalForm"
                  type="radio"
                  :value="false"
                >
              </div>
              <div>
                <label>Interno/modal</label>
                <input
                  v-model="modalForm"
                  type="radio"
                  :value="true"
                >
              </div>
            </div>
          </div>
          <div class="simulator-preview__control">
            <span>Texto botón formulario</span>
            <input v-model="formButtonText">
          </div>
          <div
            v-if="!useAmicarForm&&!modalForm"
            class="simulator-preview__control"
          >
            <span>URL Redirección</span>
            <input
              class="simulator-preview__input--wide"
              v-model="redirectionUrl"
              placeholder="Dirección de envío de datos"
            >
          </div>
          <div
            v-if="useAmicarForm"
            class="simulator-preview__control"
          >
            <span>Estado auto</span>
            <select v-model="vehicleCondition">
              <option :value="'show'">
                Seleccionar en formulario
              </option>
              <option :value="'N'">
                Nuevo
              </option>
              <option :value="'U'">
                Usado
              </option>
            </select>
          </div>
          <div
            class="simulator-preview__control"
            v-if="useAmicarForm"
          >
            <span>ID marca auto</span>
            <input
              class="simulator-preview__input--wide"
              v-model="vehicleBrand"
              placeholder="Dejar vacío para elegir en formulario"
            >
          </div>
          <div class="simulator-preview__heading">
            Configuración Respuestas
          </div>
          <div class="simulator-preview__control">
            <span>Caso éxito</span>
            <textarea
              class="simulator-preview__textarea"
              @keyup="checkLineBreak"
              v-model="resultSuccessText"
            />
          </div>
          <div class="simulator-preview__control">
            <span>Caso rechazo</span>
            <textarea
              class="simulator-preview__textarea"
              @keyup="checkLineBreak"
              v-model="resultRejectedText"
            />
          </div>
        </template>
        <div class="simulator-preview__heading">
          Configuración de producto
        </div>
        <template v-if="currencyType !== 'USD' || allowCurrencyChange">
          <div class="simulator-preview__control">
            <span>ID del producto convencional</span>
            <input
              type="number"
              v-model="conventionalExternalId"
            >
          </div>
          <div class="simulator-preview__control">
            <span>ID del producto inteligente</span>
            <input
              type="number"
              v-model="intelligentExternalId"
            >
          </div>
        </template>
        <template v-if="currencyType === 'USD' || allowCurrencyChange">
          <div class="simulator-preview__control">
            <span>ID del producto convencional USD</span>
            <input
              type="number"
              v-model="conventionalUsdExternalId"
            >
          </div>
          <div class="simulator-preview__control">
            <span>ID del producto inteligente USD</span>
            <input
              type="number"
              v-model="intelligentUsdExternalId"
            >
          </div>
        </template>
        <div class="simulator-preview__control">
          <span>Mínimo pie convencional (%)</span>
          <input
            type="number"
            v-model="downPaymentRanges.conventional.min"
          >
        </div>
        <div class="simulator-preview__control">
          <span>Máximo pie convencional (%)</span>
          <input
            type="number"
            v-model="downPaymentRanges.conventional.max"
          >
        </div>
        <div class="simulator-preview__control">
          <span>Mínimo pie inteligente (%)</span>
          <input
            type="number"
            v-model="downPaymentRanges.intelligent.min"
          >
        </div>
        <div class="simulator-preview__control">
          <span>Máximo pie inteligente (%)</span>
          <input
            type="number"
            v-model="downPaymentRanges.intelligent.max"
          >
        </div>
        <div class="simulator-preview__heading">
          Colores
        </div>
        <div
          class="simulator-preview__control"
          v-for="(value, key) in colors"
          :key="key"
        >
          <span>{{ colorLabels[key] }}</span>
          <div class="simulator-preview__color-container">
            <div class="simulator-preview__color">
              <input
                @focus="editColor(key)"
                v-model="colors[key].hex"
              >
              <div
                @click="editColor(key)"
                class="simulator-preview__color-preview"
                :style="{ 'background-color': colors[key].hex }"
              />
            </div>
            <div
              class="simulator-preview__color-picker"
              v-show="editingColor === key"
            >
              <color-picker v-model="colors[key]"></color-picker>
              <button @click="editColor(null)">
                Listo
              </button>
            </div>
          </div>
        </div>
        <div class="simulator-preview__heading">
          Código para incrustar
        </div>
        <textarea
          class="simulator-preview__embed-code"
          rows="6"
          readonly
          v-model="computedEmbedCode"
        />
      </div>
      <div class="simulator-preview__apply-container">
        <button
          :disabled="isButtonDisabled()"
          @click="updateQueryString()"
          class="simulator-preview__apply"
        >
          Aplicar cambios
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-statements, no-magic-numbers, no-undef */
import { Sketch } from 'vue-color';
import COLORS from '../utils/colors';
import MulticurrencyMixin from '../mixins/multicurrency';

const DEFAULT_USD_PEN_EXCHANGE_RATE = 3.6;

export default {
  mixins: [MulticurrencyMixin],
  components: {
    'color-picker': Sketch,
  },
  data() {
    const colorLabels = {};
    const colors = {};

    COLORS.forEach(color => {
      colorLabels[color.key] = color.label;
      colors[color.key] = { hex: color.default };
    });

    return {
      resizeInterval: null,
      editingColor: null,
      colorLabels,
      colors,
      width: 500,
      fontScale: 100,
      spacingScale: 100,
      conventionalExternalId: gon.defaultConventionalId || 0,
      intelligentExternalId: gon.defaultIntelligentId || 0,
      conventionalUsdExternalId: gon.defaultUsdConventionalId || 0,
      intelligentUsdExternalId: gon.defaultUsdIntelligentId || 0,
      price: 0,
      minPrice: 0,
      maxPrice: 0,
      downPayment: 10,
      downPaymentType: 0,
      downPaymentOptions: [{ text: 'Porcentaje', value: 0 }, { text: 'Monto', value: 1 }],
      downPaymentRanges: { conventional: { min: 0, max: 100 }, intelligent: { min: 0, max: 100 }},
      intelligentName: this.$t('smartChoice'),
      from: '',
      originUrl: '',
      term: 12,
      termOptions: [12, 24, 36, 48, 60, 72],
      queryString: '',
      showFormButton: true,
      formButtonText: 'Quiero este crédito',
      resultSuccessText: 'Ya estás a un paso para tener el auto que deseas, pronto te contactaremos',
      resultRejectedText: 'Lamentablemente no cumples con los requisitos para el crédito, prueba con otros valores',
      vehicleCondition: 'show',
      tagManagerId: '',
      vehicleBrand: '',
      redirectionUrl: '',
      useAmicarForm: true,
      modalForm: false,
      currencyType: this.$i18n.locale === 'es-PE' ? 'PEN' : null,
      usdPenExchangeRate: this.$i18n.locale === 'es-PE' ? DEFAULT_USD_PEN_EXCHANGE_RATE : null,
      allowCurrencyChange: false,
    };
  },
  mounted() {
    this.updateQueryString();
    this.resizeInterval = setInterval(this.resizeIframe.bind(this), 100);
  },
  beforeDestroy() {
    clearInterval(this.resizeInterval);
  },
  computed: {
    computedEmbedCode() {
      return `<iframe src="https://${window.location.host}/simulator?${this.queryString}" width="${this.width}"></iframe>`
    },
    computedQueryString() {
      let params = [
        ['cuotas', this.term],
        ['pie', this.downPayment],
        ['tipo_pie', this.downPaymentType],
        ['rangos_pie', JSON.stringify(this.downPaymentRanges)],
        ['escala_textos', this.fontScale],
        ['escala_espaciado', this.spacingScale],
        ['mostrar_boton_formulario', this.showFormButton],
        ['texto_boton_formulario', this.formButtonText],
        ['texto_resultado_exitoso', this.resultSuccessText],
        ['texto_resultado_fallido', this.resultRejectedText],
        ['estado_auto', this.vehicleCondition],
        ['usar_formulario_amicar', this.useAmicarForm],
        ['permitir_cambio_moneda', this.allowCurrencyChange],
        ['precio_minimo', this.minPrice],
        ...Object.keys(this.colors).map(key => {
          return [`color_${key}`, this.colors[key].hex.replace('#', '')];
        }),
      ];
      if (!this.useAmicarForm) params.push(['usar_modal', this.modalForm]);
      if (this.redirectionUrl) params.push(['redirection_url', this.redirectionUrl]);
      if (this.price > 0) params.push(['precio', this.price]);
      if (this.tagManagerId) params.push(['tag_manager_id', this.tagManagerId]);
      if (this.from) params.push(['fuente', this.from]);
      if (this.originUrl) params.push(['url_origen', this.originUrl]);
      if (this.vehicleBrand) params.push(['marca', this.vehicleBrand]);
      if (this.conventionalExternalId > 0) params.push(['producto_convencional', this.conventionalExternalId]);
      if (this.intelligentExternalId > 0) params.push(['producto_inteligente', this.intelligentExternalId]);
      if (this.conventionalUsdExternalId > 0) {
        params.push(['producto_usd_convencional', this.conventionalUsdExternalId]);
      }
      if (this.intelligentUsdExternalId > 0) params.push(['producto_usd_inteligente', this.intelligentUsdExternalId]);
      if (this.maxPrice > 0) params.push(['precio_maximo', this.maxPrice]);
      if (this.intelligentName) params.push(['nombre_inteligente', this.intelligentName]);
      if (this.currencyType) params.push(['moneda', this.currencyType]);
      if (this.usdPenExchangeRate) params.push(['tasa_de_cambio', this.usdPenExchangeRate]);
      params = params.map(param => {
        param[1] = encodeURIComponent(param[1]);

        return param.join('=');
      });

      return params.join('&');
    },
    validPostUrl() {
      return /(?:https?:\/\/)+(?:[\w]+(\.|:))+(([a-zA-Z.]{2,6}|\d{1,4}))([/\w\.-]*)*\/?/.test(this.redirectionUrl);
    },
  },
  methods: {
    editColor(key) {
      this.editingColor = key;
    },
    updateQueryString() {
      this.queryString = this.computedQueryString;
    },
    resizeIframe() {
      if (!this.$refs.iframe.contentWindow.document.body) return;
      this.$refs.iframe.height = this.$refs.iframe.contentWindow.document.body.clientHeight;
    },
    isButtonDisabled() {
      return this.queryString === this.computedQueryString ||
        this.minPrice > this.maxPrice ||
        (!this.useAmicarForm && !this.modalForm && !this.validPostUrl);
    },
    checkLineBreak(event) {
      event.target.value = event.target.value.replace(/[\r\n\v]+/g, '');
    },
  },
};
</script>

<style lang="scss">
.simulator-preview {
  display: flex;
  width: 100vw;
  height: 100vh;

  &__form {
    position: relative;
    width: 400px;
    height: 100%;
    background-color: #eee;
    overflow: hidden;

    &--pending {
      .simulator-preview__controls {
        padding-bottom: 100px;
      }

      .simulator-preview__apply-container {
        transform: translateY(0);
      }
    }
  }

  &__controls {
    height: 100%;
    padding: 20px;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__heading {
    color: #888;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-bottom: 5px;
    margin-bottom: 9px;
    border-bottom: 1px solid #aaa;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  &__control {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }

  &__color-container {
    position: relative;
  }

  &__color {
    display: flex;
    justify-content: flex-end;
  }

  &__color-picker {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    z-index: 20;

    &::before {
      content: 'arrow_drop_up';
      position: absolute;
      top: -17px;
      right: 0;
      font-family: 'Material Icons';
      font-size: 28px;
      color: #aaa;
    }

    .vc-sketch {
      box-shadow: none;
      background: none;
      border: 0;

      .vc-sketch-presets {
        display: none;
      }

      .vc-hue-picker {
        height: 22px;
      }

      .vc-sketch-hue-wrap {
        height: 24px;
      }

      .vc-sketch-alpha-wrap {
        display: none;
      }
    }

    button {
      display: block;
      background-color: #000;
      color: #fff;
      border-radius: 3px;
      border: 0;
      height: 30px;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 10px;
      margin-top: 5px;
      width: calc(100% - 20px);
      outline: none;

      &:hover {
        opacity: .8;
      }
    }
  }

  &__textarea {
    height: 46px;
    max-width: 200px;
    resize: none;
    width: 100%;
  }

  &__color-preview {
    width: 30px;
    height: 20px;
    margin-left: 10px;
    border: 1px solid #bbb;
  }

  &__input {
    &--wide {
      width: 200px;
    }

    &--block {
      text-align: end;
    }
  }

  &__separator {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
  }

  &__embed-code {
    width: 100%;
    font-family: monospace;
    font-size: 18px;
  }

  &__iframe-container {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 50px;
    overflow-y: auto;
  }

  &__iframe {
    display: block;
    margin: 0 auto;
    border: 2px solid #ddd;
    box-sizing: content-box;
    overflow: hidden;
  }

  &__apply-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    background-color: #eee;
    padding: 15px;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, .1);
    transition: transform .2s ease;
    transform: translateY(100%);
  }

  &__apply {
    width: 100%;
    background-color: #444;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.1px;
    border-radius: 6px;
    height: 47px;
    outline: none;
    cursor: pointer;

    &:hover:not([disabled]) {
      background-color: #222;
    }

    &[disabled] {
      opacity: .3;
      cursor: default;
    }
  }
}
</style>
