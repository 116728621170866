<template>
  <div class="credit-step">
    <div class="credit-step__title">
      {{ title }}
    </div>
    <div class="credit-step__subtitle">
      {{ subtitle }}
    </div>
    <input
      class="credit-step__input"
      type="text"
      :placeholder="inputPlaceholder"
      v-model="input"
    >
    <div class="credit-step__buttons">
      <div
        v-if="showBackButton"
        @click="$emit('back')"
        class="credit-step__button credit-step__button--small credit-step__button--gray"
      >
        back
      </div>
      <div
        @click="$emit('next')"
        class="credit-step__button credit-step__button--small"
      >
        {{ buttonPlaceholder }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
    buttonPlaceholder: {
      type: String,
      default: 'Continuar',
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      input: '',
    };
  },
};
</script>
