<template>
  <div>
    <div
      class="distributor"
      :key="distributor.id"
      v-for="distributor in distributorList"
      @click="$emit('distributor-selected', distributor)"
      @mouseover="$emit('distributor-hovered', distributor)"
    >
      <div class="distributor__name">
        {{ distributor.name }}
      </div>
      <div
        v-if="showBrandLogos"
        class="distributor__brands-container"
      >
        <template
          v-for="brand in distributor.activeBrands"
        >
          <div
            v-if="brand.logoAttachment"
            :key="brand.name"
          >
            <img
              class="distributor__brand"
              :src="brand.logoAttachment"
            >
          </div>
        </template>
      </div>
      <div class="distributor__contact-wrapper">
        <div class="distributor__icon distributor__icon--address" />
        <div class="distributor__contact">
          {{ fullAddress(distributor) }}
        </div>
      </div>
      <div
        class="distributor__contact-wrapper"
        v-if="distributor.phone"
      >
        <div class="distributor__icon distributor__icon--phone" />
        <div class="distributor__contact">
          {{ distributor.phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    distributorList: {
      type: Array,
      default: () => [],
    },
    showBrandLogos: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fullAddress(distributor) {
      return `${distributor.address}, ${distributor.commune}`
        .replace(', ,', ',').replace(', null', '');
    },
  },
};
</script>
