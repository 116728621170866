<template>
  <div class="amicar-loading">
    <div class="loading-box">
      <div class="loading-dots">
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
    <div class="loading-message">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style type="text/css">
@keyframes loading-dots {
   0% { transform: translate(12px,80px) scale(0); }
  25% { transform: translate(12px,80px) scale(0); }
  50% { transform: translate(12px,80px) scale(1); }
  75% { transform: translate(80px,80px) scale(1); }
 100% { transform: translate(148px,80px) scale(1); }
}
@keyframes loading-dots-r {
   0% { transform: translate(148px,80px) scale(1); }
 100% { transform: translate(148px,80px) scale(0); }
}
@keyframes loading-dots-c {
   0% { background: #ff520f }
  25% { background: #ff960f }
  50% { background: #ff860f }
  75% { background: #ff760f }
 100% { background: #ff520f }
}
.loading-dots div {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translate(80px,80px) scale(1);
  background: #ff520f;
  animation: loading-dots 2.2222222222222223s infinite cubic-bezier(0,0.5,0.5,1);
}
.loading-dots div:nth-child(1) {
  background: #ff760f;
  transform: translate(148px,80px) scale(1);
  animation: loading-dots-r 0.5555555555555556s infinite cubic-bezier(0,0.5,0.5,1), loading-dots-c 2.2222222222222223s infinite step-start;
}.loading-dots div:nth-child(2) {
  animation-delay: -0.5555555555555556s;
  background: #ff520f;
}.loading-dots div:nth-child(3) {
  animation-delay: -1.1111111111111112s;
  background: #ff760f;
}.loading-dots div:nth-child(4) {
  animation-delay: -1.6666666666666665s;
  background: #ff860f;
}.loading-dots div:nth-child(5) {
  animation-delay: -2.2222222222222223s;
  background: #ff960f;
}
.loading-box {
  width: 200px;
  height: 150px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}
.loading-message {
  width: 200px;
  height: 200px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 16;
  font-weight: lighter;
  color: #1f2f48;
  padding: 10px 0;
}
.loading-dots {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.loading-dots div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>
