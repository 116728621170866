<template>
  <label class="radio-input">
    <input
      type="radio"
      :value="value"
      class="amicar-checkbox amicar-checkbox--primary"
      :checked="modelValue === value"
      @change="onChange"
    >
    <span class="radio-input__label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    modelValue: {
      default: null,
      type: String,
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-input {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  &__label {
    margin-left: 4px;
    font-size: .7em;
  }
}
</style>
