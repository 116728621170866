<template>
  <div class="feedback">
    <div class="feedback__check" />
    <div class="feedback__subtitle">
      ¡Documentación cargada con éxito!
    </div>
    <div class="feedback__message">
      Este es un resumen de los documentos que ingresaste:
    </div>
    <div class="label-thanks">
      <div class="cedula-thanks" />
      Cédula de identidad
      <br>
      <button
        v-for="(file, index) in this.cedulas"
        :key="index"
        class="file-button"
      >
        {{ file | truncate(15, '...') }}
      </button>
    </div>
    <hr v-if="this.liquidaciones.length > 0">
    <div v-if="this.liquidaciones.length > 0" class="label-thanks">
      <div class="liquidaciones-thanks" />
      Últimas 3 liquidaciones de sueldo
      <br>
      <button
        v-for="(file, index) in this.liquidaciones"
        :key="index"
        class="file-button"
      >
        {{ file | truncate(15, '...') }}
      </button>
    </div>
    <hr>
    <div class="label-thanks">
      <div class="domicilio-thanks" />
      Comprobante de domicilio
      <br>
      <button
        v-for="(file, index) in this.domicilios"
        :key="index"
        class="file-button"
      >
        {{ file | truncate(15, '...') }}
      </button>
    </div>
    <hr v-if="this.cotizaciones.length > 0">
    <div v-if="this.cotizaciones.length > 0" class="label-thanks">
      <div class="cotizaciones-thanks" />
      Certificado de 12 ultimas cotizaciones
      <br>
      <button
        v-for="(file, index) in this.cotizaciones"
        :key="index"
        class="file-button"
      >
        {{ file | truncate(15, '...') }}
      </button>
    </div>
    <hr v-if="this.carpetas.length > 0">
    <div v-if="this.carpetas.length > 0" class="label-thanks">
      <div class="cotizaciones-thanks" />
      Carpeta tributaria
      <br>
      <button
        v-for="(file, index) in this.carpetas"
        :key="index"
        class="file-button"
      >
        {{ file | truncate(15, '...') }}
      </button>
    </div>
    <hr v-if="this.pasaporte.length > 0">
    <div v-if="this.pasaporte.length > 0" class="label-thanks">
      <div class="cotizaciones-thanks" />
      Pasaporte
      <br>
      <button
        v-for="(file, index) in this.pasaporte"
        :key="index"
        class="file-button"
      >
        {{ file | truncate(15, '...') }}
      </button>
    </div>
    <hr v-if="this.visa.length > 0">
    <div v-if="this.visa.length > 0" class="label-thanks">
      <div class="cotizaciones-thanks" />
      Visa
      <br>
      <button
        v-for="(file, index) in this.visa"
        :key="index"
        class="file-button"
      >
        {{ file | truncate(15, '...') }}
      </button>
    </div>
    <div class="message-thanks">
      Por medio de un correo electrónico te contactaremos con respuesta de la validación de tus documentos.
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lead: null,
      cedulas: [],
      liquidaciones: [],
      domicilios: [],
      cotizaciones: [],
      carpetas: [],
      pasaporte: [],
      visa: [],
    };
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      }

      return text;
    },
  },
  created() {
    this.lead = this.$route.query.lead;
    this.cedulas = this.$route.query.cedulas ? this.$route.query.cedulas.split(',') : [];
    this.liquidaciones = this.$route.query.liquidaciones ? this.$route.query.liquidaciones.split(',') : [];
    this.domicilios = this.$route.query.domicilios ? this.$route.query.domicilios.split(',') : [];
    this.cotizaciones = this.$route.query.cotizaciones ? this.$route.query.cotizaciones.split(',') : [];
    this.carpetas = this.$route.query.carpetas ? this.$route.query.carpetas.split(',') : [];
    this.pasaporte = this.$route.query.pasaporte ? this.$route.query.pasaporte.split(',') : [];
    this.visa = this.$route.query.visa ? this.$route.query.visa.split(',') : [];
  },
};
</script>

<style lang="scss" scoped>

.label-thanks {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 14px;
  font-size: 15px;
  font-family: "Source Sans Pro", serif;
  color: #6F6F6F;
  line-height: 19px;
  text-align: left;
}

.message-thanks {
  height: 36px;
  margin-left: 15%;
  width: 350px;
  margin-bottom: 15px;
  margin-top: 25px;
  color: #6F6F6F;
  font-family: "Source Sans Pro",serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.header {
  margin-left: -42px;
  margin-top: -42px;
  width: 120%;
  color: #ED6831;
  font-size: 12px;
  margin-bottom: 40px;
  padding-bottom: 5px;
  font-weight: 600;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  line-height: 14px;
  background-color: rgba(237,104,49,0.1);
}

.file-button {
  box-sizing: border-box;
  height: 32px;
  margin-right: 5px;
  font-size: 11px;
  width: 117px;
  font-weight: bold;
  margin-top: 19px;
  border: 1px solid #25A6FF;
  opacity: 0.4;
  border-radius: 15.5px;
}

.cedula-thanks {
  height: 15px;
  width: 23px;
  background: url("../assets/icons/cedula.png") no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 13px;
}

.liquidaciones-thanks {
  height: 19px;
  width: 18px;
  background: url("../assets/icons/liquidaciones.png") no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 13px;
}

.domicilio-thanks {
  height: 19px;
  width: 19px;
  background: url("../assets/icons/domicilio.png") no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 13px;
}

.cotizaciones-thanks {
  height: 19px;
  width: 16px;
  background: url("../assets/icons/cotizaciones.png") no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 13px;
}

.carpeta-thanks {
  height: 19px;
  width: 16px;
  background: url("../assets/icons/cotizaciones.png") no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 13px;
}

.feedback {
  @import '../styles/app/variables';

  background-color: $feedback-background;
  color: $feedback_text;
  padding: 42px;
  text-align: center;

  &__greeting {
    font-size: 41px;
    padding: 5px 0;
    font-weight: bold;
  }

  &__subtitle {
    padding: 5px 0;
    color: #23C273;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__check {
    height: 100px;
    width: 100px;
    background: url("../assets/check-amicar.png") no-repeat center;
    background-size: cover;
    margin: auto;
    margin-bottom: 20px;

  }

  &__message {
    font-size: 18px;
    font-weight: bold;
    font-family: "Source Sans Pro", serif;
    color: #6F6F6F;
    letter-spacing: .6px;
    padding: 10px 0;
    text-align: center;
  }

  &__disclaimer {
    font-size: 12px;
    font-weight: lighter;
    line-height: 13px;
    color: #6F6F6F;
    letter-spacing: .6px;
    padding: 10px 0;
  }

  &__field {
    border-top: solid 1px #000;
    font-size: 18px;
    padding: 5px;

    &--bottom {
      border-bottom: solid 1px #000;
    }
  }

  &__button {
    background-color: #fff;
    color: #F66536!important;
    margin-top: 30px;
    padding: 8px 16px;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.18);
    border: 0;
    border-radius: 20px;
    font-weight: 500;
    letter-spacing: 1.2px;
  }
}

@media only screen and (max-width: 768px) {
  .message-thanks {
    height: 36px;
    width: 100%;
    margin-left: 0;
  }
}

</style>

<style lang="scss" scoped>
@import '../styles/app/variables';
@import './form/form';

.form {
  color: #B9B9B9;;
  --font-size: 20px;
  --spacing: 17.5px;
  border: 1px solid rgba(196, 202, 213, 0.24);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 29px 35px 0 rgba(212, 212, 212, 0.29);
}

</style>
