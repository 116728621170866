<template>
  <!-- eslint-disable vue/component-name-in-template-casing -->
  <div class="simulator-wrapper" v-cloak>
    <AmicarSimulator
      v-show="showSimulator"
      @toggle-form="toggleForm()"
      @proceed="proceed"
      @send-info="sendInfo"
      v-bind="simulatorInitialData"
      :conventional-product="conventionalProduct"
      :intelligent-product="intelligentProduct"
      :administration-costs="administrationCosts"
      :insurance-rates="insuranceRates"
      :simulator-first="simulatorFirst"
    />
    <component
      v-show="!showSimulator"
      @toggle-form="toggleForm()"
      @proceed="proceed"
      @send-info="sendInfo"
      v-bind="answers"
      :brands="brands"
      :simulator-first="simulatorFirst"
      :is="countryForm"
    />
    <div
      class="spinner__container"
      v-show="loading"
    >
      <AmicarLoading :message="'Estamos pre-evaluando tu información.'" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-magic-numbers */
const isWebview = require('is-ua-webview');
import AmicarSimulator from './amicar-simulator.vue';
import AmicarFormCl from './amicar-form-cl.vue';
import AmicarFormPe from './amicar-form-pe.vue';
import AmicarLoading from './amicar-loading.vue';
import FormService from '../services/form.js';

const formService = new FormService();

export default {
  components: { AmicarSimulator, AmicarLoading },
  props: {
    simulatorFirst: {
      type: Boolean,
      default: true,
    },
    conventionalProduct: {
      type: Object,
      default: () => {},
    },
    intelligentProduct: {
      type: Object,
      default: () => {},
    },
    administrationCosts: {
      type: Array,
      default: () => [],
    },
    insuranceRates: {
      type: Array,
      default: () => [],
    },
    brands: {
      type: Object,
      default: () => {},
    },
    showSimulatorSubmit: {
      type: Boolean,
      default: true,
    },
    showCost: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSimulator: null,
      simulatorInitialData: {
        initialAmount: this.$i18n.locale === 'es-CL' ? 10000000 : 15000,
        userMinAmount: this.$i18n.locale === 'es-CL' ? 4000000 : 18000,
        userMaxAmount: this.$i18n.locale === 'es-CL' ? 30000000 : 140000,
        initialDownPayment: 20,
        downPaymentType: 0,
        initialTerm: 36,
        showFormButton: this.showSimulatorSubmit,
        showCost: this.showCost,
      },
      answers: {},
      loading: false,
      queryParams: null,
    };
  },
  mounted() {
    this.showSimulator = this.simulatorFirst;
    this.queryParams = this.$route.query;
  },
  methods: {
    toggleForm() {
      this.showSimulator = !this.showSimulator;
    },
    proceed(data) {
      this.answers = Object.assign({}, this.answers, data, { fromAmicarSimulator: true });
      this.toggleForm();
    },
    sendInfo(data) {
      this.answers = Object.assign({}, this.answers, data, { fromAmicarSimulator: true });
      this.checkClientApproval();
    },
    async checkClientApproval() {
      this.loading = true;
      const res = await formService.checkClientApproval(this.answers);
      let approved = this.translateData(res);
      this.answers.approved = approved;
      this.$cookies.set('answers', this.answers);
      if (this.$i18n.locale !== 'es-PE') {
        const body = {
          lead: res.evaluation.lead,
          formulario_url: this.generateFormURL(res.evaluation.lead, this.answers),
          documentacion_url: this.generateDocsURL(res.evaluation.lead, this.answers),
        };
        let newBody = this.getQueryParams(body);
        await formService.approveTermsDocument({ 'leadId': body.lead });
        await formService.updateLead(newBody);
      }
      this.loading = false;
      window.location.href = `/resultado?approved=${approved}&email=${this.answers.email}`;
    },
    normalizeName(name) {
      let normalizedName = '';
      if(!isWebview) {
        normalizedName = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      }else{
        normalizedName = name.replace(/Á/g, 'A').replace(/á/g, 'a').replace(/É/g, 'E').replace(/é/g, 'e').replace(/Í/g, 'I')
                            .replace(/í/g, 'i').replace(/Ó/g, 'O').replace(/ó/g, 'o').replace(/Ú/g, 'U').replace(/ú/g, 'u')
                            .replace(/Ü/g, 'U').replace(/ü/g, 'u').replace(/Ñ/g, 'N').replace(/ñ/g, 'n');
      }
      return normalizedName;
    },
    generateFormURL(leadId, body) {
      const regexExp = /[ÁáÉéÍíÓóÚúÜüÑñ]/g;
      const name = body.name;
      let normalizedName = name;
      if(regexExp.test(name)) {
        normalizedName = this.normalizeName(name);
        return 'https://' + process.env.APPLICATION_HOST + '/solicitud?lead=' + leadId + '&name=' + normalizedName + '&token=' + btoa(leadId + normalizedName.replace(/ /g, ''));
      }else{
        return 'https://' + process.env.APPLICATION_HOST + '/solicitud?lead=' + leadId + '&name=' + name + '&token=' + btoa(leadId + name.replace(/ /g, ''));
      }
    },
    generateDocsURL(leadId, body) {
      const regexExp = /[ÁáÉéÍíÓóÚúÜüÑñ]/g;
      const name = body.name;
      let normalizedName = name;
      let nationality = body.nationality=='Chileno' ? 'c' : 'e'
      if(regexExp.test(name)){
        normalizedName = this.normalizeName(name);
        return 'https://' + process.env.APPLICATION_HOST + '/documentacion?lead=' + leadId + '&name=' + normalizedName + '&type=' + body.workingCondition + '&nac=c&token=' + btoa(leadId + normalizedName.replace(/ /g, ''));
      }else{
        return 'https://' + process.env.APPLICATION_HOST + '/documentacion?lead=' + leadId + '&name=' + name + '&type=' + body.workingCondition + '&nac=' + nationality + '&token=' + btoa(leadId + name.replace(/ /g, ''));
      }
    },
    getAdsParams(body) {
      if(this.queryParams!==null) {
        body.fuente=this.queryParams.utm_source;
        body.medio=this.queryParams.utm_medium;
        body.campana=this.queryParams.utm_campaign;
        body.keyword=this.queryParams.utm_term;
        body.contenido=this.queryParams.utm_content;
      }
      return body;
    },
    getQueryParams(body){
      let bodyCopy = {
        ...body
      };
      if(this.queryParams !== null) {
        if(this.queryParams.campaign === 'economicos') {
          bodyCopy.campana=this.queryParams.campaign;
          bodyCopy.url_post='http://www.economicos.cl/' + this.queryParams.post_origin;
        }else if(this.queryParams.utm_source !== null) {
          bodyCopy.fuente=this.queryParams.utm_source;
          bodyCopy.medio=this.queryParams.utm_medium;
          bodyCopy.campana=this.queryParams.utm_campaign;
          bodyCopy.keyword=this.queryParams.utm_term;
          bodyCopy.contenido=this.queryParams.utm_content;
        }
      }
      return bodyCopy;
    },
    translateData(response) {
      if (this.$i18n.locale === 'es-PE') {
        return response.evaluationResult === 'APPROVED';
      }
      this.answers.leadId = response.evaluation.lead;
      if (response.evaluation.result == 'RECHAZADO' ) {
          return false;
        } else {
          return true;
        }
    },
    canHaveAlternativeScenarios(response){
      return response.VALIDA_INGRESO_MINIMO && response.VALIDA_SCORE_EQUIFAX &&
        response.VALIDA_MOROSIDAD && response.VALIDA_EDAD;
    },
    fixLeadStatus(resBody,leadBody) {
      if((resBody.porcentaje < 30 && resBody.porcentaje >= 20) && resBody.tipoVehiculo === 'U'){
        let checkRules = leadBody.regla_monto_a_financiar==="true" && leadBody.regla_ingreso_minimo==="true" 
                      && leadBody.regla_monto_pie_porc_financiar!=="true"  && leadBody.regla_score_equifax==="true" 
                      && leadBody.regla_valida_morosidad==="true"  && leadBody.regla_edad==="true";
        if(checkRules) {
          let tempBody = {
            ...leadBody
          };
          tempBody.resultadoEvaluacion = 'EQUIFAX APRUEBA';
          tempBody.regla_monto_pie_porc_financiar = "true";
          this.answers.approved = true;
          this.$cookies.set('answers', this.answers);
          return tempBody;
        }else{
          return leadBody;
        }
      }else{
        return leadBody;
      }
    }
  },
  computed: {
    countryForm() {
      switch (this.$i18n.locale) {
      case 'es-PE':
        return AmicarFormPe;
      default:
        return AmicarFormCl;
      }
    },
  },
};

</script>
<style lang="scss">
@import '../styles/app/variables';

[v-cloak] {
  display:none;
}

.spinner {
  left: 50%;
  position: absolute;
  top: 45%;
  transform: translateX(-50%);

  &__container {
    position: absolute;
    background-color: white;
    height: 100%;
    top: 0;
    width: 100%;
    z-index: 999;
    padding-top: 25%;
  }
}

.amicar-checkbox {
  appearance: none;
  border-radius: .15em;
  margin: 0;
  font-size: 1em;
  width: 1em;
  height: 1em;
  outline: none;
  text-align: center;

  &--primary {
    border: .1em solid $primary-color;

    &:checked {
      background-color: $primary-color;
    }
  }

  &--gray {
    border: .1em solid $gray-color;
    margin: .2em 0 .5em .6em;

    &:checked {
      background-color: $gray-color;
    }
  }

  &:checked {
    border: 0 none transparent;
  }

  &:checked::after {
    content: '\2713';
    line-height: 1;
    vertical-align: top;
    color: $contrast-color;
  }
}

.amicar-wrapper {
  &__button {
    background-color: $gray-color;
    border: 0;
    border-radius: 45px;
    color: $contrast-color;
    flex: 1;
    outline: 0;
    font-size: .8em;
    height: 2.5em;
    opacity: .9;
    text-align: center;
    width: 100%;

    &:hover {
      opacity: .5;
    }

    &:active {
      opacity: 1;
    }

    &:disabled {
      opacity: .2;
    }

    &--send {
      background-color: $primary-color;
      margin-left: 1.5%;
      text-transform: uppercase;
    }

    &--back {
      margin-right: 1.5%;
    }

    &--no-margin {
      margin: 0;
    }
  }
}
</style>
