<template>
  <ValidationObserver
    ref="form"
    tag="div"
  >
    <form class="form">
      <div class="form__icon form__icon--success"></div>
      <div class="form__row form__row--title">
        <div class="form__title">
          <b>PASO 2</b>
        </div>
        <div class="form__subtitle">
          Completa el formulario para continuar con el proceso.
        </div>
      </div>
      <div class="form__horizontal form__horizontal--input">
        <form-input
          :rules="`required|date_format:dd/MM/yyyy|date_before:${today},dd/MM/yyyy`"
          name="Fecha de Nacimiento"
          ref="birthDate"
        >
          <text-input
            v-model="form.birthDate"
            placeholder="Fecha de Nacimiento"
          >
            <template #button>
              <v-date-picker
                @input="updateDate($event, 'birthDate', 'dd/MM/yyyy')"
                :value="birthDate"
                :popover="{ placement: 'left', visibility: 'click' }"
                locale="es"
                :first-day-of-week="2"
                :maxDate="maxDate"
              >
                <button
                  class="text-input__button"
                  type="button"
                >
                  <i class="material-icons">date_range</i>
                </button>
              </v-date-picker>
            </template>
          </text-input>
        </form-input>
         <form-input
          rules="required"
          name="Sexo"
        >
          <select-input
            :options="['Masculino', 'Femenino', 'Otro']"
            :placeholder="'Sexo'"
            v-model="form.sex"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Dirección"
        >
          <text-input v-model="form.address" placeholder="Dirección"/>
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Region"
        >
          <select-input
            :options="regions"
            :placeholder="'Seleccione Región'"
            v-model="selectedRegion"
            @input="setCommunes()"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Comuna"
        >
          <select-input
            :options="communes"
            v-model="selectedCommune"
            :placeholder="'Seleccione Comuna'"
          />
        </form-input>
      </div>
      <div class="form__horizontal form__horizontal--input">
        <form-input
          rules="required"
          name="Estado Civil"
        >
          <select-input
            :options="civilStatuses"
            v-model="form.civilStatus"
            :placeholder="'Estado Civil'"
          />
        </form-input>
        <form-input
          rules="required"
          name="Estudios"
        >
          <select-input
            :options="studies"
            v-model="form.studies"
            :placeholder="'Estudios'"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Casa o departamento propio"
        >
          <select-input
            :options="housingType"
            v-model="form.houseOwner"
            :placeholder="'¿Tienes casa o departamento propio?'"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Actividad Laboral"
        >
          <select-input
            :options="workTypes"
            v-model="form.workType"
            :placeholder="'Actividad Laboral'"
          />
        </form-input>
      </div>
      <div
        class="form__row form__row--input"
        v-if="dependent"
      >
        <form-input
          :rules="`required|date_format:dd/MM/yyyy|date_before:${startOfMonth},dd/MM/yyyy`"
          label="Fecha Ingreso Empleo Actual:"
          name="Fecha Ingreso Empleo Actual"
          ref="workStart"
        >
          <text-input
            v-model="form.workStart"
            placeholder="22/07/2012"
          >
            <template #button>
              <v-date-picker
                @input="updateDate($event, 'workStart', 'dd/MM/yyyy')"
                :value="workStart"
                :popover="{ placement: 'left', visibility: 'click' }"
                locale="es"
                :first-day-of-week="2"
              >
                <button
                  class="text-input__button"
                  type="button"
                >
                  <i class="material-icons">date_range</i>
                </button>
              </v-date-picker>
            </template>
          </text-input>
        </form-input>
      </div>
      <div
        class="form__row form__row--input"
        v-if="dependent"
      >
        <form-input
          rules="required"
          label="Empresa o Empleador:"
          name="Empresa o Empleador"
        >
          <text-input v-model="form.employerName" />
        </form-input>
      </div>
      <div class="form__row form__row--button">
        <button
          id="leads-form-submit"
          type="button"
          class="amicar-wrapper__button amicar-wrapper__button--send amicar-wrapper__button--no-margin"
          @click="sendData()"
        >
          Enviar Datos
        </button>
      </div>
    </form>
    <div
      class="spinner__container"
      v-show="loading"
    >
    <AmicarLoading :message="'Estamos guardando tu solicitud.'"/>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { format, startOfMonth, addMonths } from 'date-fns';

import './form/validation-rules';
import FormInput from '../components/form/form-input';
import AmicarLoading from '../components/amicar-loading.vue';
import TextInput from '../components/form/text-input';
import SelectInput from '../components/form/select-input';
import RadioInput from '../components/form/radio-input';
import FormService from '../services/form.js';
import ChileService from '../services/chile.js';

const formService = new FormService();
const chileService = new ChileService();

export default {
  components: {
    ValidationObserver,
    FormInput,
    TextInput,
    SelectInput,
    RadioInput,
    AmicarLoading,
  },
  data() {
    return {
      maxDate: new Date(),
      form: {
        birthDate: null,
        sex: null,
        commune: null,
        civilStatus: null,
        studies: null,
        houseOwner: null,
        employerName: null,
        workType: null,
        workStart: null,
        address: null,
      },
      today: new Date().toISOString(),
      startOfMonth: null,
      birthDate: null,
      workStart: null,
      regions: null,
      communes: null,
      loading: false,
      selectedRegion: null,
      selectedCommune: null,
      housingType: [
        'Arrienda',
        'Propia con Deuda',
        'Propia sin Deuda',
        'Vive con Familiares',
      ],
      civilStatuses: [
        { id: 1, name: 'Casado', value: 'Casado' },
        { id: 2, name: 'Divorciado', value: 'Separado Legalmente' },
        { id: 3, name: 'Unión Civil', value: 'Casado' },
        { id: 4, name: 'Separado de Hecho', value: 'Separado de Hecho' },
        { id: 5, name: 'Soltero', value: 'Soltero' },
        { id: 6, name: 'Viudo', value: 'Viudo' },
      ],
      studies: [
        { id: 1, name: 'Educación Básica', value: 'Primarios' },
        { id: 2, name: 'Educación Media', value: 'Secundarios' },
        { id: 3, name: 'Educación Universitaria', value: 'Superiores' },
        { id: 4, name: 'Postgrado', value: 'Postgrado' },
        { id: 5, name: 'Magíster', value: 'Magister' },
        { id: 6, name: 'Sin Estudios', value: 'Otros' },
        { id: 7, name: 'Otros', value: 'Otros' },
      ],
      workTypes: [
        { id: 1, name: 'Dependiente No Profesional', type: 'dependiente' },
        { id: 2, name: 'Dependiente Profesional', type: 'dependiente' },
        { id: 3, name: 'Independiente No Profesional', type: 'independiente' },
        { id: 4, name: 'Independiente Profesional', type: 'independiente' },
        { id: 5, name: 'Jubilado', type: null },
        { id: 6, name: 'Otros', type: null },
      ],
    };
  },
  mounted() {
    this.startOfMonth = startOfMonth(addMonths(new Date(), 1)).toISOString();
    this.regions = chileService.getRegions();
    this.communes = chileService.getCommunesByRegion(this.region);
    this.selectedRegion = this.regions[0];
    this.selectedCommune = this.communes[0];
    this.maxDate.setFullYear( this.maxDate.getFullYear() - 22 );
  },
  computed: {
    dependent() {
      return this.form.workType && this.form.workType.type === 'dependiente';
    },
    selectDefaultCommune() {
      this.selectedCommune = this.communes[0];
    }
  },
  methods: {
    setCommunes() {
      this.communes = chileService.getCommunesByRegion(this.selectedRegion.id);
    },
    sendData() {
      this.$refs.form.validate().then(success => {
        if (!success) return;
        this.updateLead();
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async updateLead() {
      const data = this.$cookies.get('answers');
      this.loading = true;
      try {
        this.scrollToTop();
        const body = {
          'lead': data.leadId,
          'genero': this.form.sex,
          'comuna': this.selectedCommune.name,
          'region': this.selectedRegion.externalCode,
          'estudios': this.form.studies.value,
          'direccion': this.form.address,
          'empleador': this.form.employerName,
          'tipoTrabajador': this.form.workType.type,
          'estadoCivil': this.form.civilStatus.value,
          'tipoResidencia': this.form.houseOwner,
          'arriendo_dividendo_cliente__c': 1,
          'segundo_formulario_ok': true,
          'fechaNacimiento': this.salesforceDateFormat(this.form.birthDate),
          'fechaIngresoEmpleo': this.salesforceDateFormat(this.form.workStart),
          'descripcion': `Nacimiento: ${this.form.birthDate}, Comienzo Trabajo: ${this.form.workStart}`,
        };
        await formService.updateLead(body);
        this.loading = false;
        this.$cookies.remove('answers');
        window.location.href = '/gracias?lead=' + data.leadId;
      } catch (e) {
        this.loading = false;
        this.$cookies.remove('answers');
        window.location.href = '/gracias';
      }
    },
    salesforceDateFormat(dateString) {
      if (!dateString) return null;
      const date = dateString.split('/');

      return date[2] + '-' + date[1] + '-' + date[0];
    },
    updateDate(date, dateKey, dateFormat = 'dd/MM/yyyy') {
      if (!date) return;
      this[dateKey] = date;
      this.form[dateKey] = format(date, dateFormat);
      this.$refs[dateKey].$children[0].$children[0].$emit('blur');
    },
  },
};
</script>

<style lang="scss">
.vc-title,
.vc-nav-title {
  display: flex;
  margin-left: 14px;
  &::after {
    content: 'arrow_drop_down';
    font-weight: normal;
    font-family: 'Material Icons';
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
}

.vc-title {
  margin-left: 24px;
}

.vc-nav-title::after {
  font-size: 18px;
}
</style>

<style lang="scss" scoped>
@import '../styles/app/variables';
@import './form/form';

.form {
  --font-size: 20px;
  --spacing: 17.5px;
  border: 1px solid rgba(196, 202, 213, 0.24);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 29px 35px 0 rgba(212, 212, 212, 0.29);

  &__icon {
    width: 60px;
    height: 60px;

    &--success {
      background: url("../assets/Calculadora_azul.png") no-repeat center;
      background-size: cover;
    }
  }
}

</style>
