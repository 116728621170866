<template>
  <text-input
    :placeholder="placeholder"
    @input="onInput"
    :value="value"
    :readonly="readonly"
  >
    <template #button>
      <v-date-picker
        @input="updateDate($event, 'dd-MM-yyyy')"
        :value="date"
        :popover="{ placement: popoverPlacement, visibility: 'click' }"
        locale="es"
        :first-day-of-week="2"
      >
        <button
          class="text-input__button"
          type="button"
        >
          <i class="material-icons">date_range</i>
        </button>
      </v-date-picker>
    </template>
  </text-input>
</template>

<script>
import { format } from 'date-fns';
import TextInput from './text-input.vue';

export default {
  components: {
    TextInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    popoverPlacement: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      date: null,
    };
  },
  methods: {
    onInput(e) {
      this.$emit('input', e);
    },
    updateDate(date, dateFormat = 'dd/MM/yyyy') {
      if (!date) return;
      this.date = date;
      this.$emit('input', format(date, dateFormat));
    },
  },
};
</script>
