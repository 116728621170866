import COLORS from '../utils/colors';

export default {
  methods: {
    coloredCheckbox(checked) {
      return checked ? [this.coloredBorder(), this.coloredBackground()] : this.coloredBorder();
    },

    coloredBackground(key = 'highlights') {
      return { 'background-color': this.$colorValueFromKey(key) };
    },

    coloredBorder(key = 'highlights') {
      return { 'border-color': this.$colorValueFromKey(key) };
    },

    coloredText(key = 'highlights') {
      return { 'color': this.$colorValueFromKey(key) };
    },

    $colorValueFromKey(key) {
      return this.$attrs[`color-${key}`] ? this.$attrs[`color-${key}`] : COLORS.find(color => color.key === key).default;
    },
  },
};
