<template>
  <div class="form__horizontal form__horizontal--input">
    <form-input
      rules="required|date_format:dd-MM-yyyy|date_before_or_eq_today:dd-MM-yyyy"
      name="workStart"
    >
      <date-input
        :value="workStart"
        @input="e => $emit('start-change', e)"
        placeholder="Desde:"
        :popover-placement="popoverPlacement"
      />
    </form-input>
    <div class="form-input">
      <form-input
        :rules-obj="{
          required: true,
          date_format: 'dd-MM-yyyy',
          date_before_or_eq_today: 'dd-MM-yyyy',
          date_after: [ISOWorkStart, 'dd-MM-yyyy']
        }"
        name="workEnd"
        default-class=""
      >
        <date-input
          :value="workEnd"
          @input="e => $emit('end-change', e)"
          placeholder="Hasta:"
          :popover-placement="popoverPlacement"
        />
      </form-input>
      <div>
        <input
          id="workEndToday"
          v-model="workEndToday"
          :value="true"
          type="radio"
        >
        <label
          class="form__work"
          id="workEndToday"
        >La actualidad</label>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parse, parseISO, isValid, differenceInMonths, isToday } from 'date-fns';
import DateInput from '../form/date-input.pe';
import FormInput from '../form/form-input';

export default {
  components: { DateInput, FormInput },
  props: {
    workStart: { type: String, default: null },
    workEnd: { type: String, default: null },
    popoverPlacement: { type: String, default: 'bottom' },
  },
  data() {
    return {
      workEndToday: false,
    };
  },
  computed: {
    ISOWorkStart() {
      let date = parse(this.workStart, 'dd-MM-yyyy', new Date());
      if (!isValid(date)) {
        date = new Date();
      }

      return date.toISOString();
    },
    ISOWorkEnd() {
      let date = parse(this.workEnd, 'dd-MM-yyyy', new Date());
      if (!isValid(date)) {
        date = new Date();
      }

      return date.toISOString().split('T')[0];
    },
    seniority() {
      return differenceInMonths(parseISO(this.ISOWorkEnd), parseISO(this.ISOWorkStart));
    },
    today() {
      const now = new Date();

      return now.toISOString().split('T')[0];
    },
  },
  watch: {
    seniority() {
      this.$emit('seniority-change', this.seniority);
    },
    workEnd() {
      const workEnd = parse(this.workEnd, 'dd-MM-yyyy', new Date());
      this.workEndToday = isToday(workEnd);
    },
    workEndToday() {
      if (this.workEndToday) {
        this.$emit('end-change', format(parseISO(this.today), 'dd-MM-yyyy'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/app/variables';
@import '../form/form';

.form {
  --font-size: 20px;
  --spacing: 17.5px;

  &__work {
    font-size: .65em;
  }
}
</style>
