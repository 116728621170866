import { render, staticRenderFns } from "./documentation_thanks.vue?vue&type=template&id=541c090e&scoped=true&"
import script from "./documentation_thanks.vue?vue&type=script&lang=js&"
export * from "./documentation_thanks.vue?vue&type=script&lang=js&"
import style0 from "./documentation_thanks.vue?vue&type=style&index=0&id=541c090e&lang=scss&scoped=true&"
import style1 from "./documentation_thanks.vue?vue&type=style&index=1&id=541c090e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "541c090e",
  null
  
)

export default component.exports