<template>
  <div class="feedback">
    <div class="feedback__check"></div>
    <div class="feedback__subtitle">
      ¡Solicitud enviada con éxito!
    </div>
    <div class="feedback__message">
      En un plazo no superior a <b>2 días hábiles</b> te enviaremos <br/>
      un correo electrónico para informarte sobre el resultado de <br>
      tu solicitud de crédito.
    </div>
    <button
      class="feedback__button"
      style="color:#fff;cursor: pointer;"
      @click="home()"
    >
      Ir al inicio
    </button>
    <div class="feedback__disclaimer">
      <b>Importante:</b> Recuerda que la aprobación depende de que los datos ingresados sean correctos y comprobables. Las <br>
      Entidades Financieras solicitarán documentación que acredite la veracidad de la información enviada.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    scenarios() {
      window.location.href = '/entiende-tu-credito';
    },
    frecuents() {
      window.location.href = '/preguntas-frecuentes';
    },
    home() {
      window.location.href = `https://${process.env.APPLICATION_HOST}`;
    },
  },
};
</script>

<style lang="scss" scoped>

.feedback {
  @import '../styles/app/variables';

  background-color: $feedback-background;
  color: $feedback_text;
  padding: 42px;
  text-align: center;

  &__greeting {
    font-size: 41px;
    padding: 5px 0;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 22px;
    padding: 5px 0;
    font-weight: bold;
  }

  &__message {
    font-size: 18px;
    font-weight: lighter;
    color: #6F6F6F;
    letter-spacing: .6px;
    padding: 10px 0;
  }
  
  &__disclaimer {
    font-size: 12px;
    font-weight: lighter;
    line-height: 13px;
    color: #6F6F6F;
    letter-spacing: .6px;
    padding: 10px 0;
  }
  
  &__field {
    border-top: solid 1px #000;
    font-size: 18px;
    padding: 5px;

    &--bottom {
      border-bottom: solid 1px #000;
    }
  }

  &__button {
    background-color: $feedback-back;
    color: $feedback-text;
    margin: 30px 0px;
    padding: 8px 16px;
    border: 0;
    border-radius: 20px;
    font-weight: 500;
    letter-spacing: 1.2px;
  }

  &__check {
    height: 100px;
    width: 100px;
    background: url("../assets/check-amicar.png") no-repeat center;
    background-size: cover;
    margin: auto;
    margin-bottom: 20px;
  }
}
</style>

