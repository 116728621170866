<template>
  <ValidationObserver ref="form">
    <div class="form">
      <div class="form__row form__row--title">
        <div class="form__title">
          <b>¡Ya tenemos tu escenario de crédito!</b>
        </div>
        <div class="form__subtitle">
          Con los siguientes datos conseguiremos una <b>pre-evaluación.</b>
        </div>
      </div>
      <div class="form__horizontal form__horizontal--input">
        <form-input
          rules="required"
          name="identifierType"
        >
          <select-input
            :placeholder="'Tipo de Documento'"
            :options="identifierTypes"
            v-model="selectedIdentifierType"
          />
        </form-input>
        <form-input
          rules="required"
          name="identifier"
        >
          <text-input
            v-model="form.identifier"
            placeholder="N° Identidad"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|alpha_spaces"
          name="nombre"
        >
          <text-input
            v-model="form.name"
            placeholder="Nombre"
          />
        </form-input>
      </div>
      <div class="form__horizontal form__horizontal--input">
        <form-input
          rules="required|alpha_spaces"
          name="Apellido Paterno"
        >
          <text-input
            v-model="form.fatherSurname"
            placeholder="Apellido Paterno"
          />
        </form-input>
        <form-input
          rules="required|alpha_spaces"
          name="Apellido Materno"
        >
          <text-input
            v-model="form.motherSurname"
            placeholder="Apellido Materno"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          :rules="`required|min_income_pe_mask:${minIncome}`"
          name="Ingreso"
        >
          <text-input
            placeholder="Ingreso en Soles"
            v-model.lazy="incomeMask"
            v-money="incomeMask !== null ? money : null"
          />
        </form-input>
      </div>
      <div class="form__horizontal form__horizontal--input">
        <form-input
          rules="required|email"
          name="Correo Electrónico"
        >
          <text-input
            v-model="form.email"
            placeholder="Correo"
          />
        </form-input>
        <form-input
          rules="required|phone"
          name="Teléfono"
        >
          <text-input
            v-model="form.phone"
            placeholder="Teléfono"
          />
        </form-input>
      </div>
      <div
        class="form__row form__row--input"
        v-if="showVehicleCondition === 'show'"
      >
        <form-input
          rules="required"
          name="Estado del auto"
        >
          <select-input
            :placeholder="'Seleccione Estado de Auto'"
            :options="vehicleConditions"
            v-model="selectedVehicleCondition"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Marca del auto"
        >
          <select-input
            :placeholder="'Seleccione la Marca'"
            :optgroups="computedBrands"
            v-model="selectedBrand"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          label="Antiguedad Laboral:"
        />
      </div>
      <seniority-inputs
        :work-start="form.workStart"
        :work-end="form.workEnd"
        @start-change="val => form.workStart = val"
        @end-change="val => form.workEnd = val"
        @seniority-change="val => form.seniority = val"
      />
      <div
        v-if="amount"
        class="form__row form__row--value"
      >
        <div class="form__input">
          Precio del auto:
        </div>
        <span class="form__amount">
          {{ $n(amount, 'currency') }}
        </span>
      </div>
      <div
        v-if="downPayment"
        class="form__row form__row--value"
      >
        <div class="form__input">
          Cuota Inicial:
        </div>
        <span>{{ $n(downPayment, 'currency') }}</span>
      </div>
      <div class="form__row form__row--terms">
        <input
          class="amicar-checkbox amicar-checkbox--gray"
          v-model="agreedTerms"
          type="checkbox"
        >
        <span class="form__terms">
          Estoy de acuerdo con los
          <a
            class="form__link"
            href="/terminos-y-condiciones"
            target="_blank"
          >Términos y Condiciones</a>
        </span>
        <span
          class="form__error form__error--left"
          v-if="!validTerms"
        >
          Debes aceptar los términos y condiciones
        </span>
      </div>
      <div class="form__row form__row--button">
        <button
          v-if="simulatorFirst"
          class="amicar-wrapper__button amicar-wrapper__button--back"
          :class="{'amicar-wrapper__button--no-margin': !simulatorFirst}"
          @click="$emit('toggle-form')"
        >
          VOLVER
        </button>
        <button
          id="simulator-form-submit"
          class="amicar-wrapper__button amicar-wrapper__button--send"
          :class="{'amicar-wrapper__button--no-margin': !simulatorFirst}"
          @click="sendData()"
        >
          ENVIAR
        </button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { VMoney } from 'v-money';
import { ValidationObserver } from 'vee-validate';
import './form/validation-rules';
import FormInput from '../components/form/form-input';
import TextInput from '../components/form/text-input';
import SelectInput from '../components/form/select-input';
import SeniorityInputs from './es-PE/seniority-inputs';
import { MIN_INCOME, IDENTIFIER_TYPES } from '../constants/es-PE';
import { VEHICLE_CONDITION_OPTIONS } from '../constants/shared';

export default {
  components: {
    ValidationObserver,
    FormInput,
    TextInput,
    SelectInput,
    SeniorityInputs,
  },
  props: {
    simulatorFirst: {
      type: Boolean,
      default: null,
    },
    amount: {
      type: Number,
      default: 0,
    },
    downPayment: {
      type: Number,
      default: 0,
    },
    showVehicleCondition: {
      type: String,
      default: 'show',
    },
    brands: {
      type: Object,
      default: () => {},
    },
    initialForm: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.selectedVehicleCondition = this.showVehicleCondition === 'show' ? '' : this.showVehicleCondition;

    const cache = this.$cookies.get('answers');
    if (cache) {
      this.form = {
        ...this.form,
        identifier: cache.identifier,
        name: cache.name,
        fatherSurname: cache.fatherSurname,
        motherSurname: cache.motherSurname,
        email: cache.email,
        phone: cache.phone,
        income: cache.income,
        vehicleCondition: cache.vehicleCondition,
        vehicleBrandId: cache.vehicleBrandId,
        workStart: cache.workStart,
        workEnd: cache.workEnd,
        vehicleDistributor: cache.vehicleDistributor,
      };
    }
  },
  data() {
    return {
      form: {
        from: 'amicar-pe',
        identifier: '',
        name: '',
        fatherSurname: '',
        motherSurname: '',
        email: '',
        phone: '',
        income: null,
        identifierType: null,
        vehicleCondition: null,
        vehicleBrandId: '',
        vehicleDistributor: '',
        vehicleBrand: '',
        workStart: null,
        workEnd: null,
        seniority: null,
      },
      minIncome: MIN_INCOME,
      vehicleConditions: VEHICLE_CONDITION_OPTIONS,
      identifierTypes: IDENTIFIER_TYPES,
      loading: false,
      feedback: false,
      approved: false,
      agreedTerms: false,
      validTerms: true,
      selectedIdentifierType: null,
      selectedBrand: null,
      selectedVehicleCondition: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'S/ ',
        suffix: '',
        precision: 0,
        masked: false,
      },
      incomeMask: null,
      directives: { money: VMoney },
    };
  },
  methods: {
    sendData() {
      this.$refs.form.validate().then(success => {
        if (!this.agreedTerms) {
          this.validTerms = false;

          return;
        }
        if (!success) return;
        this.scrollToTop();
        const event = this.simulatorFirst ? 'send-info' : 'proceed';
        this.$emit(event, this.form);
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    computedBrands() {
      const brands = Object.keys(this.brands).map(key => ({
        key,
        options: this.brands[key],
      }));

      return brands;
    },
  },
  watch: {
    agreedTerms() {
      this.validTerms = this.agreedTerms;
    },
    incomeMask() {
      this.form.income = Number(this.incomeMask.replace(/[^0-9]/g, ''));
    },
    selectedBrand() {
      this.form.vehicleBrand = this.selectedBrand.name;
      this.form.vehicleBrandId = this.selectedBrand.indexaId;
      this.form.vehicleDistributor = this.selectedBrand.distributor;
    },
    selectedVehicleCondition() {
      this.form.vehicleCondition = this.selectedVehicleCondition.id;
    },
    selectedIdentifierType() {
      this.form.identifierType = Number(this.selectedIdentifierType.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/app/variables';
@import './form/form';

.form {
  --font-size: 20px;
  --spacing: 17.5px;

  &__link {
    color: unset;
  }

  &__row {
    &--terms {
      color: $gray-color;
      display: flex;
      font-size: 1em;
      line-height: 30px;
    }
  }

  &__amount {
    color: $primary-color;
  }

  &__terms {
    font-size: .65em;
    padding-left: .6em;
  }
}
</style>
