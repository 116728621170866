<template>
  <ValidationObserver
    ref="form"
    tag="div"
  >
    <DocError v-if="uploadError"/>
    <div v-else>
      <span v-if="!isValid()">Esta URL no es válida.</span>
      <form
        v-else
        class="form"
      >
        <div class="form__row form__row--title">
          <div class="form__check"></div>
          <div class="subtitle mb-0">
            <b>PASO 3</b>
          </div>
          <div class="title">
            <b>Documentación sustentatoria</b>
          </div>
          <div class="subtitle">
            Hola {{ this.name }}, tu solicitud ingresada fue <b>pre-aprobada</b> <br/>
            Carga los siguientes documentos de respaldo para que las <br/>
            financieras puedan validar tu información y continuar con el proceso.<br/>
            Si no puedes adjuntar los documentos, envíalos a <a href="https://api.whatsapp.com/send?phone=56974127170">+56974127170</a>
          </div>
        </div>
        <file-input
          documentType="cedula"
          @updateFiles="getDocuments($event, 'cedulas')"
          multiple
        >
          <template v-slot:title>Cédula de identidad</template>
          <template v-slot:subtitle>(Por ambos lados)</template>
          <template v-slot:bubbleMessage>Debes subir uno o dos fotos/documentos de tu cédula de identidad, donde todos los campos del documento sean visibles.</template>
        </file-input>
        <span
          v-if="(errorDocument('cedulas') || documentErrors.cedulas)"
          class="error"
        >* Campo obligatorio</span>
        <!-- PASAPORTE -->
        <hr v-if="isExtranjero()">
        <file-input
          v-if="isExtranjero()"
          documentType="pasaporte"
          @updateFiles="getDocuments($event, 'pasaporte')"
        >
          <template v-slot:title>Pasaporte</template>
          <template v-slot:bubbleMessage>Documento público que el Gobierno otorga a sus nacionales, por intermedio de sus organismos competentes, cuando estos deben viajar al exterior o se encuentran en territorio de un país extranjero.</template>
        </file-input>
        <span
          v-if="(errorDocument('pasaporte') || documentErrors.pasaporte)"
          class="error"
        >* Campo obligatorio</span>
        <hr v-if="isExtranjero()">
        <file-input
          v-if="isExtranjero()"
          documentType="visa"
          @updateFiles="getDocuments($event, 'visa')"
        >
          <template v-slot:title>Comprobante de<br/>Solicitud de Visa</template>
          <template v-slot:bubbleMessage>Documento emitido por el Departamento de Extranjería y Migración al momento de enviar o presentar tu solicitud de Permanencia Definitiva en trámite. Dicho documento tiene una vigencia de 6 meses.</template>
        </file-input>
        <span
          v-if="(errorDocument('visa') || documentErrors.visa)"
          class="error"
        >* Campo obligatorio</span>
        <hr v-if="isDependiente()">
        <file-input
          v-if="isDependiente()"
          documentType="liquidaciones"
          @updateFiles="getDocuments($event, 'liquidaciones')"
          multiple
        >
          <template v-slot:title>Últimas 3<br/>liquidaciones de sueldo</template>
          <template v-slot:bubbleMessage>Documento entregado por el empleador, donde se indican los montos pagados por sueldo base, cargas familiares y los descuentos legales de pensiones y salud, entre otros conceptos.</template>
        </file-input>
        <span
          v-if="(errorDocument('liquidaciones') || documentErrors.liquidaciones)"
          class="error"
        >* Campo obligatorio</span>
        <hr>
        <file-input
          documentType="domicilio"
          @updateFiles="getDocuments($event, 'domicilios')"
        >
          <template v-slot:title>Comprobante<br/>de domicilio</template>
          <template v-slot:bubbleMessage>Contrato de arrendamiento, recibo de luz, teléfono fijo, agua, gas, cuenta bancaria o de gastos relacionada a tu nombre, que compruebe el domicilio donde resides.</template>
        </file-input>
        <span
          v-if="(errorDocument('domicilios') || documentErrors.domicilios)"
          class="error"
        >* Campo obligatorio</span>
        <hr v-if="isDependiente()">
        <file-input
          v-if="isDependiente()"
          documentType="cotizaciones"
          @updateFiles="getDocuments($event, 'cotizaciones')"
        >
          <template v-slot:title>Certificado 12 ultimas<br/>cotizaciones</template>
          <template v-slot:subtitle>(Con RUT de empleador)</template>
          <template v-slot:bubbleMessage>Documento que muestra el resumen de tus pagos previsionales efectuados en tu cuenta obligatoria de la AFP en que te encuentres afiliado.</template>
        </file-input>
        <span
          v-if="(errorDocument('cotizaciones') || documentErrors.cotizaciones)"
          class="error"
        >* Campo obligatorio</span>
        <hr v-if="!isDependiente()">
        <file-input
          v-if="!isDependiente()"
          documentType="carpeta"
          @updateFiles="getDocuments($event, 'carpetas')"
        >
          <template v-slot:title>Carpeta<br/>Tributaria</template>
          <template v-slot:bubbleMessage>La Carpeta Tributaria es un trámite rápido y sencillo que todo contribuyente puede realizar ante la plataforma del SII de forma automática. Esta carpeta electrónica sirve de aval en momentos donde se requiere solicitar un crédito a una institución bancaria, acreditar rentas o acreditar tamaño de empresa.</template>
        </file-input>
        <span
          v-if="(errorDocument('carpetas') || documentErrors.carpetas)"
          class="error"
        >* Campo obligatorio</span>
        <div class="form__row form__row--button">
          <button
            type="button"
            class="amicar-wrapper__button amicar-wrapper__button--send amicar-wrapper__button--no-margin"
            style="cursor:pointer"
            @click="sendData()"
          >
            Enviar Documentos
          </button>
        </div>
      </form>
      <div
        class="spinner__container"
        style="text-align: center;"
        v-show="loading"
      >
        <AmicarLoading :message="'Estamos guardando tus documentos.'" />
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import './form/validation-rules';
import FormInput from './form/form-input';
import FileInput from './form/file-input';
import AmicarLoading from './amicar-loading.vue';
import UploadService from '../services/upload.js';
import FormService from '../services/form.js';
import DocError from './DocumentationError'

const formService = new FormService();
const uploadService = new UploadService();

export default {
  components: {
    ValidationObserver,
    FormInput,
    FileInput,
    AmicarLoading,
    DocError,
  },
  data() {
    return {
      name: null,
      lead: null,
      form: {
        cedulas: {
          files: null,
          fileNames: '',
          required: true,
          error: false,
        },
        pasaporte: {
          files: null,
          fileNames: '',
          required: false,
          error: false,
        },
        visa: {
          files: null,
          fileNames: '',
          required: false,
          error: false,
        },
        liquidaciones: {
          files: null,
          fileNames: '',
          required: false,
          error: false,
        },
        domicilios: {
          files: null,
          fileNames: '',
          required: true,
          error: false,
        },
        cotizaciones: {
          files: null,
          fileNames: '',
          required: false,
          error: false,
        },
        carpetas: {
          files: null,
          fileNames: '',
          required: false,
          error: false,
        },
      },
      documentErrors: {
        cedulas: false,
        pasaporte: false,
        visa: false,
        liquidaciones: false,
        domicilios: false,
        cotizaciones: false,
        carpetas: false
      },
      loading: false,
      error: false,
      today: new Date().toISOString(),
      uploadError: false,
    };
  },
  created() {
    this.name = this.$route.query.name;
    this.lead = this.$route.query.lead;
    if(this.isDependiente()){
      this.form.liquidaciones.required = true;
      this.form.cotizaciones.required = true;
    }else{
      this.form.carpetas.required = true;
    }
    if(this.isExtranjero()){
      this.form.pasaporte.required = true;
      this.form.visa.required = true;
    }
  },
  methods: {
    isValid() {
      const token = atob(this.$route.query.token);
      return token === this.lead + this.name.replace(/ /g, '') || token === this.lead + this.name;
    },
    isExtranjero() {
      return (this.$route.query.nac === 'e');
    },
    isDependiente() {
      return (this.$route.query.type === 'dependiente');
    },
    sendData() {
      this.$refs.form.validate().then(success => {
        if (!success) return;
        this.uploadDocuments();
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async uploadDocuments() {
      if (!this.validForm()) {
        this.error = true;
        return;
      } else {
        this.error = false;
        this.loading = true;
        try {
          this.scrollToTop();
          const formData = new FormData();
          formData.append('lead', this.lead);
          for(const key in this.form){
            if(this.form[key].required){
              this.form[key].files.forEach(file => formData.append(`${key}[]`, file));
            }
          }
          formData.append('fileCount', this.getFormFilesLength());
          await uploadService.upload(formData);
          this.$cookies.remove('answers');
          const body = {
            'lead': this.lead,
            'documentacion_ok': true,
          };
          await formService.updateLead(body);
          this.loading = false;
          window.location.href = `/exito-documentacion?lead=${this.lead}&cedulas=${this.form.cedulas.fileNames}&cotizaciones=${this.form.cotizaciones.fileNames}&domicilios=${this.form.domicilios.fileNames}&carpetas=${this.form.carpetas.fileNames}&liquidaciones=${this.form.liquidaciones.fileNames}&pasaporte=${this.form.pasaporte.fileNames}&visa=${this.form.visa.fileNames}`;
        } catch (e) {
          this.loading = false;
          this.$cookies.remove('answers');
          console.error("Error al subir los documentos", e);
          this.uploadError = true;
        }
      }
    },
    getFormFilesLength(){
      let totalFiles = 0;
      for (const key in this.form) {
        if (this.form[key].required) {
          totalFiles = totalFiles + this.form[key].files.length
        }
      }
      return totalFiles;
    },
    getDocuments(value, document) {
      this.form[document].files = value;
      this.form[document].fileNames = this.getFilenames(value);
    },
    getFilenames(files){
      var fileNames = '';
      files.forEach(file => {
        fileNames = fileNames === '' ? fileNames.concat(file.name) : fileNames.concat(`,${file.name}`);
      });
      return fileNames;
    },
    errorDocument(document) {
      if(this.form[document].files !== null){
        if(this.form[document].files.length <= 0){
          this.form[document].error = true;
          return true;
        }else{
          this.documentErrors[document] = false;
          this.form[document].error = false;
          return false;
        }
      }else{
        this.form[document].error = false;
        return false;
      }
    },
    validForm() {
      let errorCount = 0;
      for (const key in this.form) {
        if(this.form[key].required) {
          if (this.form[key].error) {
            errorCount++;
          }else if(this.form[key].files === null){
            this.documentErrors[key] = true;
            errorCount++;
          }
        }
      }
      if(errorCount===0) {
        return true;
      }else{
        return false;
      }
    },
  },
};
</script>

<style lang="scss">

hr {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px dashed #D5D5D5;
}

.title {
  width: 100%;
  padding-top: 9px;
  text-align: center;
  font-size: 23px;
  color: #112D47;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}

.error {
  width: 100%;
  padding-right: 60px;
  font-size: 12px;
  font-family: "Source Sans Pro";
  font-weight: 300;
  color: red;
  // line-height: 19px;
  text-align: right;
}

.subtitle {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 14px;
  font-size: 15px;
  font-family: "Source Sans Pro";
  font-weight: 300;
  color: #6F6F6F;
  line-height: 19px;
  text-align: center;
}

</style>

<style lang="scss" scoped>
@import '../styles/app/variables';
@import './form/form';

.form {
  color: #B9B9B9;;
  --font-size: 20px;
  --spacing: 17.5px;
  border: 1px solid rgba(196, 202, 213, 0.24);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 29px 35px 0 rgba(212, 212, 212, 0.29);

  &__check {
    height: 100px;
    width: 100px;
    background: url("../assets/Documentos_azul.png") no-repeat center;
    background-size: cover;
    margin: auto;
    margin-bottom: 20px;
  }
}
</style>
