/* eslint-disable no-magic-numbers, no-new */
/* global process */

import Vue from 'vue';
import VMoney from 'v-money';
import { rutInputDirective } from 'vue-dni';
import { camelizeKeys } from 'humps';
import VueCookies from 'vue-cookies';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import VueMask from 'v-mask';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import VCalendar from 'v-calendar';
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import './index';

import CapitalizeFilter from '../filters/capitalize';
import LowercaseFilter from '../filters/lowercase';
import TitleCaseFilter from '../filters/titlecase';

import numberFormats from '../locale/number-formats';
import messages from '../locale/messages';
import vMoneyConfigByLocale from '../locale/v-money-config-by-locale';

import Simulator from '../components/simulator.vue';
import SimulatorPreview from '../components/simulator-preview.vue';

import AmicarWrapper from '../components/amicar-wrapper.vue';
import Disapproved from '../components/disapproved.vue';
import Rejected from '../components/rejected.vue';
import FeedbackPe from '../components/es-PE/feedback.vue';
import DistributorsMap from '../components/distributors-map.vue';
import Faqs from '../components/faqs.vue';
import CreditInfo from '../components/credit_info/credit-info.vue';
import Scenarios from '../components/scenarios.vue';
import LeadsForm from '../components/leads-form.vue';
import PersonalData from '../components/personal-data.vue';
import Documentation from '../components/documentation.vue';
import ProductCarousel from '../components/product-carousel.vue';
import Thanks from '../components/thanks.vue';
import PersonalDataThanks from '../components/personal_data_thanks.vue';
import DocumentationThanks from '../components/documentation_thanks.vue';
import AmicarLoading from '../components/amicar-loading.vue';

import '../styles/application.scss';

Vue.component('clip-loader', ClipLoader);
Vue.use(VueCookies);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(VueMask);
Vue.use(VCalendar);
Vue.use(Buefy);
Vue.filter('camelizeKeys', value => camelizeKeys(value));
Vue.filter('capitalize', CapitalizeFilter);
Vue.filter('titlecase', TitleCaseFilter);
Vue.filter('lowercase', LowercaseFilter);
Vue.directive('rut', rutInputDirective);
Vue.filter('roundTo', (value, positions) => {
  const power = 10 ** positions;

  return Math.round(value * power) / power;
});

const router = new VueRouter({
  mode: 'history',
});

function isStaging() {
  if (process.env.VUE_APP_STAGING_MODE) {
    return true;
  }

  return false;
}

function getLocale() {
  if (isStaging()) {
    return Vue.cookies.get('change_locale');
  }

  return window.location.origin.match(/(\.pe)/) ? 'es-PE' : 'es-CL';
}

const i18n = new VueI18n({
  locale: getLocale(),
  numberFormats,
  messages,
});

Vue.use(VMoney, vMoneyConfigByLocale[getLocale()]);

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('app')) {
    new Vue({
      el: '#app',
      i18n,
      router,
      components: {
        Simulator,
        SimulatorPreview,
        DistributorsMap,
        AmicarWrapper,
        Disapproved,
        Rejected,
        FeedbackPe,
        Faqs,
        CreditInfo,
        Scenarios,
        LeadsForm,
        PersonalData,
        Documentation,
        ProductCarousel,
        Thanks,
        PersonalDataThanks,
        DocumentationThanks,
        AmicarLoading,
      },
    });
  }

  const menuButton = document.getElementById('menu-button');
  if (menuButton) {
    menuButton.addEventListener('click', () => {
      const menu = document.getElementById('navbar-menu');
      const button = document.getElementById('menu-button');
      if (button.className === 'navbar__menu-button navbar__menu-button--menu') {
        menu.style.height = 'calc(100vh - 50px)';
        button.className = 'navbar__menu-button navbar__menu-button--close';
      } else {
        menu.style.height = '0';
        button.className = 'navbar__menu-button navbar__menu-button--menu';
      }
    });
  }
});
