/* eslint-disable max-statements, max-statements-per-line, eqeqeq, no-negated-condition*/
function validateRut(fullRut) {
  const values = fullRut.split('.')
    .join('').split('-')
    .join('');
  const rut = values.slice(0, -1);
  let dv = values.slice(-1).toUpperCase();
  if (rut.length < 7) return false;
  let sum = 0;
  let mult = 2;
  let index;
  for (let i = 1; i <= rut.length; i++) {
    index = mult * rut.charAt(rut.length - i);
    sum += index;
    if (mult < 7) { mult += 1; } else { mult = 2; }
  }
  const expectedDv = 11 - (sum % 11);
  dv = (dv == 'K') ? 10 : dv;
  dv = (dv == 0) ? 11 : dv;
  if (expectedDv != dv) { return false; }

  return true;
}

export default validateRut;
