<template>
  <div
    class="distributor-card"
    @click="$emit('close-card')"
  >
    <div class="distributor-card__content">
      <div class="distributor-card__close" />
      <div class="distributor-card__name">
        {{ distributor.name }}
      </div>
      <div class="distributor-card__address">
        <a
          :href="`https://maps.google.com/?q=${distributor.address}`"
          target="_blank"
        >{{ distributor.address }}</a>
      </div>
      <div v-if="distributor.phone">
        {{ distributor.phone }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DistributorCard',
  props: {
    distributor: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.distributor-card {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: .9;

  &__close {
    background: url('~images/close.svg') no-repeat center;
    background-size: cover;
    cursor: pointer;
    height: 28px;
    position: absolute;
    top: 3px;
    right: 3px;
    width: 28px;
  }

  &__content {
    height: 150px;
    width: 80%;
    max-width: 350px;
    background-color: #fff;
    margin: 0 auto;
    padding: 24px 0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  &__name {
    font-weight: 600;
  }
}
</style>
