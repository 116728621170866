<template>
  <div class="section faq-section">
    <div class="section__title">
      Preguntas Frecuentes
    </div>
    <div class="columns is-multiline">
      <div class="column is-3 is-full-touch">
        <div class="box columns is-multiline is-flex is-vcentered m-4 px-5">
          <div :class="'column is-full is-full-mobile is-clickable faq__category is-flex px-0 '+isActive('PAGO')" @click="setCategory('PAGO')">
            <i class="material-icons-outlined px-4">directions_car</i>
            <span class="pr-4">Pago del crédito</span>
          </div>
          <div :class="'column is-full is-full-mobile is-clickable faq__category is-flex px-0 '+isActive('REQUISITOS')" @click="setCategory('REQUISITOS')">
            <i class="material-icons-outlined px-4">calculate</i>
            <span class="pr-4">Requisitos del crédito</span>
          </div>
          <div :class="'column is-full is-full-mobile is-clickable faq__category is-flex px-0 '+isActive('OTROS')" @click="setCategory('OTROS')">
            <i class="material-icons-outlined px-4">forum</i>
            <span class="pr-4">Otras preguntas</span>
          </div>
          <div :class="'column is-full is-full-mobile is-clickable faq__global is-flex px-0 '+isActive('all')" @click="setCategory('all')">
            <i class="material-icons-outlined px-4">public</i>
            <span class="pr-4">Todo</span>
          </div>
        </div>
      </div>
      <div class="column is-full-touch">
        <div class="faq-section__faqs">
          <div
            :id="'faq-'+faq.order"
            class="faq"
            v-for="faq in visibleFaqs"
            :key="faq.id"
            @click="toggleResponse(faq)"
          >
            <div class="faq__title">
              {{ faq.title }}
            </div>
            <div
              class="faq__toggle"
              :class="{'faq__toggle--toggled': !showResponses[faq.order]}"
            />
            <div
              :ref="`response${faq.order}`"
              class="faq__response"
              v-html="markdown(faq.body)"
            />
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import marked from 'marked';
import DOMPurify from 'dompurify';
import StrapiService from '../services/strapi'

const strapiService = new StrapiService();

export default {
  data() {
    return {
      showResponses: {},
      faqs: [],
      category: 'PAGO',

    };
  },
  async created() {
    let strapiFaqs = await strapiService.get_faqs();
    this.faqs = await strapiFaqs.strapi
              .filter((faq) => faq.visible)
              .sort((faqA, faqB) => faqA.order - faqB.order)
    this.faqs.forEach((faq) => {
      faq.body = faq.body.replaceAll("https://web-portal-clientes-testing-6yvamsdnmq-ue.a.run.app/users/login", '')
      this.$set(this.showResponses, faq.order, false)
    })
  },
  methods: {
    toggleResponse(faq) {
      this.showResponses[faq.order] = !this.showResponses[faq.order];
      if (this.showResponses[faq.order]) {
        const height = this.$refs[`response${faq.order}`][0].scrollHeight;
        this.$refs[`response${faq.order}`][0].style.height = `${height}px`;
      } else {
        this.$refs[`response${faq.order}`][0].style.height = '0';
      }
    },
    markdown(content) {
      return marked(DOMPurify.sanitize(content));
    },
    setCategory(category) {
      this.category = category
    },
    isActive(category) {
      return (this.category === category) ? 'active' : ''
    }
  },
  computed: {
    visibleFaqs() {
      return this.faqs.filter((faq) => {
        return faq.category === this.category || this.category === 'all'
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../styles/app/variables';
.faq {
  &__category {
    color: $primary-color;
    border-bottom: 1px solid #ddd;
    padding: 15px;
    
  }
  &__global {
    color: $text-color;
    padding: 15px
  }
  &__global .material-icons-outlined {
    color: $text-color;
  }
}

.active {
  font-weight: 900;
}
</style>
