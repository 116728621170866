<template>
  <option
    v-if="option && option.id"
    :selected="selectedValue && selectedValue.id === option.id"
    :value="JSON.stringify(option)"
  >
    {{ option.name }}
  </option>
  <option
    v-else
    :key="option"
    :selected="selectedValue === option"
    :value="option"
  >
    {{ option }}
  </option>
</template>

<script>
export default {
  props: {
    option: {
      type: [String, Object],
      required: true,
    },
    selectedValue: {
      type: [String, Object],
      default: null,
    },
  },
};
</script>