const baseConfig = {
  precision: 0,
  decimal: ',',
  thousands: '.',
  suffix: '',
  masked: false,
};

const altConfig = {
  precision: 0,
  decimal: '.',
  thousands: ',',
  suffix: '',
  masked: false,
};

export default {
  'es-CL': { ...baseConfig, prefix: '$' },
  'es-PE': { ...altConfig, prefix: 'S/ ' },
  'en-US': { ...altConfig, prefix: 'USD ' },
};
