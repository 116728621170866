const numberFormats = {
  'es-PE': {
    currency: { style: 'currency', currency: 'PEN', minimumFractionDigits: 0, maximumFractionDigits: 0 },
    'currency-PEN': { style: 'currency', currency: 'PEN', minimumFractionDigits: 0, maximumFractionDigits: 0 },
    'currency-USD': { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
  },
  'es-CL': {
    currency: { style: 'currency', currency: 'CLP' },
    'currency-CLP': { style: 'currency', currency: 'CLP' },
  },
};

export default numberFormats;
