<template>
  <component
    :is="feedback()"
    @find="find()"
    :form="form"
  />
</template>

<script>
import Approved from './approved.vue';
import Rejected from './rejected.vue';

export default {
  props: {
    form: {
      type: Object,
      default: () => { },
    },
    approved: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    find() {
      window.location.href = '/red-de-concesionarios';
    },
    feedback() {
      if (this.approved) {
        return Approved;
      }

      return Rejected;
    },
  },
};
</script>

<style lang="scss" scoped>

@import '../../styles/app/variables';

.feedback {
  background-color: $feedback-pe-background;
  color: $feedback-pe-text;
  padding: 42px;
  text-align: center;
}

::v-deep .feedback {
  &__greeting {
    font-size: 41px;
    padding: 5px 0;
    font-weight: bold;
  }

  &__hi {
    color: $feedback-pe-text-hi;
    font-size: 36px;
  }

  &__subtitle {
    font-size: 22px;
    padding: 5px 0;
    font-weight: bold;
  }

  &__message {
    font-size: 15px;
    font-weight: lighter;
    color: $feedback-pe-text;
    letter-spacing: .6px;
    padding: 10px 0;
  }

  &__field {
    border-top: solid 1px #000;
    font-size: 18px;
    padding: 5px;

    &--bottom {
      border-bottom: solid 1px #000;
    }
  }

  &__button {
    background-color: $feedback-back;
    color: #fff;
    cursor: pointer;
    margin-top: 30px;
    padding: 8px 16px;
    border: 0;
    border-radius: 20px;
    font-weight: 500;
    letter-spacing: 1.2px;
  }
}
</style>

