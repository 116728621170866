<template>
  <div class="feedback">
    <div class="feedback__greeting">
      <span class="feedback__hi">¡Felicitaciones!</span> {{ form.name }},
    </div>
    <div class="feedback__message">
      Ya estas a un paso de tener el auto que deseas.
    </div>
    <div class="feedback__message">
      Te hemos enviado un correo con los datos de tu simulación <br>
      para que puedas acercarte a tu concesionario más cercano
    </div>
    <button
      class="feedback__button"
      @click="find()"
    >
      Encuéntralo aquí
    </button>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    find() {
      this.$emit('find');
    },
  },
};
</script>

