<template>
  <div class="form__horizontal">
    <form-input>
      <div class="label">
        <div :class="documentType" />
        <slot name="title"></slot>
        <span class="documentation_form">
          <span
            @mouseenter="toggleBubble(true)"
            @mouseleave="toggleBubble(false)"
            class="documentation_form__icon material-icons"
          >
            help
          </span>
          <div
            class="documentation_form__bubble"
            :class="{ 'documentation_form__bubble--visible': showBubble }"
          ><slot name="bubbleMessage"></slot></div>
        </span>
        <br>
        <div v-if="documentType!=='liquidaciones'">
          <span style="font-size:11px;padding-left:38px;"><slot name="subtitle"></slot></span>
          <br>
        </div>
        <span v-if="multiple" style="font-size:10px;padding-left:38px;color:#F66536;font-weight: bold">Seleccione uno o mas documentos</span>
      </div>
    </form-input>
    <form-input>
      <button
        type="button"
        class="documentation_form__button documentation_form__button--send documentation_form__button--no-margin"
        @click="getFile()"
      >{{ buttonMessage }}
      </button>
      <div class="info">
        Máximo 25 MB de PDF o imagen (jpeg, jpg, png)
      </div>
      <button
        v-for="(doc, index) in files"
        :key="index"
        type="button"
        class="file_button"
        @click="removeFile(index)"
      >
        <span class="filename">
          {{ doc.name | truncate(8, '...') }}
        </span>
        <i class="material-icons">clear</i>
      </button>
    </form-input>
    <input
      type="file"
      accept=".pdf,.jpeg,.jpg,.png"
      :multiple="multiple"
      :id="documentType"
      :ref="documentType"
      @change="handleFileUpload()"
      style="display:none;"
    >
  </div>
</template>

<script>
import FormInput from './form-input.vue';
export default {
  components: {
    FormInput,
  },
  props: {
    documentType: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.buttonMessage = this.documentType == 'cedula' || this.documentType == 'liquidaciones' ? 'Agregar Documento(s)' : 'Agregar Documento'
    this.multiple = (this.documentType == 'cedula' || this.documentType == 'liquidaciones')
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      }
      return text;
    },
  },
  data() {
    return {
      showBubble: false,
      buttonMessage: '',
      files: [],
    }
  },
  methods: {
    toggleBubble(display) {
      this.showBubble = display;
    },
    handleFileUpload() {
      Array.from(this.$refs[this.documentType].files).forEach(file => {
        if(this.verifyFile(file)){
          this.files.push(file);
        }
      });
      this.sendFiles();
    },
    getFile() {
      this.$refs[this.documentType].click();
    },
    removeFile(index) {
      this.files = this.files.filter((file, customIndex) => customIndex !== index);
      this.sendFiles();
    },
    sendFiles() {
      this.$emit('updateFiles', this.files);
    },
    verifyFile(file) {
      if(file.size <= 0 || file.size > (25 * 1024 * 1024)){
        alert(`El archivo ${file.name} no cumple con el tamaño requerido`);
        return false;
      }else{
        return true;
      }
    },
    alertInvalidFile(name){
      this.$buefy.dialog.alert({
        title: 'Error',
        message: `El archivo ${name} no cumple con el tamaño requerido`,
        type: 'primary',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/app/variables';
@import '../form/form';
.label {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 14px;
  font-size: 15px;
  font-family: "Source Sans Pro";
  font-weight: 300;
  margin-left: 10%;
  color: #6F6F6F;
  line-height: 19px;
  text-align: left;
}

.material-icons {
  font-size: 11px !important;
  color: red;
}

.documentation_form {
  &__button {
    cursor: pointer;
    background-color: #fff;
    border: 0;
    border-radius: 45px;
    color: #ED6831;
    flex: 1;
    outline: 0;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.18);
    font-size: 14px;
    height: 2em;
    margin-left: 10%;
    opacity: .9;
    text-align: center;
    width: 80%;
    margin-top: 15px !important;

    &:hover {
      opacity: .5;
    }

    &:active {
      opacity: 1;
    }

    &:disabled {
      opacity: .2;
    }

    &--send {
      background-color: #fff;
      margin-left: 1.5%;
    }

    &--back {
      margin-right: 1.5%;
    }

    &--no-margin {
      margin: 0;
    }
  }

  &__icon {
    cursor: pointer;
    margin-left: 5px;
    font-size: 1.25em !important;
    color: #202A49;
  }

  &__bubble {
    position: absolute;
    right: 0;
    bottom: 32px;
    width: 418px;
    white-space: normal;
    font-size: .8125em;
    line-height: 1.307em;
    background-color: #eee;
    padding: 14px;
    border-radius: 4px;
    pointer-events: none;
    opacity: 0;
    z-index: 9;
    transition: opacity .2s ease;

    &--visible {
      opacity: 1;
    }
  }

  &__bubble-arrow {
    position: absolute;
    font-size: 1.65em;
    bottom: -19px;
    right: -5px;
  }
}

.file_button {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #25A6FF;
  border-radius: 15px;
  margin-right: 8px;
  flex: 1 1;
  font-size: 12px;
  height: 2em;
  opacity: .9;
  text-align: center;
}

.filename {
  font-size: 12px;
}

.info {
  width: 80%;
  padding-top: 5px;
  padding-bottom: 8px;
  font-size: 9px;
  font-family: "Source Sans Pro";
  font-weight: bold;
  color: #6F6F6F;
  line-height: 11px;
  text-align: center;
}

.cedula {
  height: 15px;
  width: 23px;
  margin-top: 5px;
  background: url("../../assets/icons/cedula.png") no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 13px;
}

.liquidaciones {
  height: 20px;
  width: 18px;
  background: url("../../assets/icons/liquidaciones.png") no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 13px;
}

.domicilio {
  height: 20px;
  width: 17px;
  background: url("../../assets/icons/domicilio.png") no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 13px;
}

.cotizaciones, .pasaporte, .visa {
  height: 20px;
  width: 17px;
  background: url("../../assets/icons/cotizaciones.png") no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 13px;
}

.carpeta {
  height: 20px;
  width: 17px;
  background: url("../../assets/icons/cotizaciones.png") no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 13px;
}

@media only screen and (max-width: 768px) {
  .documentation_form {
    &__bubble {
      width: 360px;
    }
  }
}
</style>