<template>
  <div class="columns box is-multiline">
    <div class="column is-full has-text-left">
      <p class="pl-3 pt-3 is-color-orange">{{ type }}</p>
    </div>
    <div class="level columns column is-full my-0">
      <div class="level-item column has-text-center scenario-value">
        <p><strong>Valor Cuota</strong></p>
        <p>{{ scenario.monthlyPayment | toCLP }}</p>
      </div>
      <div class="vertical-hr is-hidden-mobile"></div>
      <div class="level-item column has-text-center scenario-value">
        <p><strong>Valor del auto</strong></p>
        <p>{{ scenario.vehiclePrice | toCLP }}</p>
      </div>
      <div class="vertical-hr is-hidden-mobile"></div>
      <div class="level-item column has-text-center scenario-value">
        <p><strong>Pie</strong></p>
        <p>{{ scenario.downPayment | toCLP }} <span class="ml-3 scenario-value-light">{{ scenario.downPaymentPercentage }}%</span></p>
      </div>
    </div>
    <div class="columns column is-full payment-info is-multiline is-gapless">
      <div class="level columns column is-full is-multiline is-mobile scenario-subtitle-value">
        <span class="level-item column has-text-right">A financiar: <b>{{ scenario.balance | toCLP }}</b></span>
        <span class="vertical-hr mx-4 is-hidden-mobile"/>
        <span class="level-item column has-text-left">CAE: <b>{{ scenario.annualCAE }}%</b></span>
      </div>
      <div class="level columns column is-full is-multiline is-mobile scenario-subtitle-value">
        <span class="level-item column has-text-right">Nº Cuotas: <b>{{ scenario.term }}</b></span>
        <span class="vertical-hr mx-4 is-hidden-mobile"/>
        <span class="level-item column has-text-left">Costo total del crédito: <b>{{ scenario.finalCapital | toCLP }}</b></span>
      </div>
      <div class="column is-full">
        <div class="buttons has-margin-top-30 is-centered">
          <button
            id="recover-lead-submit"
            :class="[loading ? 'is-loading' : '', 'button is-primary-amicar is-rounded']"
            @click="sendData()"
          >
            Continuar con este escenario
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormService from '../services/form.js';
const formService = new FormService();
export default {
  props: {
    scenario: {
      type: Object,
      required: true,
    },
    answers: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  filters: {
    toCLP(value) {
      if(value!=null){
        return "$" + value.toLocaleString('es-CL')
      }else{
        return "$0"
      }
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods:{
    sendData() {
      this.updateApprovedScenario();
    },
    async updateApprovedScenario() {
      this.loading = true;
      this.answers.approved = true;
      this.$cookies.set('answers', this.answers);
      const body = {
        lead: this.answers.leadId,
        regla_monto_a_financiar: 'true',
        regla_ingreso_minimo: 'true',
        regla_monto_pie_porc_financiar: 'true',
        regla_score_equifax: 'true',
        regla_valida_morosidad: 'true',
        regla_edad: 'true',
        resultadoEvaluacion: "RECUPERADO",
        montoPie: this.scenario.downPayment,
        porcentajePie: this.scenario.downPaymentPercentage,
        valorCuota: this.scenario.monthlyPayment,
        valorVehiculo: this.scenario.vehiclePrice
      };
      await formService.updateLead(body);
      this.loading = false;
      window.location.href = `/resultado?approved=true&regained=true&email=${this.answers.email}`;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/app/variables';

.is-primary-amicar {
  background-color: $primary-color;
  color: $contrast-color;
  transform: translateY(0.5rem);
  &:hover {
    background-color: $primary-color;
    color: $contrast-color;
  }
  &:focus {
    border: none;
    color: $contrast-color;
    box-shadow: rgba(103, 103, 103, 0.25) 0px 0px 0px 2px;
  }
}

.is-color-orange {
  color: #ED6831;
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-size: 1rem;
}

.scenario-title {
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.scenario-title-value {
  color: #112d47;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
}

.scenario-subtitle-value {
  color: #6c6c6c;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
}

.scenario-subvalue {
  color: #5aadd4;
  font-family: 'Helvetica', sans-serif;
  font-size: 12px !important;
  text-align: center;
  font-weight: bold;
  padding-top: 5px;
}

.scenario-value {
  color: #5aadd4;
  font-family: 'Helvetica', sans-serif;
  font-size: 18px !important;
  text-align: center;
  font-weight: bold;
  padding-top: 5px;
}
.scenario-value-light {
  color: #5aadd4;
  font-family: 'Helvetica', sans-serif;
  font-size: 18px !important;
  text-align: center;
  font-weight: 400;
  padding-top: 5px;
}

.vertical-hr {
  box-sizing: border-box;
  height: 29.25px;
  width: 1px;
  border: 1px solid #C5C5C5;
}

.payment-info{
  background-color: #eeeeeeff;
  border-radius: 0 0 10px 10px;
}

.buttons {
  height: 1.5rem;
}

.box {
  padding: 0;
  box-shadow: 0 0 35px 0 rgba(0,0,0,0.11);
  border-radius: 10px;
}

</style>
