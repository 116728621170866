/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import { parse, isValid, isBefore, isAfter, startOfToday, format as formatDate, parseISO } from 'date-fns';
import { required, email, alpha_spaces, min_value } from 'vee-validate/dist/rules';
import validateRut from '../../utils/rut.js';

extend('required', {
  ...required,
  message: 'Este campo es requerido',
});

extend('email', {
  ...email,
  message: 'Debe ser un email válido',
});

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'No puede estar vacío ni contener números',
});

extend('min_income_cl', {
  ...min_value,
  message: 'Debe ser mayor a $99.000',
});

extend('min_income_pe', {
  ...min_value,
  message: 'Debe ser mayor a S/ 900',
});

extend('min_income_pe_mask', {
  validate: (value, min_income) => {
    if (Number(value.replace(/[^0-9]/g, '')) < Number(min_income[0])) {
      return false;
    }

    return true;
  },
  message: 'Debe ser mayor a S/ 900',
});

extend('min_income_cl_mask', {
  validate: (value, min_income) => {
    if (Number(value.replace(/[^0-9]/g, '')) < Number(min_income[0])) {
      return false;
    }

    return true;
  },
  message: 'Debe ser mayor a $99.000',
});

extend('rut', {
  validate: value => validateRut(value),
  message: 'Rut debe ser válido',
});

extend('phone', {
  validate: value => /^\d{9}$/.test(value),
  message: 'Debe tener 9 dígitos',
});

extend('date_format', {
  // https://github.com/logaretm/vee-validate/blob/v2/src/rules/date_format.js

  validate: (date, format) => {
    if (typeof date !== 'string') {
      return isValid(date) ? date : null;
    }

    const parsed = parse(date, format, new Date());

    if (!isValid(parsed) || formatDate(parsed, format) !== date) {
      return false;
    }

    return true;
  },
  message: 'Fecha no válida',
});

extend('date_before', {
  validate: (date, args) => {
    if (typeof date !== 'string') {
      return isValid(date) ? date : null;
    }

    const parsed = parse(date, args.format, new Date());

    return isBefore(parsed, parseISO(args.limitDate));
  },
  message: 'Fecha no válida',
  params: ['limitDate', 'format'],
});

extend('date_before_or_eq_today', {
  validate: (date, args) => {
    if (typeof date !== 'string') {
      return isValid(date) ? date : null;
    }

    const parsed = parse(date, args.format, new Date());

    return !isAfter(parsed, startOfToday());
  },
  message: 'Fecha no debe estar en el futuro',
  params: ['format'],
});

extend('date_after', {
  validate: (date, args) => {
    if (typeof date !== 'string') {
      return isValid(date) ? date : null;
    }

    const parsed = parse(date, args.format, new Date());

    return isAfter(parsed, parseISO(args.limitDate));
  },
  message: 'Fecha no válida',
  params: ['limitDate', 'format'],
});
