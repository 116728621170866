<template>
<div class="columns box disapproved-box is-multiline is-desktop">
  <div class="column is-full has-text-left">
    <b-button
      id="recover-lead-submit"
      class="button is-outlined"
      rounded
      @click="redirectHome()"
    >
      Volver a Simular
    </b-button>
  </div>
  <div class="column has-text-left">
    <div class="feedback-disapproved">
      <div class="feedback-disapproved__greeting">
        😢
      </div>
      <div class="feedback-disapproved__subtitle">
        El escenario que seleccionaste parece tener bajas probabilidades.
      </div>
      <div class="feedback-disapproved__message">
        Recuerda que para solicitar financiamiento por <b>{{ debtAmount | toCLP }}</b> en autos
        <b>{{ form.vehicleCondition=== 'N' ? 'nuevos' : 'usados'}}</b>,
        las financieras solicitan una renta líquida mínima de
        <b>{{ form.vehicleCondition=== 'N' ? Math.round(debtAmount/11000)*1000 : Math.round(debtAmount/7000)*1000 | toCLP }}</b>.
        Si tu renta es menor, te recomendamos modificar el <b>valor del vehículo</b> o <b>pie (monto a aportar)</b>.
      </div>
    </div>
    <div class="columns box is-multiline my-6">
      <div class="column is-full has-text-left">
        <p class="pl-3 pt-3">Escenario Actual</p>
      </div>
      <div class="level columns column is-full my-0">
        <div class="level-item column has-text-center scenario-value">
          <p><strong>Valor Cuota</strong></p>
          <p>{{ form.monthlyPayment | toCLP }}</p>
        </div>
        <div class="vertical-hr is-hidden-mobile"></div>
        <div class="level-item column has-text-center scenario-value">
          <p><strong>Valor del auto</strong></p>
          <p>{{ form.vehiclePrice | toCLP }}</p>
        </div>
        <div class="vertical-hr is-hidden-mobile"></div>
        <div class="level-item column has-text-center scenario-value">
          <p><strong>Pie</strong></p>
          <p>{{ form.downPayment | toCLP }} <span class="ml-3 scenario-value-light">{{ form.downPaymentPercentage }}%</span></p>
        </div>
      </div>
    </div>
  </div>
  <div class="vertical-divider mx-6 is-hidden-touch"/>
  <div class="column container">
    <h3 v-show="loading">{{ response }}</h3>
    <div class="scenarios-spinner__container" v-show="loading">
      <AmicarLoading :message="'Reconfigurando parámetros...'" />
    </div>
    <div v-if="firstScenarioStatus" class="block">
      <Scenario
        :scenario="firstScenario"
        :answers="firstAlternative"
        type="Alternativa 1: Dar más pie"
      />
    </div>
    <div v-if="firstScenarioStatus && secondScenarioStatus" class="block my-6" />
    <div v-if="secondScenarioStatus" class="block">
      <Scenario
        :scenario="secondScenario"
        :answers="secondAlternative"
        type="Alternativa 2: Buscar un auto más barato"
      />
    </div>
    <!-- Doesn't show at mobile -->
    <Rejected
      class="is-hidden-mobile is-hidden-tablet-only	"
      v-if="loading===false && (secondScenarioStatus===false && firstScenarioStatus===false)"
      :form="form"
      :rejected_amount="false"
    />
    <!-- Only shows at mobile -->
    <Rejected
      class="is-hidden-desktop rejected-mobile"
      v-if="loading===false && (secondScenarioStatus===false && firstScenarioStatus===false)"
      :form="form"
      :rejected_amount="false"
    />
  </div>
</div>
</template>

<script>
import AmicarLoading from './amicar-loading.vue';
import Scenario from './AlternativeScenario.vue';
import Rejected from './rejected.vue';
import FormService from '../services/form.js';

const formService = new FormService();

export default {
  components: { AmicarLoading, Scenario, Rejected },
  filters: {
    toCLP(value) {
      if(value!=null){
        return "$" + value.toLocaleString('es-CL')
      }else{
        return "$0"
      }
    }
  },
  data() {
    return {
      loading: true,
      form: {},
      response: "Estamos buscando escenarios alternativos",
      approved: false,
      rejectionMessage: 'Imposible ayudarte',
      firstAlternative: {},
      secondAlternative: {},
      firstScenario: {},
      secondScenario: {},
      firstScenarioStatus: false,
      secondScenarioStatus: false,
      debtAmount: 0,
    };
  },
  mounted() {
    this.form = this.$cookies.get('answers');
    this.debtAmount = this.form.vehiclePrice - this.form.downPayment;
    if (this.canHaveAlternativeScenarios()){
      this.findAlternativeScenarios();
    } else {
      this.loading = false
      this.response = this.rejectionMessage;
    }
  },
  methods: {
    canHaveAlternativeScenarios(){
      return this.$cookies.get('new_scenario') === 'true';
    },
    async findAlternativeScenarios() {
      const debtFactor = this.form.vehicleCondition === 'N' ? 11 : 7
      const approvedLoan = parseInt(this.form.income) * debtFactor;
      this.setFirstScenario(approvedLoan);
      this.setSecondScenario(approvedLoan);
      await this.setMonthlyPayments();
      this.loading = false;
    },
    setFirstScenario(approvedLoan){
      let vehiclePrice = parseInt(this.form.vehiclePrice);
      this.firstAlternative = {...this.form};
      this.firstAlternative.downPayment = vehiclePrice - approvedLoan;
      this.firstAlternative.downPaymentPercentage = Math.round(this.firstAlternative.downPayment * 100 / vehiclePrice);
      this.firstAlternative.scenario = this.firstAlternative.creditType + "|" +
        this.firstAlternative.downPaymentPercentage + "|" + this.firstAlternative.term;
    },
    setSecondScenario(approvedLoan){
      this.secondAlternative = {...this.form};
      let vehiclePrice = this.secondAlternative.downPayment + approvedLoan;
      this.secondAlternative.vehiclePrice = vehiclePrice;
      this.secondAlternative.downPaymentPercentage = Math.round(this.secondAlternative.downPayment * 100 / vehiclePrice);
      this.secondAlternative.scenario = this.secondAlternative.creditType + "|" +
        this.secondAlternative.downPaymentPercentage + "|" + this.secondAlternative.term;
    },
    async getMonthlyPayments(altScenario){
      const response = await formService.simulateMonthlyPayments(altScenario);
      return {
        downPayment: altScenario.downPayment,
        downPaymentPercentage: altScenario.downPaymentPercentage,
        term: response.finalLoanValues.term,
        balance: response.finalLoanValues.balance,
        finalCapital: response.finalLoanValues.finalCapital,
        monthlyPayment: response.finalLoanValues.monthlyPayment,
        annualCAE: response.equivalentAnnualCharge.annualCAE,
        vehiclePrice: altScenario.vehiclePrice,
      };
    },
    async setMonthlyPayments(){
      try {
        this.firstScenario = await this.getMonthlyPayments(this.firstAlternative);
        this.firstScenarioStatus = this.filterAlternative(this.firstAlternative);
        this.secondScenario = await this.getMonthlyPayments(this.secondAlternative);
        this.secondScenarioStatus = this.filterAlternative(this.secondAlternative);
      } catch (error) {
        console.log(error);
      }
    },
    redirectHome() {
      window.location.href = '/';
    },
    filterAlternative(alternative) {
      if(alternative.vehicleCondition==='N'){
        return (alternative.downPaymentPercentage>=20 && alternative.downPaymentPercentage<=50);
      }else{
        return (alternative.downPaymentPercentage>=30 && alternative.downPaymentPercentage<=50);
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.scenarios-spinner {
  left: 50%;
  position: relative;
  top: 45%;

  &__container {
    position: relative;
    background-color: white;
    top: 0;
    width: 100%;
    z-index: 999;
  }
}

.feedback-disapproved {
  @import '../styles/app/variables';

  background-color: $feedback-background;
  color: $feedback_text;
  text-align: left;

  &__greeting {
    font-size: 41px;
    padding: 5px 0;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 22px;
    padding: 5px 0;
    font-weight: bold;
  }

  &__message {
    font-size: 15px;
    font-weight: lighter;
    color: #6F6F6F;
    letter-spacing: .6px;
    padding: 10px 0;
  }

  &__field {
    border-top: solid 1px #000;
    font-size: 18px;
    padding: 5px;

    &--bottom {
      border-bottom: solid 1px #000;
    }
  }

  &__button {
    background-color: $feedback-back;
    color: $feedback-text;
    margin-top: 30px;
    padding: 8px 16px;
    border: 0;
    border-radius: 20px;
    font-weight: 500;
    letter-spacing: 1.2px;
  }

}
.vertical-divider {
  box-sizing: border-box;
  height: 90%;
  width: 1px;
  border: 1px solid #dadada;
}

.disapproved-box {
  padding: 4rem;
}

.rejected-mobile {
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
}
</style>
