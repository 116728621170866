<template>
  <div class="feedback">
    <div class="feedback__error mb-4" />
    <div class="feedback__subtitle mb-4">
      Error en la carga de documentos
    </div>
    <div class="feedback__message">
      En estos momentos no podemos procesar tu solicitud, intenta más tarde.
    </div>
    <hr>
    <!--<div class="message-thanks">
      Por medio de un correo electrónico te contáctaremos con respuesta de la validación de tus documentos.
    </div>-->
    <button
      type="button"
      class="amicar-wrapper__button amicar-wrapper__button--send amicar-wrapper__button--no-margin"
      style="cursor:pointer"
      @click="home()"
    >
      Ir al inicio
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lead: null,
    };
  },
  created() {
    this.lead = this.$route.query.lead;
  },
  methods: {
    home() {
      window.location.href = '/';
    },
  },
};
</script>

<style lang="scss" scoped>

.message-thanks {
  height: 36px;
  margin-left: 15%;
  width: 312px;
  margin-bottom: 20px;
  color: #6F6F6F;
  font-family: "Source Sans Pro",serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.feedback {
  @import '../styles/app/variables';

  background-color: $feedback-background;
  color: $feedback_text;
  padding: 42px;
  text-align: center;

  &__subtitle {
    padding: 5px 0;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__error {
    height: 84px;
    width: 88px;
    background: url("../assets/cross.svg") no-repeat center;
    margin-left: 40%;
  }

  &__message {
    font-size: 18px;
    font-weight: bold;
    font-family: "Source Sans Pro", serif;
    color: #6F6F6F;
    letter-spacing: .6px;
    padding: 10px 0;
    text-align: center;
  }

  &__disclaimer {
    font-size: 12px;
    font-weight: lighter;
    line-height: 13px;
    color: #6F6F6F;
    letter-spacing: .6px;
    padding: 10px 0;
  }

  &__field {
    border-top: solid 1px #000;
    font-size: 18px;
    padding: 5px;

    &--bottom {
      border-bottom: solid 1px #000;
    }
  }

  &__button {
    background-color: #fff;
    color: #F66536!important;
    margin-top: 30px;
    padding: 8px 16px;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.18);
    border: 0;
    border-radius: 20px;
    font-weight: 500;
    letter-spacing: 1.2px;
  }
}
</style>

<style lang="scss" scoped>
@import '../styles/app/variables';
@import './form/form';

.form {
  color: #B9B9B9;;
  --font-size: 20px;
  --spacing: 17.5px;
  border: 1px solid rgba(196, 202, 213, 0.24);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 29px 35px 0 rgba(212, 212, 212, 0.29);
}

</style>
