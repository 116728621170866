window.addEventListener('DOMContentLoaded', () => {
  const vehicleTypeSelect = document.getElementById('vehicle-type-select');
  if (vehicleTypeSelect) {
    const brandWrapper = document.getElementById('brand-wrapper');
    const brandSelect = document.getElementById('brand-select');
    vehicleTypeSelect.addEventListener('change', () => {
      if (vehicleTypeSelect.value === 'true') {
        brandWrapper.style.display = 'block';
      } else {
        brandWrapper.style.display = 'none';
        brandSelect.value = null;
      }
    });
  }
});

