<template>
  <div class="container">
    <div
      v-if="!form"
      class="simulator"
      :style="[coloredBackground('background'), coloredText('text')]"
    >
      <div
        v-if="allowCurrencyChange && this.$i18n.locale === 'es-PE'"
        class="simulator__row row"
      >
        <span>Moneda</span>
        <div class="simulator__select">
          <div
            class="simulator__select-arrows"
            :style="coloredText()"
          />
          <select
            v-model="currencyType"
            :style="[coloredText('inputs'), coloredBorder()]"
          >
            <option
              :key="option"
              v-for="option in ['PEN', 'USD']"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="simulator__row">
        <span>Precio del auto:</span>
        <money
          v-if="!amountReadonly"
          class="simulator__input"
          v-model="amount"
          v-bind="vMoneyConfig"
          :style="[coloredText('inputs'), coloredBorder()]"
        />
        <span v-else>{{ $n(initialAmount, i18nCurrencyKey) }}</span>
      </div>

      <div
        v-if="!amountReadonly"
        class="simulator__row simulator__row--info"
      >
        <div class="simulator__slider-container">
          <vue-slider
            v-model="amount"
            v-bind="amountSliderOptions"
          />
        </div>
      </div>
      <div
        v-if="this.$i18n.locale === 'es-PE'"
        class="simulator__row simulator__row--info"
        :style="coloredText('disclaimer')"
      >
        <span>Equivalente a: </span><span>{{ $n(toOtherCurrency(amount), i18nOtherCurrencyKey) }}</span>
      </div>

      <template v-if="downPaymentType === downPaymentTypes.AMOUNT">
        <div class="simulator__row">
          <span>{{ $t('simulator.downPayment') }}:</span>
          <money
            class="simulator__input"
            v-model="downPayment"
            v-bind="vMoneyConfig"
            :style="[coloredText('inputs'), coloredBorder()]"
          />
        </div>
      </template>
      <template v-else>
        <div class="simulator__row">
          <span>{{ $t('simulator.downPayment') }}:</span>
          <div class="simulator__input-container">
            <input
              class="simulator__input simulator__input--with-suffix simulator__input--down-payment"
              v-model.number="downPaymentPercentage"
              :style="[coloredText('inputs'), coloredBorder()]"
            >
            <div
              class="simulator__input-suffix"
              :style="coloredText('inputs')"
            >
              %
            </div>
          </div>
        </div>
      </template>

      <div class="simulator__row simulator__row--info">
        <div class="simulator__slider-container">
          <vue-slider
            v-model="downPaymentPercentage"
            v-bind="downPaymentSliderOptions"
          />
        </div>
      </div>

      <template v-if="downPaymentType === downPaymentTypes.AMOUNT">
        <div
          class="simulator__row simulator__row--info"
          :style="coloredText('disclaimer')"
        >
          <span>Equivalente a: </span>
          <span>
            {{ downPaymentPercentage | roundTo(1) }}%
            {{ this.$i18n.locale === 'es-PE' ? `(${$n(toOtherCurrency(downPayment), i18nOtherCurrencyKey)})` : '' }}
          </span>
        </div>
      </template>
      <template v-else>
        <div
          class="simulator__row simulator__row--info"
          :style="coloredText('disclaimer')"
        >
          <span>Equivalente a: </span>
          <span>
            {{ $n(downPayment, i18nCurrencyKey) }}
            {{ this.$i18n.locale === 'es-PE' ? `(${$n(toOtherCurrency(downPayment), i18nOtherCurrencyKey)})` : '' }}
          </span>
        </div>
      </template>

      <div class="simulator__row simulator__row--error">
        <span v-if="!validConfigurationsOrNotPeru">
          El monto a financiar es muy bajo, intente con otros valores de cuota y precio
        </span>
      </div>

      <div class="simulator__row row">
        <span>{{ intelligentName }}:</span>
        <input
          id="intelligent-checkbox"
          class="simulator__checkbox"
          v-model="intelligent"
          type="checkbox"
          :style="coloredCheckbox(intelligent)"
        >
      </div>

      <div class="simulator__row row">
        <span>Nº Cuotas:</span>
        <div class="simulator__select">
          <div
            class="simulator__select-arrows"
            :style="coloredText()"
          />
          <select
            v-model="term"
            :style="[coloredText('inputs'), coloredBorder()]"
          >
            <option
              :key="option"
              v-for="option in termOptions"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>

      <div
        class="simulator__row simulator__row--result"
        :style="coloredText('result')"
      >
        <span>Valor cuota: </span>
        <span>{{ validConfigurationsOrNotPeru ? $n(monthlyPayment, i18nCurrencyKey) : '-' }}</span>
      </div>

      <div
        v-if="this.$i18n.locale === 'es-PE'"
        class="simulator__row simulator__row--info"
        :style="coloredText('disclaimer')"
      >
        <span>Equivalente a: </span>
        <span>{{ $n(toOtherCurrency(monthlyPayment), i18nOtherCurrencyKey) }}</span>
      </div>

      <hr
        class="simulator__separator"
        :style="coloredBorder('separator')"
      >

      <div
        class="simulator__row simulator__row--summary"
        :style="coloredText('disclaimer')"
      >
        <span class="simulator__total">
          {{ showCost ? `Costo total del crédito: ${$n(totalCost, i18nCurrencyKey)}` : 'Valor cuota referencial' }}
        </span>
        <span class="simulator__cae">
          <template v-if="showCost">CAE: {{ this.equivalentAnnualCharge }}%</template>
          <span
            @mouseenter="toggleCaeBubble(true)"
            @mouseleave="toggleCaeBubble(false)"
            class="simulator__cae-icon material-icons"
          >help</span>
          <div
            class="simulator__cae-bubble"
            :class="{ 'simulator__cae-bubble--visible': showCaeBubble }"
            :style="[coloredBackground('bubble'), coloredText('text')]"
          >
            <span
              class="material-icons simulator__cae-bubble-arrow"
              :style="coloredText('bubble')"
            >arrow_drop_down</span>
            {{ $t('simulator.info') }}
          </div>
        </span>
      </div>

      <div
        class="simulator__row simulator__row--summary"
        v-if="intelligent"
        :style="coloredText('disclaimer')"
      >
        <span>Cuota Final Nº {{ term + 1 }}: </span>
        <span>
          {{ $n(vfmg, i18nCurrencyKey) }}
          {{ this.$i18n.locale === 'es-PE' ? `(${$n(toOtherCurrency(vfmg), i18nOtherCurrencyKey)})` : '' }}
        </span>
      </div>

      <div class="simulator__row simulator__row--button">
        <button
          @click="acceptSimulation()"
          v-if="showFormButton"
          :disabled="!validConfigurationsOrNotPeru"
          :style="coloredBackground('result')"
          class="simulator__button"
        >
          {{ formButtonText }}
        </button>
      </div>
    </div>

    <component
      @toggle-form="toggleForm()"
      v-if="form"
      v-bind="$attrs"
      :simulator-data="simulatorData"
      :simulator-data-in-pen="simulatorDataInPen"
      :exchange-rate="exchangeRate"
      :brands="brands"
      :from="from"
      :origin-url="originUrl"
      :initial-vehicle-brand="vehicleBrand"
      :show-vehicle-condition="vehicleCondition"
      :result-success-text="resultSuccessText"
      :result-rejected-text="resultRejectedText"
      :currency-type="currencyType"
      :is="countryForm"
    />
  </div>
</template>

<script>
/* eslint-disable no-magic-numbers */
import VueSlider from 'vue-slider-component';
import debounce from 'lodash.debounce';
import SimulatorFormCl from './simulator-form-cl.vue';
import SimulatorFormPe from './simulator-form-pe.vue';

import irr from '../utils/irr';

import ColorMixin from '../mixins/color';
import SizeMixin from '../mixins/size';
import MulticurrencyMixin from '../mixins/multicurrency';

const CONVENTIONAL_TERM_OPTIONS = [12, 24, 36, 48];
const PERU_EXTRA_TERMS = [60, 72];
const INTELLIGENT_TERM_OPTIONS = [24, 36, 48];
const CL_AMOUNT_INTERVAL = 500000;
const PE_AMOUNT_INTERVAL = 100;
const CL_INITIAL_AMOUNT = 10000000;
const PE_INITIAL_AMOUNT = 15000;
const DEBOUNCE_RATE = 800;

import StrapiService from "../services/strapi";
const strapiService = new StrapiService();

export default {
  components: { VueSlider },
  mixins: [ColorMixin, SizeMixin, MulticurrencyMixin],
  props: {
    amountReadonly: {
      type: Boolean,
      default: false,
    },
    downPaymentType: {
      type: Number,
      default: 0,
    },
    downPaymentRanges: {
      type: Object,
      default: () => ({ conventional: { min: 0, max: 100 }, intelligent: { min: 0, max: 50 } }),
    },
    intelligentName: {
      type: String,
      default() {
        return this.$t('smartChoice');
      },
    },
    useAmicarForm: {
      type: Boolean,
      default: true,
    },
    useModalForm: {
      type: Boolean,
      default: false,
    },
    redirectionUrl: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: undefined,
    },
    originUrl: {
      type: String,
      default: '',
    },
    formButtonText: {
      type: String,
      default: 'Quiero este crédito',
    },
    resultSuccessText: {
      type: String,
    },
    resultRejectedText: {
      type: String,
    },
    showFormButton: {
      type: Boolean,
      default: true,
    },
    fontScale: {
      type: Number,
      default: 100,
    },
    spacingScale: {
      type: Number,
      default: 100,
    },
    initialAmount: {
      type: Number,
      default() {
        return this.$i18n.locale === 'es-CL' ? CL_INITIAL_AMOUNT : PE_INITIAL_AMOUNT;
      },
    },
    userMinAmount: {
      type: Number,
      default: 0,
    },
    userMaxAmount: {
      type: Number,
      default: Infinity,
    },
    initialDownPayment: {
      type: Number,
      default: 20,
    },
    initialTerm: {
      type: Number,
      default: 24,
    },
    vehicleCondition: {
      type: String,
      default: 'show',
    },
    vehicleBrand: {
      type: String,
      default: '',
    },
    showCost: {
      type: Boolean,
      default: true,
    },
    initialCurrencyType: {
      type: String,
      default: 'CLP',
    },
    exchangeRate: {
      type: Number,
      default: null,
    },
    allowCurrencyChange: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: false,
      amount: this.initialAmount,
      downPaymentPercentage: this.initialDownPayment,
      downPaymentTypes: { PERCENTAGE: 0, AMOUNT: 1 },
      term: this.initialTerm,
      intelligent: false,
      administrationCosts: [],
      insuranceRates: this.$options.filters.camelizeKeys(gon.insuranceRates),
      brands: this.$options.filters.camelizeKeys(gon.brands),
      defaultInsuranceRate: 0,
      defaultAdministrationCost: 0,
      defaultProduct: {
        configurations: [],
        downPaymentMin: 0,
        downPaymentMax: 1,
      },
      defaultConfig: {
        maxRate: 0.0233,
        vfmgRatio: 0.4,
      },
      tax: 0.008,
      showCaeBubble: false,
      simulatorData: {},
      AMOUNT_INTERVAL: this.$i18n.locale === 'es-CL' ? CL_AMOUNT_INTERVAL : PE_AMOUNT_INTERVAL,
      currencyType: this.initialCurrencyType,
    };
  },

  async mounted() {
    let brands = await strapiService.get_brands();
    this.scaleStyleVariable('--font-size', this.fontScale / 100);
    this.scaleStyleVariable('--spacing', this.spacingScale / 100);
    if(this.from === 'rutamotor'){
      this.brands = this.filterStrapiBrands(brands)
    }else if(this.from === 'bravoauto') {
      this.brands = this.allBrandsUngrouped(brands)
    }
  },

  methods: {
    acceptSimulation() {
      if (this.useAmicarForm) {
        this.toggleForm();
      } else {
        this.postToCustomUrl();
      }
    },
    async postToCustomUrl() {
      const params = [
        ['type', this.intelligent ? 'i' : 'c'],
        ['amount', this.amount],
        ['term', this.term],
        ['downPayment', this.downPayment],
        ['downPaymentPercentage', this.downPaymentPercentage],
        ['monthlyPayment', this.monthlyPayment],
        ['cae', this.equivalentAnnualCharge],
        ['totalCost', this.totalCost],
        ['currencyType', this.currencyType],
      ]
      const queryParams = params.map((param) => {
        param[1] = encodeURIComponent(param[1]);
        return param.join('=');
      }).join('&');
      const jsonParams = Object.fromEntries(params);
      console.log('useModalForm?',this.useModalForm);
      if(this.useModalForm){
        console.log(jsonParams);
        window.parent.postMessage(jsonParams, this.originUrl);
      }else{
        window.location.replace(`${this.redirectionUrl}?${queryParams}`);
      }
    },
    toggleForm() {
      this.gatherSimulatorData();
      this.form = !this.form;
      this.postSizeChangeMessage(this.form);
    },
    postSizeChangeMessage(status = false, jump = true) {
      parent.postMessage({
        type: 'toggle-simulator',
        status,
        jump,
      }, window.location.origin);
    },
    gatherSimulatorData() {
      const creditType = this.intelligent ? 'CI' : 'CC';
      this.simulatorData = {
        vehiclePrice: this.amount,
        downPayment: this.downPayment,
        downPaymentPercentage: this.downPaymentPercentage,
        monthlyPayment: this.monthlyPayment,
        term: this.term,
        creditType,
        smartChoice: this.intelligent,
        scenario: `${creditType}|${this.downPaymentPercentage}|${this.term}`,
      };
    },
    toggleCaeBubble(display) {
      this.showCaeBubble = display;
    },
    validateDownPaymentRanges(ranges) {
      Object.entries(ranges).forEach(([name, range]) => {
        if (!range.min || +range.min < 0 || +range.min > 100) range.min = 0;
        if (!range.max || +range.max < 0 || +range.max > 100) range.max = 100;
        if (+range.min > +range.max) range.min = 0;
      });

      return ranges;
    },
    irr(...cashFlows) {
      try {
        return irr(...cashFlows);
      } catch (err) {
        return Infinity;
      }
    },
    applyExchangeRatesToAdministrationCosts(costs) {
      return costs.map((cost) => (
        {
          ...cost,
          minAmount: cost.minAmount / this.exchangeRate,
          maxAmount: cost.maxAmount / this.exchangeRate,
          value: cost.value / this.exchangeRate,
        }
      ));
    },
    toOtherCurrency(value) {
      if (this.currencyType === 'CLP') return null;

      return this.currencyType === 'USD' ? value * this.exchangeRate : value / this.exchangeRate;
    },
    allBrandsUngrouped(brands) {
      let brandCount = 1
      return brands.strapi
        .filter(brand => (brand.name !== "Otros"))
        .map(brand => {
          return {
            id: brandCount++,
            name: brand.name,
            distributor: brand.distributor,
            createdAt: brand.createdAt,
            updatedAt: brand.updatedAt,
            show: true,
            website: "",
            indexaId: brand.indexa_code,
            country: "es-CL"
          };
        })
        .sort((brand1, brand2) => brand1.name.localeCompare(brand2.name))
    },
    filterStrapiBrands(brands){
      let brandCount = 1;
      let strapiBrands = {
        gildemeister: [],
        derco: [],
        otros: []
      };
      strapiBrands.derco = brands.strapi
        .filter(brand => brand.distributor === "Derco")
        .map(brand => {
          return {
            id: brandCount++,
            name: brand.name,
            distributor: "derco",
            createdAt: brand.createdAt,
            updatedAt: brand.updatedAt,
            show: true,
            website: "",
            indexaId: brand.indexa_code,
            country: "es-CL"
          };
        });
      strapiBrands.gildemeister = brands.strapi
        .filter(brand => brand.distributor === "AG")
        .map(brand => {
          return {
            id: brandCount++,
            name: brand.name,
            distributor: "gildemeister",
            createdAt: brand.createdAt,
            updatedAt: brand.updatedAt,
            show: true,
            website: "",
            indexaId: brand.indexa_code,
            country: "es-CL"
          };
        });

      strapiBrands.otros = brands.strapi
        .filter(
          brand => brand.distributor !== "Derco" && brand.distributor !== "AG"
        )
        .map(brand => {
          return {
            id: brandCount++,
            name: brand.name,
            distributor: "otros",
            createdAt: brand.createdAt,
            updatedAt: brand.updatedAt,
            show: true,
            website: "",
            indexaId: brand.indexa_code,
            country: "es-CL"
          };
        });
      return strapiBrands;
    }
  },

  computed: {
    product() {
      const product = this.intelligent ? this.products.intelligent : this.products.conventional;

      return product || this.defaultProduct;
    },

    amountValidConfigs() {
      return this.product.configurations
        .filter(c => c.minAmount <= this.toFinance && c.maxAmount >= this.toFinance);
    },

    configuration() {
      const config = this.amountValidConfigs
        .find(c => c.minTerm <= this.term && c.maxTerm >= this.term);

      return config || this.defaultConfig;
    },

    downPaymentRange() {
      const ranges = this.validateDownPaymentRanges(this.downPaymentRanges);
      const conventionalRange = {
        min: +ranges.conventional.min,
        max: +ranges.conventional.max,
      };
      const intelligentRange = {
        min: +ranges.intelligent.min,
        max: +ranges.intelligent.max,
      };

      return this.intelligent ? intelligentRange : conventionalRange;
    },

    clientRate() {
      return this.configuration.maxRate;
    },

    duration() {
      return (this.intelligent ? this.term + 1 : this.term);
    },

    termOptions() {
      const options = this.intelligent ? INTELLIGENT_TERM_OPTIONS : this.defaultConventionalTermOptions;
      const ranges = this.amountValidConfigs
        .map(config => ({ min: config.minTerm, max: config.maxTerm }));

      return options.filter(opt => ranges.some(range => opt >= range.min && opt <= range.max));
    },

    downPayment: {
      get() {
        return Math.round(this.amount * this.downPaymentPercentage / 100);
      },
      set(value) {
        this.downPaymentPercentage = value / this.amount * 100;
      },
    },

    administrationCost() {
      const difference = this.amount - this.downPayment;
      const cost = this.administrationCosts
        .filter(c => c.minAmount <= difference && c.maxAmount >= difference)
        .find(c => c.minTerm <= this.duration && c.maxTerm >= this.duration);

      return (cost && cost.value) || this.defaultAdministrationCost;
    },

    insuranceRate() {
      const rate = this.insuranceRates
        .find(i => i.term === this.duration);

      return (rate && rate.value) || this.defaultInsuranceRate;
    },

    toFinance() {
      const difference = this.amount - this.downPayment;

      return (difference + this.administrationCost) / (1 - this.insuranceRate - this.tax);
    },

    crf() {
      return ((1 + this.clientRate) ** this.term * this.clientRate) / ((1 + this.clientRate) ** this.term - 1);
    },

    conventionalMonthlyPayment() {
      const presentValue = this.toFinance;
      const monthlyPayment = presentValue * this.crf;

      return isFinite(monthlyPayment) && monthlyPayment > 0 ? Math.round(monthlyPayment) : 0;
    },

    vfmg() {
      const vfmgRatio = this.configuration.vfmgRatio;

      return this.intelligent ? (vfmgRatio * this.amount) : 0;
    },

    intelligentMonthlyPayment() {
      const presentValue = this.toFinance - this.vfmg / ((1 + this.clientRate) ** (this.term + 1));
      const monthlyPayment = presentValue * this.crf;

      return isFinite(monthlyPayment) && monthlyPayment > 0 ? Math.round(monthlyPayment) : 0;
    },

    monthlyPayment() {
      return this.intelligent ? this.intelligentMonthlyPayment : this.conventionalMonthlyPayment;
    },

    equivalentAnnualCharge() {
      const flows = Array(this.term).fill(this.monthlyPayment);
      if (this.intelligent) flows.push(this.vfmg);
      const initialInvestment = this.amount - this.downPayment;
      const eac = Math.abs(this.irr(-initialInvestment, ...flows)) * 12;

      return isFinite(eac) ? Math.round(eac * 10) / 10 : '-';
    },

    totalCost() {
      return this.monthlyPayment * this.term + this.vfmg;
    },

    minAmount() {
      const min = Math.min(...this.product.configurations.map(config => config.minAmount));

      return Math.max(Math.ceil(min / this.AMOUNT_INTERVAL) * this.AMOUNT_INTERVAL, this.userMinAmount);
    },

    maxAmount() {
      let max = Math.max(...this.product.configurations.map(config => config.maxAmount));
      max = max * (1 - this.insuranceRate - this.tax) - this.administrationCost;

      return Math.min(Math.floor(max / this.AMOUNT_INTERVAL) * this.AMOUNT_INTERVAL, this.userMaxAmount);
    },

    sliderOptions() {
      return {
        width: '100%',
        height: 8,
        dotSize: 20,
        tooltip: false,
        sliderStyle: {
          border: `2px solid ${this.$colorValueFromKey('highlights')}`,
          boxShadow: 'none',
        },
        processStyle: {
          backgroundColor: this.$colorValueFromKey('highlights'),
        },
        formatter: '{value}%',
        realTime: true,
      };
    },

    amountSliderOptions() {
      return {
        ...this.sliderOptions,
        min: 0,
        max: this.maxAmount,
        interval: this.AMOUNT_INTERVAL,
      };
    },

    downPaymentSliderOptions() {
      return {
        ...this.sliderOptions,
        ...this.downPaymentRange,
      };
    },

    countryForm() {
      switch (this.$i18n.locale) {
      case 'es-PE':
        return SimulatorFormPe;
      default:
        return SimulatorFormCl;
      }
    },

    simulatorDataInPen() {
      const data = this.simulatorData;

      if (this.currencyType === 'PEN') return data;
      if (this.currencyType !== 'USD') return {};

      return {
        ...data,
        vehiclePrice: data.vehiclePrice * this.exchangeRate,
        downPayment: data.downPayment * this.exchangeRate,
        monthlyPayment: data.monthlyPayment * this.exchangeRate,
      };
    },

    productsByCurrency() {
      return {
        [this.baseCurrency]: {
          conventional: this.$options.filters.camelizeKeys(gon.conventionalProduct),
          intelligent: this.$options.filters.camelizeKeys(gon.intelligentProduct),
        },
        'USD': {
          conventional: this.$options.filters.camelizeKeys(gon.usdConventionalProduct),
          intelligent: this.$options.filters.camelizeKeys(gon.usdIntelligentProduct),
        },
      };
    },

    products() {
      return this.productsByCurrency[this.currencyType];
    },

    validConfigurationsOrNotPeru() {
      return this.$i18n.locale !== 'es-PE' || this.amountValidConfigs.length > 0;
    },

    defaultConventionalTermOptions() {
      if (this.$i18n.locale === 'es-PE') {
        return CONVENTIONAL_TERM_OPTIONS.concat(PERU_EXTRA_TERMS);
      }

      return CONVENTIONAL_TERM_OPTIONS;
    },
  },
  watch: {
    intelligent() {
      this.$nextTick(() => {
        if (this.intelligent && this.termOptions.indexOf(this.term) === -1) {
          this.term = this.termOptions[0];
        }
      });
      this.postSizeChangeMessage(false, false);
    },
    amount: debounce(function (event) {
      if (this.amount < this.minAmount) {
        this.amount = this.minAmount;
      }
    }, DEBOUNCE_RATE),
    currencyType: {
      immediate: true,
      handler(newValue) {
        const administrationCosts = this.$options.filters.camelizeKeys(gon.administrationCosts);
        if (newValue === 'USD' && this.exchangeRate) {
          this.administrationCosts = this.applyExchangeRatesToAdministrationCosts(administrationCosts);
        } else {
          this.administrationCosts = administrationCosts;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../styles/app/variables';

  .simulator {
    --font-size: 20px;
    --spacing: 25px;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    font-size: var(--font-size);
    color: $text-color;
    overflow: hidden;

    &__row {
      margin-bottom: var(--spacing);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--result {
        color: $primary-color;
        font-weight: 500;
      }

      &--info {
        margin-top: -15px;
        font-size: 0.7em;
        color: $light-color;
      }

      &--summary {
        font-size: 0.8em;
        margin: 0 0 20px 0;
        color: $light-color;
      }

      &--button {
        margin-top: 1em;
        text-align: center;
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--error {
        margin-top: -20px;
        font-size: .6em;
        color: $error-color;
      }
    }

    &__slider-container {
      margin: 0 -10px;
      width: calc(100% + 20px);
    }

    &__input-container {
      position: relative;
    }

    &__input-suffix {
      position: absolute;
      right: 0;
      top: 1px;
      pointer-events: none;
    }

    &__input {
      background: transparent;
      width: 6.25em;
      text-align: right;
      font-size: 1em;
      border: 0px;
      border-bottom: 0.1em solid $primary-color;
      border-radius: 0;
      margin: 0;
      padding: 0;

      &--with-suffix {
        padding-right: 0.8em;
      }

      &--down-payment {
        width: 2.5em;
      }
    }

    &__select {
      position: relative;

      select {
        padding-right: 0.9em;
        font-size: 1em;
        background: none;
        border: 0;
        border-bottom: 0.1em solid $primary-color;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        cursor: pointer;
      }
    }

    &__select-arrows {
      position: absolute;
      right: -6px;
      font-size: 1.2em;
      pointer-events: none;
      color: $primary-color;

      &::before {
        content: 'arrow_drop_up';
        font-family: 'Material Icons';
        position: absolute;
        top: -0.167em;
        right: 0;
      }

      &::after {
        content: 'arrow_drop_down';
        font-family: 'Material Icons';
        position: absolute;
        top: 0.125em;
        right: 0;
      }
    }

    &__checkbox {
      appearance: none;
      border: 0.1em solid $primary-color;
      border-radius: 0.15em;
      margin: 0;
      font-size: 1em;
      width: 1em;
      height: 1em;
      text-align: center;
      outline: none;

      &:checked {
        background-color: $primary-color;
        border: 0px none transparent;
      }

      &:checked:after {
        content: '\2713';
        font-size: 1em;
        line-height: 1;
        vertical-align: top;
        color: $contrast-color;
      }
    }

    &__separator {
      width: 100%;
      border: 0.05em solid $very-light-color;
      background-color: $very-light-color;
      margin-bottom: 24px;
    }

    &__button {
      background-color: $primary-color;
      border: 0;
      border-radius: 2px;
      color: white;
      font-size: .8em;
      height: 2.5em;
      opacity: .9;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      &:hover {
        opacity: .5;
      }

      &:active {
        opacity: 1;
      }
    }

    &__cae {
      position: relative;
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
    }

    &__total {
      margin-right: 20px;
    }

    &__cae-icon {
      cursor: pointer;
      margin-left: 5px;
      font-size: 1.25em;
    }

    &__cae-bubble {
      position: absolute;
      right: 0;
      bottom: 32px;
      width: calc(100vw - 40px);
      white-space: normal;
      font-size: 0.8125em;
      line-height: 1.307em;
      background-color: #eee;
      padding: 14px;
      border-radius: 4px;
      pointer-events: none;
      opacity: 0;
      transition: opacity .2s ease;

      &--visible {
        opacity: 1;
      }
    }

    &__cae-bubble-arrow {
      position: absolute;
      font-size: 1.65em;
      bottom: -19px;
      right: -5px;
    }
  }
</style>
