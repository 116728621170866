<template>
  <div
    class="credit-info"
    v-if="currentStep"
  >
    <component
      :is="currentStep.component"
      v-bind="currentStep.props"
      @next="mountNextStep()"
      @back="mountPreviousStep()"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import CreditInfoSimple from './credit-info-simple';
import CreditInfoChoice from './credit-info-choice';

const STEP_TO_COMPONENT = {
  simple: Vue.extend(CreditInfoSimple),
  choice: Vue.extend(CreditInfoChoice),
};

export default {
  data() {
    return {
      nextStepIndex: 0,
      steps: {
        0: { type: 'simple', props: {
          title: 'Ingresa tu rut',
          subtitle: 'Este dato es necesario para validar tu información',
          inputPlaceholder: 'Tu rut',
          showBackButton: false,
        } },
        1: { type: 'choice', props: {} },
        2: { type: 'simple', props: {
          title: 'Ingresa tu correo',
          subtitle: 'Ingresa el correo electrónico donde te gustaría recibir la información',
          inputPlaceholder: 'Tu correo',
          buttonPlaceholder: 'Enviar',
        } },
      },
      currentStep: null,
    };
  },
  mounted() {
    this.mountNextStep();
  },
  methods: {
    mountNextStep() {
      if (this.nextStepIndex in this.steps) {
        const nextStep = this.steps[this.nextStepIndex];
        this.currentStep = { component: STEP_TO_COMPONENT[nextStep.type], props: nextStep.props };
        this.nextStepIndex += 1;
      }
    },
    mountPreviousStep() {
      this.nextStepIndex -= 2;
      const nextStep = this.steps[this.nextStepIndex];
      this.currentStep = { component: STEP_TO_COMPONENT[nextStep.type], props: nextStep.props };
      this.nextStepIndex += 1;
    },
  },
};
</script>
