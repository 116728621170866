<template>
  <div class="feedback">
    <div class="feedback__check"></div>
    <div class="feedback__subtitle">
      ¡Solicitud enviada con éxito!
    </div>
    <div class="feedback__message">
      En un plazo no superior a 2 días hábiles <br/>
      te enviaremos un correo electrónico <br/>
      para informarte sobre el <br/>
      resultado de tu solicitud de crédito. 
    </div>
    <div class="feedback__disclaimer">
      En caso de ser aprobado(a) deberás validar la información <br> 
      entregada con documentación que la sustente.
    </div>
    <button
      class="feedback__button"
      style="cursor: pointer;"
      @click="home()"
    >
      Ir al inicio
    </button>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    home() {
      window.location.href = `https://${process.env.APPLICATION_HOST}`;
    },
  },
};
</script>

<style lang="scss" scoped>

.feedback {
  @import '../styles/app/variables';

  background-color: $feedback-background;
  color: $feedback_text;
  padding: 42px;
  text-align: center;

  &__greeting {
    font-size: 41px;
    padding: 5px 0;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 22px;
    padding: 5px 0;
    color: #23C273; 
    font-weight: bold;
  }

  &__check {
    height: 100px;
    width: 100px;
    background: url("../assets/check-amicar.png") no-repeat center;
    background-size: cover;
    margin: auto;
    margin-bottom: 20px;
  }

  &__message {
    font-size: 18px;
    font-weight: bolder;
    color: #6F6F6F;
    letter-spacing: .6px;
    padding: 10px 0;
  }
  
  &__disclaimer {
    font-size: 12px;
    font-weight: lighter;
    line-height: 13px;
    color: #6F6F6F;
    letter-spacing: .6px;
    padding: 10px 0;
  }
  
  &__field {
    border-top: solid 1px #000;
    font-size: 18px;
    padding: 5px;

    &--bottom {
      border-bottom: solid 1px #000;
    }
  }

  &__button {
    background-color: #fff;
    color: #F66536!important;
    margin-top: 30px;
    padding: 8px 16px;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.18);
    border: 0;
    border-radius: 20px;
    font-weight: 500;
    letter-spacing: 1.2px;
  }
}
</style>

