<template>
  <ValidationProvider
    tag="div"
    :class="[defaultClass, {'form-input--compact': compact}]"
    :rules="rules || rulesObj"
    :name="name"
    v-slot="{ errors }"
  >
    <label
      class="form-input__label"
    >
      {{ label }}
    </label>
    <slot />
    <span
      class="form-input__error"
      v-if="errors.length > 0"
    >
      {{ errors[0] }}
    </span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: '',
    },
    rulesObj: {
      type: Object,
      default() {
        return {};
      },
    },
    compact: {
      type: Boolean,
      default: false,
    },
    defaultClass: {
      type: String,
      default: 'form-input',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/app/variables';

.form-input {
  $root: &;

  &__label {
    font-size: .7em;
  }

  &__error {
    color: $error-color;
    font-size: .54em;
    bottom: -15px;
    padding-right: 1em;
    padding-top: .2em;
    width: 100%;
    display: block;
    text-align: right;

    &--left {
      left: 3.7em;
    }
  }

  &--compact {
    #{$root}__error {
      bottom: unset;
      right: 20px;
    }
  }
}
</style>
