<template>
  <div class="text-input">
    <input
      v-if="isRut"
      v-rut
      class="text-input__input"
      :placeholder="placeholder"
      @input="onInput"
      :value="value"
      :readonly="readonly"
    >
    <input
      v-else
      class="text-input__input"
      :placeholder="placeholder"
      @input="onInput"
      :value="value"
      :readonly="readonly"
    >
    <slot name="button" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    isRut: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    }
  },
};
</script>

<style lang="scss" scoped>

.text-input {
  width: 100%;
  position: relative;
  height: 40px;
  margin-top: calc(.4 * var(--spacing)) !important;
  
  &__input {
    font-size: .7em;
    padding-left: .6em;
    width: 100%;
    height: 40px;
    border-radius: 19.5px;
    padding: 14px;
    outline: 0;
    border: 1px solid #aaa;
  }

  &::v-deep .text-input__button {
    position: absolute;
    border: 0;
    padding: 0;
    height: 30px;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    color: #ff520f;
    z-index: 1;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }
}
</style>
