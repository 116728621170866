const messages = {
  'es-CL': {
    smartChoice: 'Opción Inteligente',
    simulator: {
      downPayment: 'Pie',
      continue: 'Continuar',
      info: 'Los valores y conceptos indicados en esta simulación son referenciales, no vinculantes,\n' +
        'y no conllevan la aprobación y otorgamiento del crédito, el cual es gestionado por Amicar\n' +
        'y otorgado por una tercera entidad financiera. Valor cuota incluye gastos operacionales e\n' +
        'impuestos al crédito. Las cuales se encuentran calculadas con vencimiento en 30 días.',
    },
    distributorsMap: {
      commune: 'Comuna',
      communes: 'Comunas',
      region: 'Región',
      regions: 'Regiones',
    },
  },
  'es-PE': {
    smartChoice: 'Compra Inteligente',
    simulator: {
      downPayment: 'Cuota Inicial',
      continue: 'Quiero este crédito',
      info: 'Los valores y conceptos indicados en está simulación son referenciales\n' +
        'y no conllevan la aprobación ni otorgamiento del crédito, el cual es gestionado por Amicar\n' +
        'y otorgado por una Entidad Financiera. El valor de la cuota es referencial,\n' +
        'calculada para clientes de 4ta y 5ta categoría y para vehículos de uso particular.\n' +
        'Para mayor información de TEA y TCEA visita nuestros Términos y Condiciones.',
    },
    distributorsMap: {
      commune: 'Sede',
      communes: 'Sedes',
      region: 'Departamento',
      regions: 'Departamentos',
    },
  },
}
;

export default messages;
