<template>
  <div class="feedback" :style="[coloredBackground('feedback_background'), coloredText('feedback_text')]">
    <div v-if="approved || conditioned">
      <div class="feedback__icon feedback__icon--success"></div>
      <div class="feedback__greeting">
        Hola {{ form.name }},
      </div>
      <div class="feedback__message">
        {{ resultSuccessText }}
      </div>
      <div class="feedback__info">
        <div
          class="feedback__info__field feedback__info__field--model"
          v-if="form.model"
        />
        <div class="feedback__info__field">
          {{ form.name }}
        </div>
        <div class="feedback__info__field">
          {{ $i18n.locale === 'es-PE' ? form.identifier : form.rut }}
        </div>
        <div class="feedback__info__field">
          {{ form.phone }}
        </div>
        <div class="feedback__info__field feedback__info__field--bottom">
          {{ form.email }}
        </div>
      </div>
    </div>
    <div v-if="!approved && !conditioned">
      <div class="feedback__icon feedback__icon--failure"></div>
      <div class="feedback__greeting">
        Hola {{ form.name }},
      </div>
      <div v-if="errors && !conditioned">
        <div class="feedback__message">
          Hubo un error con los datos ingresados, intenta nuevamente.
        </div>
        <div class="feedback__info">
          <div class="feedback__info__field feedback__info__field--bottom">
            {{ errors }}
          </div>
        </div>
      </div>
      <div v-if="!errors">
        <div class="feedback__message">
          {{ resultRejectedText }}
        </div>
      </div>
    </div>
    <button :style="[coloredBackground('feedback_back'), coloredText('feedback_text')]"
      class="feedback__button" v-on:click="$emit('back')">
      VOLVER
    </button>
  </div>
</template>

<script>
import ColorMixin from '../mixins/color';
import SizeMixin from '../mixins/size';

export default {
  mixins: [ColorMixin, SizeMixin],
  props: {
    approved: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => { },
    },
    errors: {
      type: String,
      default: '',
    },
    resultSuccessText: {
      type: String,
    },
    resultRejectedText: {
      type: String,
    },
    conditioned: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  padding: 3em;
  text-align: center;
  font-size: var(--font-size);

  &__icon {
    width: 45px;
    height: 45px;
    margin-left: 50%;
    transform: translateX(-50%);

    &--success {
      background: url("../assets/check.svg") no-repeat center;
      background-size: cover;
    }

    &--failure {
      background: url("../assets/cross.svg") no-repeat center;
      background-size: cover;
    }
  }

  &__greeting {
    font-size: 1.3em;
    padding: .2em 0;
  }

  &__message {
    font-size: .75em;
    font-weight: lighter;
    letter-spacing: .6px;
  }

  &__info {
    margin-top: calc(1.1 * var(--spacing));

    &__field {
      border-top: solid 1px black;
      font-size: calc(0.4em + 8px);
      padding: calc(.2 * var(--spacing));

      &--bottom {
        border-bottom: solid 1px black;
      }
    }
  }

  &__button {
    margin-top: 4em;
    padding: 8px 16px;
    border: 0;
    border-radius: 3px;
    font-weight: 500;
    letter-spacing: 1.2px;
  }
}
</style>

