<template>
  <ValidationObserver
    ref="form"
    tag="div"
  >
    <div
      v-show="!feedback"
      class="form"
      :style="[coloredBackground('background'), coloredText('text')]"
    >
      <div class="form__row form__row--title">
        <div class="form__title">
          Ingresa tus datos
        </div>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="identifierType"
          label="Tipo de Documento:"
          :compact="true"
        >
          <select-input
            placeholder="Seleccione una opción"
            :options="identifierTypes"
            v-model="selectedIdentifierType"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="identifier"
          label="N° Identidad"
          :compact="true"
        >
          <text-input v-model="form.identifier" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|alpha_spaces"
          label="Nombre"
          name="nombre"
          :compact="true"
        >
          <text-input v-model="form.name" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|alpha_spaces"
          label="Apellido Paterno:"
          name="Apellido Paterno"
          :compact="true"
        >
          <text-input v-model="form.fatherSurname" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|alpha_spaces"
          label="Apellido Materno:"
          name="Apellido Materno"
          :compact="true"
        >
          <text-input v-model="form.motherSurname" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          :rules="`required|min_income_pe_mask:${minIncome}`"
          label="Ingreso en Soles:"
          name="income"
          :compact="true"
        >
          <text-input
            placeholder="Ingreso en Soles"
            v-model.lazy="incomeMask"
            v-money="incomeMask !== null ? vMoneyConfig : null"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|email"
          label="Correo Electrónico:"
          name="Correo Electrónico"
          :compact="true"
        >
          <text-input v-model="form.email" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|phone"
          label="Teléfono:"
          name="phone"
          :compact="true"
        >
          <text-input v-model="form.phone" />
        </form-input>
      </div>
      <div
        class="form__row form__row--input"
        v-if="showVehicleCondition === 'show'"
      >
        <form-input
          rules="required"
          label="Estado del auto:"
          name="Estado del auto"
          :compact="true"
        >
          <select-input
            :options="vehicleConditions"
            v-model="selectedVehicleCondition"
          />
        </form-input>
      </div>
      <div
        class="form__row form__row--input"
        v-if="!initialVehicleBrand"
      >
        <form-input
          rules="required"
          label="Marca del auto:"
          name="Marca del auto"
          :compact="true"
        >
          <select-input
            :optgroups="computedBrands"
            v-model="selectedBrand"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input label="Antiguedad Laboral:" />
      </div>
      <seniority-inputs
        :work-start="form.workStart"
        :work-end="form.workEnd"
        @start-change="val => form.workStart = val"
        @end-change="val => form.workEnd = val"
        @seniority-change="val => form.seniority = val"
        popover-placement="bottom-end"
      />
      <div class="form__row form__row--value">
        <div class="form__input">
          Cuota Inicial:
        </div>
        <span>
          {{ $n(simulatorData.downPayment, i18nCurrencyKey) }}
        </span>
      </div>
      <div class="form__row form__row--terms">
        <input
          class="amicar-checkbox amicar-checkbox--gray form__terms-checkbox"
          v-model="agreedTerms"
          type="checkbox"
        >
        <span class="form__terms">
          Estoy de acuerdo con los
          <a
            class="form__link"
            href="/terminos-y-condiciones"
            target="_blank"
          >Términos y Condiciones</a>
        </span>
        <span
          class="form__error form__error--left"
          v-if="!validTerms"
        >
          Debes aceptar los términos y condiciones
        </span>
      </div>
      <div class="form__row form__row--button">
        <button
          class="form__button form__button--back"
          @click="$emit('toggle-form')"
          :style="coloredBackground('disclaimer')"
        >
          VOLVER
        </button>
        <button
          id="simulator-form-submit"
          type="button"
          @click="checkClientApproval()"
          class="form__button form__button--send"
          :style="coloredBackground('result')"
        >
          ENVIAR
        </button>
      </div>
      <div
        class="spinner__container"
        v-show="loading"
      >
        <clip-loader
          class="spinner"
          :loading="loading"
          :size="'45px'"
          :color="$colorValueFromKey('result')"
        />
      </div>
    </div>
    <feedback
      v-if="feedback"
      @back="toggleFeedback()"
      :form="form"
      :approved="approved"
      :errors="errors"
      v-bind="$attrs"
      :result-success-text="resultSuccessText"
      :result-rejected-text="resultRejectedText"
    />
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import './form/validation-rules';
import FormInput from '../components/form/form-input';
import TextInput from '../components/form/text-input';
import SelectInput from '../components/form/select-input';
import ColorMixin from '../mixins/color';
import SizeMixin from '../mixins/size';
import MulticurrencyMixin from '../mixins/multicurrency';
import FormService from '../services/form.js';
import Feedback from './feedback.vue';
import SeniorityInputs from './es-PE/seniority-inputs';
import { IDENTIFIER_TYPES, MIN_INCOME } from '../constants/es-PE';
import { VEHICLE_CONDITION_OPTIONS } from '../constants/shared';

export default {
  mixins: [ColorMixin, SizeMixin, MulticurrencyMixin],
  components: {
    ValidationObserver,
    Feedback,
    FormInput,
    TextInput,
    SelectInput,
    SeniorityInputs,
  },
  props: {
    simulatorData: {
      type: Object,
      default: () => {},
    },
    from: {
      type: String,
      default: 'amicar-pe',
    },
    originUrl: {
      type: String,
      default: '',
    },
    showVehicleCondition: {
      type: String,
      default: 'show',
    },
    brands: {
      type: Object,
      default: () => {},
    },
    initialVehicleBrand: {
      type: String,
      default: '',
    },
    resultSuccessText: {
      type: String,
      default: null,
    },
    resultRejectedText: {
      type: String,
      default: null,
    },
    currencyType: {
      type: String,
      default: 'CLP',
    },
    simulatorDataInPen: {
      type: Object,
      default: () => {},
    },
    exchangeRate: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        from: this.from,
        identifier: '',
        name: '',
        fatherSurname: '',
        motherSurname: '',
        email: '',
        phone: '',
        income: null,
        identifierType: '',
        vehicleCondition: null,
        vehicleBrand: '',
        vehicleBrandId: '',
        vehicleDistributor: '',
        originUrl: this.originUrl,
        workStart: null,
        workEnd: null,
        seniority: null,
      },
      loading: false,
      feedback: false,
      approved: false,
      errors: '',
      vehicleConditions: VEHICLE_CONDITION_OPTIONS,
      minIncome: MIN_INCOME,
      selectedBrand: null,
      selectedVehicleCondition: null,
      identifierTypes: IDENTIFIER_TYPES,
      selectedIdentifierType: null,
      incomeMask: null,
      agreedTerms: false,
      validTerms: true,
    };
  },
  mounted() {
    this.selectedVehicleCondition = this.showVehicleCondition === 'show' ?
      '' : this.vehicleConditions.find(condition => condition.id === this.showVehicleCondition);

    this.form.vehicleBrand = this.initialVehicleBrandObject.name;
    this.form.vehicleBrandId = this.initialVehicleBrandObject.indexaId;
  },
  methods: {
    postSizeChangeMessage(status = false) {
      parent.postMessage({
        type: 'toggle-simulator',
        status,
      }, window.location.origin);
    },
    toggleFeedback() {
      this.feedback = !this.feedback;
      this.postSizeChangeMessage(true);
    },
    async checkClientApproval() {
      if (!this.agreedTerms) {
        this.validTerms = false;

        return;
      }
      this.$refs.form.validate().then(async (success) => {
        if (!success) return;

        const body = Object.assign({}, this.form, this.simulatorDataInPen, { income: this.incomeInPen });
        this.loading = true;
        const res = await new FormService().checkClientApproval(body);
        this.loading = false;
        if (res.evaluationResult === 'APPROVED') {
          this.approved = true;
        } else {
          this.approved = false;
          this.errors = res.mensajeError;
        }
        this.toggleFeedback();
      });
    },
  },
  computed: {
    computedBrands() {
      const dercoBrands = { derco: this.brands.derco }
      const brands = Object.keys(dercoBrands).map(key => ({
        key,
        options: this.brands[key],
      }));

      return brands;
    },
    initialVehicleBrandObject() {
      if (!this.initialVehicleBrand) return null;
      const flatBrands = this.computedBrands.reduce((accumulator, brand) => accumulator.concat(brand.options), []);

      return flatBrands.find((brand) => brand.indexaId === Number(this.initialVehicleBrand));
    },
    incomeInPen() {
      return this.currencyType === 'USD' && this.exchangeRate ? this.form.income * this.exchangeRate : this.form.income;
    },
  },
  watch: {
    selectedVehicleCondition() {
      this.form.vehicleCondition = this.selectedVehicleCondition && this.selectedVehicleCondition.id;
    },
    selectedBrand() {
      this.form.vehicleBrand = this.selectedBrand.name;
      this.form.vehicleBrandId = this.selectedBrand.indexaId;
      this.form.vehicleDistributor = this.selectedBrand.distributor;
    },
    selectedIdentifierType() {
      this.form.identifierType = Number(this.selectedIdentifierType.id);
    },
    incomeMask() {
      this.form.income = Number(this.incomeMask.replace(/[^0-9]/g, ''));
    },
    agreedTerms() {
      this.validTerms = this.agreedTerms;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/app/variables';
@import './form/form';

.spinner {
  left: 50%;
  position: absolute;
  top: 45%;
  transform: translateX(-50%);

  &__container {
    position: absolute;
    background-color: rgba(255, 255, 255, .71);
    height: 100%;
    top: 0;
    width: 100%;
  }
}

.form {
  &__button {
    background-color: $primary-color;
    border: 0;
    border-radius: 2px;
    color: #fff;
    flex: 1;
    font-size: .8em;
    height: 2.5em;
    opacity: .9;
    text-align: center;
    width: 100%;

    &:hover {
      opacity: .5;
    }

    &:active {
      opacity: 1;
    }

    &--send {
      background-color: $primary-color;
      margin-left: 1.5%;
    }

    &--back {
      margin-right: 1.5%;
    }
  }

  &__link {
    color: unset;
  }

  &__row {
    &--terms {
      color: $gray-color;
      display: flex;
      align-items: center;
      margin: 5px 0 20px;
    }
  }

  &__terms {
    padding-left: .6em;
    font-size: .7em;
  }

  &__terms-checkbox {
    border: .1em solid #aaa;
    margin: 0;
  }
}
</style>
