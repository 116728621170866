<template>
  <div class="section distributors">
    <div class="distributors-panel">
      <div class="section__title distributors__title">
        Encuentra tu Concesionario más cercano.
      </div>
      <div
        v-if="!commune || isBigScreen()"
        class="section__subtitle"
      >
        Selecciona tu {{ $t('distributorsMap.commune') }} y el tipo de auto que buscas.
      </div>
      <div class="distributors-panel__control">
        <div class="distributors-panel__input-wrapper">
          <div class="distributors-panel__input-title">
            Tipo de Auto
          </div>
          <select
            v-model="onlyNew"
            class="distributors-panel__input"
          >
            <option :value="null">
              Nuevos y Usados
            </option>
            <option :value="true">
              Nuevos
            </option>
            <option :value="false">
              Usados
            </option>
          </select>
          <div class="distributors__icon-dropdown" />
        </div>
        <div
          v-if="onlyNew && $i18n.locale === 'es-CL'"
          class="distributors-panel__input-wrapper"
        >
          <div class="distributors-panel__input-title">
            Marca
          </div>
          <select
            v-model="selectedBrand"
            class="distributors-panel__input"
          >
            <option :value="null">
              Todas las Marcas
            </option>
            <option
              :key="brand.id"
              v-for="brand in brands"
              :value="brand"
            >
              {{ brand.name }}
            </option>
          </select>
          <div class="distributors__icon-dropdown" />
        </div>
        <div
          v-if="$i18n.locale === 'es-PE'"
          class="distributors-panel__input-wrapper"
        >
          <div class="distributors-panel__input-title">
            {{ $t('distributorsMap.region') }}
          </div>
          <select
            v-model="region"
            class="distributors-panel__input"
          >
            <option :value="null">
              Todas
            </option>
            <option
              :key="regionOption"
              v-for="regionOption in regions"
              :value="regionOption"
            >
              {{ regionOption }}
            </option>
          </select>
          <div class="distributors__icon-dropdown" />
        </div>
        <div
          class="distributors-panel__input-wrapper distributors-panel__input-wrapper--no-margin"
        >
          <div class="distributors-panel__input-title">
            {{ $t('distributorsMap.commune') }}
          </div>
          <select
            v-model="commune"
            class="distributors-panel__input"
          >
            <option :value="null">
              Todas las {{ $t('distributorsMap.communes') }}
            </option>
            <option
              :key="index"
              v-for="(communeOption, index) in communes"
              :value="communeOption"
            >
              {{ communeOption }}
            </option>
          </select>
          <div class="distributors__icon-dropdown" />
        </div>
      </div>
      <div class="distributors-panel__buttons">
        <div
          class="distributors-panel__button"
          @click="showMap = true"
          :class="{ 'distributors-panel__button--selected': showMap }"
        >
          Ver Mapa
        </div>
        <div
          class="distributors-panel__button"
          @click="showMap = false"
          :class="{ 'distributors-panel__button--selected': !showMap }"
        >
          Ver Lista
        </div>
      </div>
      <div
        v-show="!showMap || isBigScreen()"
        class="distributors__list"
      >
        <div
          class="distributors__sub-list"
          v-if="commune"
        >
          <div
            class="distributors__separator"
            v-if="distributorsFromCommune.length"
          >
            <div class="distributors__icon-dropdown distributors__icon-dropdown--separator" />
            <div v-if="selectedBrand">
              Concesionarios en {{ commune }} que venden {{ selectedBrand.name }}
            </div>
            <div v-else-if="onlyNew === false">
              Concesionarios en {{ commune }} que venden autos usados
            </div>
            <div v-else-if="onlyNew">
              Concesionarios en {{ commune }} que venden autos nuevos
            </div>
            <div v-else>
              Concesionarios en {{ commune }}
            </div>
          </div>
          <div v-else>
            <div v-if="selectedBrand">
              No hay concesionarios en {{ commune }} que vendan {{ selectedBrand.name }}
            </div>
            <div v-else-if="onlyNew === false">
              No hay concesionarios en {{ commune }} que vendan autos usados
            </div>
            <div v-else>
              No hay concesionarios en {{ commune }} que vendan autos nuevos
            </div>
          </div>
          <DistributorList
            @distributor-hovered="hoveredDistributor = $event"
            @distributor-selected="centerMap($event)"
            :distributor-list="distributorsFromCommune"
            :show-brand-logos="onlyNew"
          />
        </div>
        <div
          class="distributors__sub-list"
          v-if="commune"
        >
          <div class="distributors__separator">
            <div class="distributors__icon-dropdown distributors__icon-dropdown--separator" />
            <div v-if="selectedBrand">
              Concesionarios cerca de {{ commune }} que venden {{ selectedBrand.name }}
            </div>
            <div v-else-if="onlyNew === false">
              Concesionarios cerca de {{ commune }} que venden autos usados
            </div>
            <div v-else-if="onlyNew">
              Concesionarios cerca de {{ commune }} que venden autos nuevos
            </div>
            <div v-else>
              Concesionarios cerca de {{ commune }}
            </div>
          </div>
          <DistributorList
            @distributor-hovered="hoveredDistributor = $event"
            @distributor-selected="centerMap($event)"
            :distributor-list="distributorsCloseToCommune"
            :show-brand-logos="onlyNew"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showMap || isBigScreen()"
      class="distributors__map"
    >
      <div
        @click="showMap = false"
        class="distributors__map-close"
      />
      <DistributorCard
        v-if="selectedDistributor"
        :distributor="selectedDistributor"
        @close-card="selectedDistributor = null"
      />
      <l-map
        :zoom="zoom"
        :center="center"
        ref="myMap"
      >
        <l-tile-layer :url="url" />
        <l-marker
          :key="distributor.id"
          v-for="distributor in filteredDistributors"
          :lat-lng="[distributor.latitude, distributor.longitude]"
          :icon="samePlace(distributor) ? locationOnIcon : locationOffIcon"
          @click="selectedDistributor = distributor"
        />
      </l-map>
    </div>
    <link
      rel="stylesheet"
      href="https://unpkg.com/leaflet@1.4.0/dist/leaflet.css"
      integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=="
      crossorigin=""
    >
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import locationOn from 'images/map_location_on.svg';
import locationOff from 'images/map_location_off.svg';
import DistributorList from './distributor-list.vue';
import DistributorCard from './distributor-card.vue';

const NUMBER_OF_NEARBY_DISTRIBUTORS = 6;

/* eslint-disable no-underscore-dangle, no-undef, no-magic-numbers, arrow-body-style */
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  components: { LMap, LTileLayer, LMarker, DistributorList, DistributorCard },
  props: {
    distributors: {
      type: Array,
      default: () => [],
    },
    brands: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      zoom: 13,
      showMap: false,
      selectedBrand: null,
      hoveredDistributor: null,
      selectedDistributor: null,
      onlyNew: null,
      commune: null,
      region: null,
      localeCenter: {
        'es-CL': L.latLng(-33.379173, -70.529546),
        'es-PE': L.latLng(-12.0651423,	-76.9709948),
      },
      center: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      locationOffIcon: L.icon({
        iconUrl: locationOff,
        iconSize: [35, 35],
        iconAnchor: [18, 32],
      }),
      locationOnIcon: L.icon({
        iconUrl: locationOn,
        iconSize: [35, 35],
        iconAnchor: [18, 32],
        zIndexOffset: 10000,
      }),
    };
  },
  mounted() {
    this.parseQueryString();
    this.$refs.myMap.mapObject.setView(this.localeCenter[this.$i18n.locale], this.zoom);
  },
  methods: {
    samePlace(distributor) {
      return (this.hoveredDistributor &&
      distributor.latitude === this.hoveredDistributor.latitude &&
      distributor.longitude === this.hoveredDistributor.longitude);
    },
    isBigScreen() {
      return window.innerWidth >= 768;
    },
    centerMap(distributor) {
      const newCenter = L.latLng(distributor.latitude, distributor.longitude);
      this.$refs.myMap.mapObject.panTo(newCenter);
    },
    getVectorialDistance(x1, x2, y1, y2) {
      return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    },
    parseQueryString() {
      if (this.$route.query.commune) {
        this.commune = this.$route.query.commune;
      }

      if (this.$route.query.region) {
        this.region = this.$route.query.region;
      }

      if (this.$route.query.onlyNew) {
        this.onlyNew = this.$route.query.onlyNew;
      }

      if (this.$route.query.selectedBrand) {
        const brandName = this.$route.query.selectedBrand;
        this.selectedBrand = this.brands.find(brand => brand.name === brandName);
      }
    },
    pushQueryString() {
      const query = {};
      if (this.commune) {
        Object.assign(query, {}, { commune: String(this.commune) });
      }
      if (this.onlyNew !== null) {
        Object.assign(query, {}, { onlyNew: String(this.onlyNew) });
      }
      if (this.selectedBrand) {
        Object.assign(query, {}, { selectedBrand: String(this.selectedBrand.name) });
      }
      this.$router.push({ query });
    },
  },
  watch: {
    region() {
      if (!this.communes.includes(this.commune)) {
        this.commune = this.communes[0];
      }
    },
    commune() {
      if (this.commune) {
        const communeDistributors = this.distributors
          .filter(distributor => distributor.commune === this.commune);
        const lats = communeDistributors.reduce((acc, d) => acc + d.latitude, 0);
        const longs = communeDistributors.reduce((acc, d) => acc + d.longitude, 0);
        const count = communeDistributors.length;
        this.center = L.latLng(lats / count, longs / count);
        this.$refs.myMap.mapObject.panTo(this.center);
      }
      this.pushQueryString();
    },
    onlyNew() {
      if (!this.onlyNew) {
        this.selectedBrand = null;
      }
      this.pushQueryString();
    },
    selectedBrand() {
      this.pushQueryString();
    },
    selectedDistributor() {
      this.hoveredDistributor = this.selectedDistributor;
    },
  },
  computed: {
    filteredDistributors() {
      if (this.onlyNew === null) {
        return this.distributors;
      } else if (this.onlyNew === false) {
        return this.distributors.filter(distributor => distributor.hasUsed);
      }
      if (this.selectedBrand) {
        const ids = this.selectedBrand.distributorIds;

        return this.distributors.filter(distributor => ids.indexOf(distributor.id) >= 0);
      }

      return this.distributors.filter(distributor => distributor.sellsNewCars === true);
    },
    distributorsFromCommune() {
      return this.filteredDistributors.filter(distributor => distributor.commune === this.commune);
    },
    distributorsCloseToCommune() {
      return this.filteredDistributors
        .filter((distributor) => distributor.commune !== this.commune)
        .sort((a, b) => {
          return this.getVectorialDistance(this.center.lat, a.latitude, this.center.lng, a.longitude) <
                 this.getVectorialDistance(this.center.lat, b.latitude, this.center.lng, b.longitude) ? -1 : 1;
        }).slice(0, NUMBER_OF_NEARBY_DISTRIBUTORS);
    },
    communes() {
      const filteredDistributors = (this.region) ?
        this.distributors.filter(distributor => distributor.region === this.region) :
        this.distributors;

      return [... new Set(filteredDistributors.map(distributor => distributor.commune))]
        .sort((a, b) => a.localeCompare(b, 'es'));
    },
    regions() {
      return [... new Set(this.distributors.map(distributor => distributor.region))]
        .sort((a, b) => a.localeCompare(b, 'es'));
    },
  },
};
</script>
