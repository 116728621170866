<template>
  <ValidationObserver
    ref="form"
    tag="div"
  >
    <span v-if="!isValid()">Esta URL no es válida.</span>
    <form
      v-else
      class="form"
    >
      <div class="form__row form__row--title">
        <div class="form__check"></div>
        <div class="subtitle mb-0">
          <b>PASO 2</b>
        </div>
        <div class="title">
          <b>Solicitud de crédito</b>
        </div>
        <div class="subtitle">
          <b>Hola {{ this.name }}, ¡gracias por visitarnos nuevamente!</b> <br>
          Completa el siguiente formulario para iniciar <br>
          el envío de tu solicitud de crédito.
        </div>
      </div>
      <div class="form__horizontal form__horizontal--input">
        <form-input
          :rules="`required|date_format:dd/MM/yyyy|date_before:${today},dd/MM/yyyy`"
          name="Fecha de Nacimiento"
          ref="birthDate"
        >
          <text-input
            v-model="form.birthDate"
            placeholder="Fecha de Nacimiento"
          >
            <template #button>
              <v-date-picker
                @input="updateDate($event, 'birthDate', 'dd/MM/yyyy')"
                :value="birthDate"
                :popover="{ placement: 'left', visibility: 'click' }"
                locale="es"
                :first-day-of-week="2"
                :maxDate="maxDate"
              >
                <button
                  class="text-input__button"
                  type="button"
                >
                  <i class="material-icons">date_range</i>
                </button>
              </v-date-picker>
            </template>
          </text-input>
        </form-input>
        <form-input
          rules="required"
          name="Sexo"
        >
          <select-input
            :options="['Masculino', 'Femenino', 'Otro']"
            :placeholder="'Sexo'"
            v-model="form.sex"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Dirección"
        >
          <text-input
            v-model="form.address"
            placeholder="Dirección"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Region"
        >
          <select-input
            :options="regions"
            :placeholder="'Seleccione Región'"
            v-model="selectedRegion"
            @input="setCommunes()"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Comuna"
        >
          <select-input
            :options="communes"
            v-model="selectedCommune"
            :placeholder="'Seleccione Comuna'"
          />
        </form-input>
      </div>
      <div class="form__horizontal form__horizontal--input">
        <form-input
          rules="required"
          name="Estado Civil"
        >
          <select-input
            :options="civilStatuses"
            v-model="form.civilStatus"
            :placeholder="'Estado Civil'"
          />
        </form-input>
        <form-input
          rules="required"
          name="Estudios"
        >
          <select-input
            :options="studies"
            v-model="form.studies"
            :placeholder="'Estudios'"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Casa o departamento propio"
        >
          <select-input
            :options="housingType"
            v-model="form.houseOwner"
            :placeholder="'¿Tienes casa o departamento propio?'"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Actividad Laboral"
        >
          <select-input
            :options="workTypes"
            v-model="form.workType"
            :placeholder="'Actividad Laboral'"
          />
        </form-input>
      </div>
      <div
        class="form__row form__row--input"
        v-if="dependent"
      >
        <form-input
          :rules="`required|date_format:dd/MM/yyyy|date_before:${startOfMonth},dd/MM/yyyy`"
          label="Fecha Ingreso Empleo Actual:"
          name="Fecha Ingreso Empleo Actual"
          ref="workStart"
        >
          <text-input
            v-model="form.workStart"
            placeholder="22/07/2012"
          >
            <template #button>
              <v-date-picker
                @input="updateDate($event, 'workStart', 'dd/MM/yyyy')"
                :value="workStart"
                :popover="{ placement: 'left', visibility: 'click' }"
                locale="es"
                :first-day-of-week="2"
              >
                <button
                  class="text-input__button"
                  type="button"
                >
                  <i class="material-icons">date_range</i>
                </button>
              </v-date-picker>
            </template>
          </text-input>
        </form-input>
      </div>
      <div
        class="form__row form__row--input"
        v-if="dependent"
      >
        <form-input
          rules="required"
          label="Empresa o Empleador:"
          name="Empresa o Empleador"
        >
          <text-input v-model="form.employerName" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Nacionalidad"
        >
          <select-input
            :options="['Chileno', 'Extranjero']"
            :placeholder="'Nacionalidad'"
            v-model="form.nationality"
          />
        </form-input>
      </div>
      <div class="form__row form__row--title">
        <div class="subtitle">
          <input
            v-model="agreedTerms"
            type="checkbox"
          >
          <span class="terms">
            He leido y acepto los
            <a
              class="form__link"
              href="/terminos-y-condiciones"
              target="_blank"
            >Términos y Condiciones</a>
          </span>
        </div>
      </div>
      <div class="form__row form__row--button">
        <button
          type="button"
          class="amicar-wrapper__button amicar-wrapper__button--send amicar-wrapper__button--no-margin"
          @click="sendData()"
        >
          Enviar Datos
        </button>
      </div>
    </form>
    <div
      class="spinner__container"
      style="text-align: center;"
      v-show="loading"
    >
      <AmicarLoading :message="'Estamos guardando tu solicitud.'" />
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { format, startOfMonth, addMonths } from 'date-fns';

import './form/validation-rules';
import FormInput from '../components/form/form-input';
import AmicarLoading from '../components/amicar-loading.vue';
import TextInput from '../components/form/text-input';
import SelectInput from '../components/form/select-input';
import FormService from '../services/form.js';
import ChileService from '../services/chile.js';

const formService = new FormService();
const chileService = new ChileService();

export default {
  components: {
    ValidationObserver,
    FormInput,
    TextInput,
    SelectInput,
    AmicarLoading,
  },
  data() {
    return {
      maxDate: new Date(),
      name: null,
      lead: null,
      form: {
        birthDate: null,
        nationality: null,
        sex: null,
        commune: null,
        civilStatus: null,
        studies: null,
        houseOwner: null,
        employerName: null,
        workType: null,
        workStart: null,
        address: null,
      },
      agreedTerms: false,
      validTerms: true,
      today: new Date().toISOString(),
      startOfMonth: null,
      birthDate: null,
      workStart: null,
      regions: null,
      communes: null,
      loading: false,
      selectedRegion: null,
      selectedCommune: null,
      housingType: [
        'Arrienda',
        'Propia con Deuda',
        'Propia sin Deuda',
        'Vive con Familiares',
      ],
      civilStatuses: [
        { id: 1, name: 'Casado', value: 'Casado' },
        { id: 2, name: 'Divorciado', value: 'Separado Legalmente' },
        { id: 3, name: 'Unión Civil', value: 'Casado' },
        { id: 4, name: 'Separado de Hecho', value: 'Separado de Hecho' },
        { id: 5, name: 'Soltero', value: 'Soltero' },
        { id: 6, name: 'Viudo', value: 'Viudo' },
      ],
      studies: [
        { id: 1, name: 'Educación Básica', value: 'Primarios' },
        { id: 2, name: 'Educación Media', value: 'Secundarios' },
        { id: 3, name: 'Educación Universitaria', value: 'Superiores' },
        { id: 4, name: 'Postgrado', value: 'Postgrado' },
        { id: 5, name: 'Magíster', value: 'Magister' },
        { id: 6, name: 'Sin Estudios', value: 'Otros' },
        { id: 7, name: 'Otros', value: 'Otros' },
      ],
      workTypes: [
        { id: 1, name: 'Dependiente No Profesional', type: 'dependiente' },
        { id: 2, name: 'Dependiente Profesional', type: 'dependiente' },
        { id: 3, name: 'Independiente No Profesional', type: 'independiente' },
        { id: 4, name: 'Independiente Profesional', type: 'independiente' },
        { id: 5, name: 'Jubilado', type: null },
        { id: 6, name: 'Otros', type: null },
      ],
    };
  },
  mounted() {
    this.startOfMonth = startOfMonth(addMonths(new Date(), 1)).toISOString();
    this.regions = chileService.getRegions();
    this.communes = chileService.getCommunesByRegion(this.region);
    this.selectedRegion = this.regions[0];
    this.selectedCommune = this.communes[0];
    this.maxDate.setFullYear( this.maxDate.getFullYear() - 22 );
  },
  watch: {
    agreedTerms() {
      this.validTerms = this.agreedTerms;
    },
  },
  created() {
    this.name = this.$route.query.name;
    this.lead = this.$route.query.lead;
  },
  computed: {
    dependent() {
      return this.form.workType && this.form.workType.type === 'dependiente';
    },
    selectDefaultCommune() {
      this.selectedCommune = this.communes[0];
    }
  },
  methods: {
    isValid() {
      const token = atob(this.$route.query.token);
      return token === this.lead + this.name.replace(/ /g, '') || token === this.lead + this.name;
    },
    setCommunes() {
      this.communes = chileService.getCommunesByRegion(this.selectedRegion.id);
    },
    sendData() {
      this.$refs.form.validate().then(success => {
        if (!success) return;
        this.updateLead();
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async updateLead() {
      this.loading = true;
      try {
        this.scrollToTop();
        const body = {
          'lead': this.lead,
          'genero': this.form.sex,
          'comuna': this.selectedCommune.name,
          'region': this.selectedRegion.externalCode,
          'estudios': this.form.studies.value,
          'direccion': this.form.address,
          'nacionalidad': this.form.nationality,
          'empleador': this.form.employerName,
          'tipoTrabajador': this.form.workType.type,
          'estadoCivil': this.form.civilStatus.value,
          'tipoResidencia': this.form.houseOwner,
          'arriendo_dividendo_cliente__c': 1,
          'segundo_formulario_ok': true,
          'fechaNacimiento': this.salesforceDateFormat(this.form.birthDate),
          'fechaIngresoEmpleo': this.salesforceDateFormat(this.form.workStart),
          'descripcion': `Nacimiento: ${this.form.birthDate}, Comienzo Trabajo: ${this.form.workStart}`,
        };
        await formService.updateLead(body);
        this.loading = false;
        this.$cookies.remove('answers');
        window.location.href = '/exito?lead=' + this.lead;
      } catch (e) {
        this.loading = false;
        this.$cookies.remove('answers');
        window.location.href = '/exito';
      }
    },
    salesforceDateFormat(dateString) {
      if (!dateString) return null;
      const date = dateString.split('/');

      return date[2] + '-' + date[1] + '-' + date[0];
    },
    updateDate(date, dateKey, dateFormat = 'dd/MM/yyyy') {
      if (!date) return;
      this[dateKey] = date;
      this.form[dateKey] = format(date, dateFormat);
      this.$refs[dateKey].$children[0].$children[0].$emit('blur');
    },
  },
};
</script>

<style scoped>
.loading-box {
  margin-left: 28%;
}
</style>

<style lang="scss">

.header {
  width: 109.5%;
  text-align: center;
  color: #ED6831;
  font-size: 12px;
  margin-top: -20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 600;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  line-height: 14px;
  background-color: rgba(237,104,49,0.1);
}

.terms {
  width: 100%;
  padding-top: 18px;
  text-align: center;
  font-size: 14px;
  color: #112D47;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}


.privacy-policy {
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 60%;
  padding-top: 18px;
  text-align: justify;
  font-size: 14px;
  color: #112D47;
  letter-spacing: 0;
  line-height: 29px;
}

.title {
  width: 100%;
  padding-top: 9px;
  text-align: center;
  font-size: 23px;
  color: #112D47;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}

.subtitle {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 8px;
  font-size: 15px;
  font-family: "Source Sans Pro";
  font-weight: 300;
  color: #112D47;
  line-height: 19px;
  text-align: center;
}

</style>

<style lang="scss" scoped>
@import '../styles/app/variables';
@import './form/form';

.form {
  color: #B9B9B9;;
  --font-size: 20px;
  --spacing: 17.5px;
  border: 1px solid rgba(196, 202, 213, 0.24);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 29px 35px 0 rgba(212, 212, 212, 0.29);

  &__check {
    height: 100px;
    width: 100px;
    background: url("../assets/Calculadora_azul.png") no-repeat center;
    background-size: cover;
    margin: auto;
    margin-bottom: 20px;
  }
}

</style>
