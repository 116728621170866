<template>
  <div class="product-carousel-container">
    <div class="product-carousel">
      <div class="product-carousel-slide product-carousel-slide--container">
        <button
          class="product-carousel__arrow"
          @click="slideTo('prev')"
        >
          <i class="material-icons">keyboard_arrow_left</i>
        </button>
        <button
          class="product-carousel__arrow product-carousel__arrow--right"
          @click="slideTo('next')"
        >
          <i class="material-icons">keyboard_arrow_right</i>
        </button>
      </div>
      <div
        class="product-carousel__slides"
        :style="`transform: translateX(-${currentSlide * 306}px)`"
      >
        <div
          class="product-carousel__slide product-carousel-slide"
          v-for="(slide, index) in slides"
          :key="index"
        >
          <h2 class="product-carousel-slide__title">
            {{ slide.title }}
          </h2>
          <p class="product-carousel-slide__subtitle">
            {{ slide.subtitle }}
          </p>
          <div
            class="product-carousel-slide__html"
            v-html="slide.html"
          />
        </div>
      </div>
      <div
        class="product-carousel-container__image"
        :style="`background-image: ${backgroundImage}`"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  computed: {
    backgroundImage() {
      const gradient = 'linear-gradient(90deg, transparent, #f4f4f4 20%);';
      if (this.slides[this.currentSlide].image) {
        return `url(${this.slides[this.currentSlide].image}), ${gradient}`;
      }

      return `none, ${gradient}`;
    },
  },
  methods: {
    slideTo(dir) {
      if (dir === 'next') {
        const nextSlide = this.currentSlide + 1;
        this.currentSlide = nextSlide > this.slides.length - 1 ? 0 : nextSlide;
      } else {
        const prevSlide = this.currentSlide - 1;
        this.currentSlide = prevSlide < 0 ? this.slides.length - 1 : prevSlide;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../styles/app/_variables';

.product-carousel-container {
  overflow: hidden;
  margin: 0 -15px;
  padding-bottom: 60px;

  @media only screen and (min-width: 768px) {
    margin: 0 -45px;
  }

  &__image {
    @media only screen and (min-width: 768px) {
      background-image: none, linear-gradient(90deg, transparent, #f4f4f4 20%);
      background-position-y: 50%;
      background-position-x: 100%;
      background-repeat: no-repeat;
      background-size: 70%, cover;
      height: 100%;
      width: calc(100% - 440px);
      position: absolute;
      z-index: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.product-carousel {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    width: auto;
    justify-content: flex-start;
    padding: 0 30px;
  }

  &__arrow {
    background-color: transparent;
    border: 0;
    color: $primary-color;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -50px;
    padding: 0;
    cursor: pointer;
    text-shadow: 1px 4px 6px rgba(0, 0, 0, .3);

    &:focus {
      outline: none;
    }

    &--right {
      left: initial;
      right: -50px;
    }

    i {
      font-size: 60px;
    }
  }

  &__cta {
    background: $primary-color;
    box-shadow: 0 14px 13px 0 rgba(0, 0, 0, .12);
    border-radius: 8px;
    font-size: 17px;
    color: $contrast-color;
    letter-spacing: 0;
    text-align: center;
    height: 48px;
    padding: 0 45px;
    border: 0;
    cursor: pointer;
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__slides {
    display: flex;
    width: 301px;
    padding: 14px 14px 30px;
    transition: transform .3s ease-in-out;
  }

  &__slide {
    margin-right: 30px;
    flex: 0 0 276px;
  }
}

.product-carousel-slide {
  width: 276px;
  padding: 38px 26px;
  background: #fff;
  box-shadow: 0 14px 13px 0 rgba(0, 0, 0, .12);
  border-radius: 8px;
  height: 382px;
  position: relative;

  &--container {
    background: transparent;
    box-shadow: none;
    position: absolute;
    top: 14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @media only screen and (min-width: 768px) {
      left: 44px;
      transform: none;
    }
  }

  &__title {
    font-size: 18px;
    color: $text-color;
    font-weight: 800;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  &__subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: center;
  }

  &__html {
    padding: 35px 0;
    font-size: 14px;

    &::v-deep ul {
      list-style: none;
      margin: 0;
      padding: 0 0 0 20px;
    }

    &::v-deep li::before {
      content: '\2022';
      color: $primary-color;
      font-weight: bold;
      display: inline-block;
      font-size: 20px;
      width: 15px;
      margin-left: -15px;
    }
  }
}
</style>
