class UploadService {
  constructor() {
    this.headers = new Headers();
    this.defaultOptions = { headers: this.headers, method: 'POST' };
  }

  upload(formData) {
    return fetch(`${window.location.origin}/upload`, {
      ...this.defaultOptions,
      body: formData,
    }).then(res => res.json());
  }
}

export default UploadService;
