<template>
  <ValidationObserver ref="form" tag="div">
    <div
      v-show="!feedback"
      class="form"
      :style="[coloredBackground('background'), coloredText('text')]"
    >
      <div class="form__row form__row--title">
        <div class="form__title">
          Ingresa tus datos
        </div>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|rut"
          label="Rut:"
          name="rut"
          :compact="true"
        >
          <text-input
            v-model="form.identifier"
            placeholder="Ej: 11.111.111-1"
            :is-rut="true"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          label="Nacionalidad"
          name="Nacionalidad"
          :compact="true"
        >
          <select-input :options="['Chileno', 'Extranjero']" v-model="form.nationality" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|alpha_spaces"
          label="Nombre"
          name="nombre"
          :compact="true"
        >
          <text-input v-model="form.name" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|alpha_spaces"
          label="Apellido Paterno:"
          name="Apellido Paterno"
          :compact="true"
        >
          <text-input v-model="form.fatherSurname" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|alpha_spaces"
          label="Apellido Materno:"
          name="Apellido Materno"
          :compact="true"
        >
          <text-input v-model="form.motherSurname" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|email"
          label="Correo Electrónico:"
          name="Correo Electrónico"
          :compact="true"
        >
          <text-input v-model="form.email" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|phone"
          label="Móvil:"
          name="Móvil"
          placeholder="Ej: 9 1111 1111"
          :compact="true"
        >
          <text-input v-model="form.phone" />
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          :rules="`required|min_income_cl_mask:${minIncome}`"
          label="Renta Líquida:"
          name="Renta Líquida"
          :compact="true"
        >
          <text-input v-model="form.income" />
        </form-input>
      </div>
      <div
        class="form__row form__row--input"
        v-if="showVehicleCondition === 'show'"
      >
        <form-input
          rules="required"
          label="Estado del auto:"
          name="Estado del auto"
          :compact="true"
        >
          <select-input
            :options="vehicleConditions"
            v-model="selectedVehicleCondition"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input" v-if="!initialVehicleBrand">
        <form-input
          rules="required"
          label="Marca del auto:"
          name="Marca del auto"
          :compact="true"
        >
          <select-input v-if="from==='bravoauto'"
            :placeholder="'Seleccione la Marca'"
            :options="computedBrands"
            v-model="selectedBrand" />
          <select-input v-else :optgroups="computedBrands" v-model="selectedBrand" />
        </form-input>
      </div>
      <div class="form__row form__row--value">
        <div class="form__input">
          Precio del auto:
        </div>
        <span :style="coloredText('result')">
          {{ simulatorData.vehiclePrice | money }}
        </span>
      </div>
      <div class="form__row form__row--value">
        <div class="form__input">
          Monto del Pie:
        </div>
        <span>
          {{ simulatorData.downPayment | money }}
        </span>
      </div>
      <div class="form__row form__row--terms">
        <input
          class="amicar-checkbox amicar-checkbox--gray"
          v-model="agreedTerms"
          type="checkbox"
          autocomplete="off"
        >
        <span class="form__terms">
          Estoy de acuerdo con los
          <a
            class="form__link"
            href="/terminos-y-condiciones"
            target="_blank"
          >Términos y Condiciones</a>
        </span>
        <div
          class="form__error form__error--left"
          v-if="!validTerms"
        >
          Debes aceptar los términos y condiciones
        </div>
      </div>
      <div class="form__row form__row--terms form__row--last">
        <input
          class="amicar-checkbox amicar-checkbox--gray"
          v-model="agreedPolicies"
          type="checkbox"
          autocomplete="off"
        >
        <span class="form__terms">
          Autorizo el
          <a
            class="form__link"
            href="/politicas-de-privacidad"
            target="_blank"
          >Tratamiento de mis datos</a>
        </span>
        <div
          class="form__error form__error--left"
          v-if="!validPolicies"
        >
          Debes aceptar el tratamiento de datos
        </div>
      </div>
      <div class="form__row form__row--button">
        <button
          class="form__button form__button--back"
          @click="$emit('toggle-form')"
          :style="coloredBackground('disclaimer')"
        >
          VOLVER
        </button>
        <button
          id="simulator-form-submit"
          type="button"
          @click="checkClientApproval()"
          class="form__button form__button--send"
          :style="coloredBackground('result')"
        >
          ENVIAR
        </button>
      </div>
      <div class="spinner__container" v-show="loading">
        <clip-loader
          class="spinner"
          :loading="loading"
          :size="'45px'"
          :color="$colorValueFromKey('result')"
        />
      </div>
    </div>
    <feedback
      v-if="feedback"
      @back="toggleFeedback()"
      :form="form"
      :approved="approved"
      :conditioned="conditioned"
      :errors="errors"
      v-bind="$attrs"
      :result-success-text="resultSuccessText"
      :result-rejected-text="resultRejectedText"
    />
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import "./form/validation-rules";
import FormInput from "../components/form/form-input";
import TextInput from "../components/form/text-input";
import SelectInput from "../components/form/select-input";
import ColorMixin from "../mixins/color";
import SizeMixin from "../mixins/size";
import FormService from "../services/form.js";
import Feedback from "./feedback.vue";
import { MIN_INCOME } from "../constants/es-CL";
import { VEHICLE_CONDITION_OPTIONS } from "../constants/shared";

const formService = new FormService();

export default {
  mixins: [ColorMixin, SizeMixin],
  components: {
    ValidationObserver,
    Feedback,
    FormInput,
    TextInput,
    SelectInput
  },
  props: {
    simulatorData: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String,
      default: ""
    },
    originUrl: {
      type: String,
      default: ""
    },
    showVehicleCondition: {
      type: String,
      default: "show"
    },
    brands: {
      type: [Object, Array],
      default: () => {}
    },
    initialVehicleBrand: {
      type: String,
      default: ""
    },
    resultSuccessText: {
      type: String,
      default: null
    },
    resultRejectedText: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      form: {
        from: "",
        identifier: "",
        nationality: "",
        name: "",
        fatherSurname: "",
        motherSurname: "",
        email: "",
        phone: "",
        income: null,
        workingCondition: "dependiente",
        vehicleCondition: null,
        vehicleBrandId: "",
        vehicleBrand: "",
        originUrl: this.originUrl,
        workEntryDate: 2,
        vehicleYear: new Date().getFullYear(),
        tradeInCarValue: 0,
        vehicleType: 'LIVIANO-NUEVO'
      },
      loading: false,
      feedback: false,
      approved: false,
      conditioned: false,
      agreedTerms: false,
      agreedPolicies: false,
      validTerms: true,
      validPolicies: true,
      errors: "",
      vehicleConditions: VEHICLE_CONDITION_OPTIONS,
      minIncome: MIN_INCOME,
      selectedBrand: null,
      selectedVehicleCondition: null
    };
  },
  mounted() {
    this.selectedVehicleCondition =
      this.showVehicleCondition === "show"
        ? ""
        : this.vehicleConditions.find(
            condition => condition.id === this.showVehicleCondition
          );

    this.form.vehicleBrand =
      (this.initialVehicleBrandObject === null)
        ? ""
        : this.initialVehicleBrandObject.name;

    this.form.vehicleBrandId =
      (this.initialVehicleBrandObject === null)
        ? ""
        : this.initialVehicleBrandObject.indexaId;

    this.form.from = this.getIframeOrigin(this.from)
  },
  methods: {
    postSizeChangeMessage(status = false) {
      parent.postMessage(
        {
          type: "toggle-simulator",
          status
        },
        window.location.origin
      );
    },
    toggleFeedback() {
      this.feedback = !this.feedback;
      this.postSizeChangeMessage(true);
    },
    async checkClientApproval() {
      this.$refs.form.validate().then(async success => {
        if (!this.agreedTerms &&!this.agreedPolicies) {
          this.validTerms = false
          this.validPolicies = false
          return
        }else if (!this.agreedTerms) {
          this.validTerms = false
          return
        }else if (!this.agreedPolicies) {
          this.validPolicies = false
          return
        }
        if (!success) return;

        const body = Object.assign({}, this.form, this.simulatorData);
        this.loading = true;
        const res = await formService.checkClientApproval(body);
        this.loading = false;
        if (res.evaluation.result == 'APROBADO' ) {
          this.approved = true;
        } else {
          this.approved = false;
        }
        if(res.evaluation.result == 'CONDICIONADO'){
          this.conditioned = true
        }
        await new FormService().approveTermsDocument({ 'leadId': res.evaluation.lead });
        this.toggleFeedback();
      });
    },
    getIframeOrigin(from) {
      if (!from) {
        if(window.location != window.parent.location){
          if (document.location.ancestorOrigins != undefined){
            return document.location.ancestorOrigins[0]
          }else {
            return document.referrer
          }
        }else{
          return document.location.href
        }
      }else{
        return from
      }
    }
  },
  computed: {
    computedBrands() {
      const brands = (this.from !== 'bravoauto') ? Object.keys(this.brands).map(key => ({
        key,
        options: this.brands[key]
      }))
      :
      this.brands;

      // brands.push({ key: 'otros', options: [{ distributor: 'Otros', name: 'Otros', id: 'otros', indexaId: 1000 }] });
      const parentUrl = (window.location != window.parent.location)
            ? ((document.location.ancestorOrigins != undefined) ? document.location.ancestorOrigins[0] : document.referrer)
            : document.location.href;
      if(parentUrl.includes('gildemeisterautos') || parentUrl.includes('hyundai')) {
        return brands.filter(brandGroup => brandGroup.key != 'derco');
      } else if (this.from == "rutamotor") {
        if (
          this.selectedVehicleCondition === null ||
          this.selectedVehicleCondition === "" ||
          this.selectedVehicleCondition.id === "N"
        ) {
          return brands.filter(brandGroup => brandGroup.key == "otros");
        } else {
          return brands;
        }
      } else {
        return brands;
      }
    },
    initialVehicleBrandObject() {
      if (!this.initialVehicleBrand) return null;
      const flatBrands = this.computedBrands.reduce(
        (accumulator, brand) => accumulator.concat(brand.options),
        []
      );

      return flatBrands.find(
        brand => brand.indexaId === Number(this.initialVehicleBrand)
      );
    }
  },
  filters: {
    money(value) {
      return `$${value.toLocaleString("es")}`;
    }
  },
  watch: {
    agreedTerms() {
      this.validTerms = this.agreedTerms;
    },
    agreedPolicies() {
      this.validPolicies = this.agreedPolicies;
    },
    selectedVehicleCondition() {
      this.form.vehicleCondition =
        this.selectedVehicleCondition && this.selectedVehicleCondition.id;
      if(this.selectedVehicleCondition){
        this.form.vehicleType = 'LIVIANO-' + this.selectedVehicleCondition.name.toUpperCase()
      }
    },
    selectedBrand() {
      this.form.vehicleBrand = this.selectedBrand.name;
      this.form.vehicleBrandId = this.selectedBrand.indexaId;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/app/variables";
@import "./form/form";

.spinner {
  left: 50%;
  position: absolute;
  top: 45%;
  transform: translateX(-50%);

  &__container {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.71);
    height: 100%;
    top: 0;
    width: 100%;
  }
}

.form {
  &__terms {
    font-size: .65em;
    padding-left: .6em;
  }

  &__row {
    &--terms {
      color: $gray-color;
      display: flex;
      font-size: 1em;
      line-height: 30px;
    }
    &--last{
      margin-bottom: 20px;
    }
  }

  &__button {
    background-color: $primary-color;
    border: 0;
    border-radius: 2px;
    color: white;
    flex: 1;
    font-size: 0.8em;
    height: 2.5em;
    opacity: 0.9;
    text-align: center;
    width: 100%;

    &:hover {
      opacity: 0.5;
    }

    &:active {
      opacity: 1;
    }

    &--send {
      background-color: $primary-color;
      margin-left: 1.5%;
    }

    &--back {
      margin-right: 1.5%;
    }
  }
  .amicar-checkbox{
    margin-top: 0.4em;
  }
}
</style>
