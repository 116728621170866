<template>
  <div class="feedback">
    <div class="feedback__subtitle">
       Lamentamos no poder ayudarte en esta oportunidad 
    </div>
    <div class="feedback__message mt-3">
      Según la información que ingresaste, en este momento <br/>
      no podemos continuar con tu simulación de crédito. <br/><br/>
    </div>
    <div class="feedback__check" />
    <div class="feedback__message mt-3">
      Para más información, visita a una de nuestras sucursales <br/>
      donde un ejecutivo Amicar podrá asesorarte personalizadamente.
    </div>
    <!--<button
      class="feedback__button ml-3"
      style="cursor: pointer;"
      @click="dealerships()"
    >
      Sucursales
    </button>
    -->
    <b-button
      id="recover-lead-submit"
      class="dealerships button is-outlined"
      rounded
      @click="dealerships()"
    >
      Sucursales
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => { },
    },
    rejected_amount: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    dealerships() {
      window.location.href = '/red-de-concesionarios';
    }
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  @import '../styles/app/variables';
  background-color: $feedback-background;
  color: $feedback_text;
  padding: 42px;
  text-align: center;
  &__greeting {
    font-size: 41px;
    padding: 5px 0;
    font-weight: bold;
  }
  &__subtitle {
    font-size: 22px;
    padding: 5px 0;
    font-weight: bold;
  }
  &__message {
    font-size: 15px;
    font-weight: lighter;
    color: #6F6F6F;
    letter-spacing: .6px;
    padding: 10px 0;
  }
  &__field {
    border-top: solid 1px #000;
    font-size: 18px;
    padding: 5px;
    &--bottom {
      border-bottom: solid 1px #000;
    }
  }
  &__button {
    background-color: $feedback-background;
    color: $feedback-back;
    margin-top: 30px;
    padding: 8px 16px;
    border-color: $gray-color;
    border-width: 1px;
    border-radius: 20px;
    font-weight: 500;
    letter-spacing: 1.2px;
  }

  &__check {
    height: 107px;
    width: 80px;
    background: url("../assets/ubicación_150x200.png") no-repeat center;
    background-size: cover;
    margin: auto;
  }
}

.dealerships {
  @import '../styles/app/variables';
  color: $primary-color;
}
</style>
