<template>
  <!-- eslint-disable vue/component-name-in-template-casing -->
  <div class="container simulator" v-cloak>
    <div class="simulator__row">
      <span>Precio del auto:</span>
      <div
        class="simulator__input"
      >
        {{ $n(amount, 'currency') }}
      </div>
    </div>

    <div class="simulator__row simulator__row--info">
      <div class="simulator__slider-container">
        <vue-slider
          v-model="amount"
          v-bind="amountSliderOptions"
        />
      </div>
    </div>

    <template v-if="downPaymentType === downPaymentTypes.AMOUNT">
      <div class="simulator__row">
        <span>{{ $t('simulator.downPayment') }}:</span>
        <money
          class="simulator__input"
          v-model="downPayment"
        />
      </div>
    </template>
    <template v-else>
      <div class="simulator__row">
        <span>{{ $t('simulator.downPayment') }}:</span>
        <div class="simulator__input-container">
          <input
            class="simulator__input simulator__input--with-suffix simulator__input--down-payment"
            v-model.number="downPaymentPercentage"
          >
          <div class="simulator__input-suffix">
            %
          </div>
        </div>
      </div>
    </template>

    <div class="simulator__row simulator__row--info">
      <div class="simulator__slider-container">
        <vue-slider
          v-model="downPaymentPercentage"
          v-bind="downPaymentSliderOptions"
        />
      </div>
    </div>

    <template v-if="downPaymentType === downPaymentTypes.AMOUNT">
      <div class="simulator__row simulator__row--info">
        <span>Equivalente a: </span><span>{{ downPaymentPercentage | roundTo(1) }}%</span>
      </div>
    </template>
    <template v-else>
      <div class="simulator__row simulator__row--error">
        <span v-if="downPaymentPercentage < initialDownPayment">
          Debe ser igual o superior al {{ initialDownPayment }}% del precio del auto
        </span>
      </div>
      <div class="simulator__row simulator__row--info">
        <span>Equivalente a: </span><span>{{ $n(downPayment, 'currency') }}</span>
      </div>
    </template>

    <div class="simulator__row simulator__row--error">
      <span v-if="!validConfigurationsOrNotPeru">
        El monto a financiar es muy bajo, intente con otros valores de cuota y precio
      </span>
    </div>

    <div class="simulator__row row" v-if="showIntelligentOption">
      <span>{{ $t('smartChoice') | capitalize }}:</span>
      <input
        id="intelligent-checkbox"
        class="amicar-checkbox amicar-checkbox--primary"
        v-model="intelligent"
        type="checkbox"
      >
    </div>

    <div class="simulator__row row">
      <span>Nº Cuotas:</span>
      <div class="simulator__select">
        <div class="simulator__select-arrows" />
        <select v-model="term">
          <option
            :key="option"
            v-for="option in termOptions"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>

    <div
      class="simulator__row simulator__row--result"
    >
      <span>Valor cuota: </span>
      <span>{{ validConfigurationsOrNotPeru ? $n(monthlyPayment, 'currency') : '-' }}</span>
    </div>

    <hr class="simulator__separator">

    <div class="simulator__row simulator__row--summary">
      <span
        class="simulator__total"
        v-if="!showCost"
      >
        Valor cuota referencial
      </span>
      <span
        class="simulator__total"
        v-if="showCost"
      >
        Costo total del crédito: {{ $n(totalCost, 'currency') }}
      </span>
      <span class="simulator__cae">
        <template v-if="showCost">CAE: {{ this.equivalentAnnualCharge }}%</template>
        <span
          @mouseenter="toggleCaeBubble(true)"
          @mouseleave="toggleCaeBubble(false)"
          class="simulator__cae-icon material-icons"
        >
          help
        </span>
        <div
          class="simulator__cae-bubble"
          :class="{ 'simulator__cae-bubble--visible': showCaeBubble }"
        >
          <span class="material-icons simulator__cae-bubble-arrow">
            arrow_drop_down
          </span>
          {{ $t('simulator.info') }}
        </div>
      </span>
    </div>

    <div
      class="simulator__row simulator__row--summary"
      v-if="intelligent && showIntelligentOption"
    >
      <span>Cuota Final : </span> <span>{{ $n(vfmg, 'currency') }}</span>
    </div>

    <div
      class="simulator__row simulator__row--button"
      v-if="!simulatorFirst || showFormButton"
    >
      <button
        v-if="!simulatorFirst"
        class="amicar-wrapper__button amicar-wrapper__button--back"
        :class="{'amicar-wrapper__button--no-margin': simulatorFirst}"
        :disabled="!validSimulation"
        @click="$emit('toggle-form')"
      >
        VOLVER
      </button>
      <button
        id="simulator-submit"
        class="amicar-wrapper__button amicar-wrapper__button--send"
        :class="{'amicar-wrapper__button--no-margin': simulatorFirst}"
        :disabled="!validSimulation || !validConfigurationsOrNotPeru"
        @click="sendData()"
        v-if="showFormButton"
      >
        {{ simulatorFirst ? $t('simulator.continue') : 'ENVIAR' }}
      </button>
    </div>
    <div
      class="simulator__row simulator__row--disclaimer"
      v-if="!simulatorFirst && showFormButton"
    >
      <span>
        *Al enviar la simulación está de acuerdo con
        <a href="/terminos-y-condiciones">
          tyc
        </a>.
      </span>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-magic-numbers */
import VueSlider from 'vue-slider-component';
import debounce from 'lodash.debounce';

import MonthlyPayments from '../utils/monthly-payments';
import irr from '../utils/irr';

const CONVENTIONAL_TERM_OPTIONS = [12, 24, 36, 48, 60];
const PERU_EXTRA_TERMS = [72];
const INTELLIGENT_TERM_OPTIONS = [24, 36];
const DEBOUNCE_RATE = 800;

export default {
  components: { VueSlider },
  props: {
    simulatorFirst: {
      type: Boolean,
      default: null,
    },
    downPaymentType: {
      type: Number,
      default: 0,
    },
    showFormButton: {
      type: Boolean,
      default: true,
    },
    initialAmount: {
      type: Number,
      default: 10000000,
    },
    userMinAmount: {
      type: Number,
      default: 0,
    },
    userMaxAmount: {
      type: Number,
      default: Infinity,
    },
    initialDownPayment: {
      type: Number,
      default: 20,
    },
    initialTerm: {
      type: Number,
      default: 24,
    },
    conventionalProduct: {
      type: Object,
      default: () => {},
    },
    intelligentProduct: {
      type: Object,
      default: () => {},
    },
    administrationCosts: {
      type: Array,
      default: () => [],
    },
    insuranceRates: {
      type: Array,
      default: () => [],
    },
    showCost: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      AMOUNT_INTERVAL: this.$i18n.locale === 'es-CL' ? 500000 : 100,
      fontScale: 100,
      spacingScale: 70,
      amount: 0,
      downPaymentPercentage: this.initialDownPayment,
      downPaymentTypes: { PERCENTAGE: 0, AMOUNT: 1 },
      term: this.initialTerm,
      intelligent: false,
      defaultInsuranceRate: 0,
      defaultAdministrationCost: 0,
      defaultProduct: {
        configurations: [],
        downPaymentMin: 0,
        downPaymentMax: 1,
      },
      defaultConfig: {
        maxRate: 0.0233,
        vfmgRatio: 0.4,
      },
      downPaymentRanges: {
        conventional: { min: 0, max: 50 },
        intelligent: { min: 0, max: 49 },
      },
      tax: 0.008,
      showCaeBubble: false,
      monthlyPayments: new MonthlyPayments(this.conventionalProduct, this.intelligentProduct,
        this.administrationCosts, this.insuranceRates),
      showIntelligentOption: true,
    };
  },
  beforeMount() {
    this.amount = this.initialAmount;
    this.showIntelligentOption = (this.$i18n.locale !== 'es-CL' || this.$route.path.startsWith('/productos') || this.$route.query.oi!=='false');
  },
  mounted() {
    this.intelligent = this.$route.path.startsWith('/productos') && this.$route.query.intelligent=='true'
  },
  methods: {
    toggleCaeBubble(display) {
      this.showCaeBubble = display;
    },
    validateDownPaymentRanges(ranges) {
      Object.entries(ranges).forEach(([, range]) => {
        if (!range.min || +range.min < 0 || +range.min > 100) range.min = 0;
        if (!range.max || +range.max < 0 || +range.max > 100) range.max = 100;
        if (+range.min > +range.max) range.min = 0;
      });

      return ranges;
    },
    irr(...cashFlows) {
      try {
        return irr(...cashFlows);
      } catch (err) {
        return Infinity;
      }
    },
    sendData() {
      const event = this.simulatorFirst ? 'proceed' : 'send-info';
      const creditType = this.intelligent ? 'CI' : 'CC';
      this.$emit(event, {
        vehiclePrice: this.amount,
        downPayment: this.downPayment,
        downPaymentPercentage: this.downPaymentPercentage,
        monthlyPayment: this.monthlyPayment,
        term: this.term,
        creditType,
        scenario: `${creditType}|${this.downPaymentPercentage}|${this.term}`,
        smartChoice: this.intelligent,
      });
    },
  },
  computed: {
    product() {
      const product = this.intelligent ? this.intelligentProduct : this.conventionalProduct;

      return product || this.defaultProduct;
    },

    amountValidConfigs() {
      return this.product.configurations
        .filter(c => c.minAmount <= this.toFinance && c.maxAmount >= this.toFinance);
    },

    configuration() {
      const config = this.amountValidConfigs
        .find(c => c.minTerm <= this.term && c.maxTerm >= this.term);

      return config || this.defaultConfig;
    },

    downPaymentRange() {
      const ranges = this.validateDownPaymentRanges(this.downPaymentRanges);
      const conventionalRange = {
        min: +ranges.conventional.min,
        max: +ranges.conventional.max,
      };
      const intelligentRange = {
        min: +ranges.intelligent.min,
        max: +ranges.intelligent.max,
      };

      return this.intelligent ? intelligentRange : conventionalRange;
    },

    clientRate() {
      return this.configuration.maxRate;
    },

    duration() {
      return (this.intelligent ? this.term + 1 : this.term);
    },

    termOptions() {
      const options = this.intelligent ? INTELLIGENT_TERM_OPTIONS : this.defaultConventionalTermOptions;
      const ranges = this.amountValidConfigs
        .map(config => ({ min: config.minTerm, max: config.maxTerm }));

      return options.filter(opt => ranges.some(range => opt >= range.min && opt <= range.max));
    },

    downPayment: {
      get() {
        return Math.round(this.amount * this.downPaymentPercentage / 100);
      },
      set(value) {
        this.downPaymentPercentage = value / this.amount * 100;
      },
    },

    administrationCost() {
      const difference = this.amount - this.downPayment;
      const cost = this.administrationCosts
        .filter(c => c.minAmount <= difference && c.maxAmount >= difference)
        .find(c => c.minTerm <= this.duration && c.maxTerm >= this.duration);

      return (cost && cost.value) || this.defaultAdministrationCost;
    },

    insuranceRate() {
      const rate = this.insuranceRates
        .find(i => i.term === this.duration);

      return (rate && rate.value) || this.defaultInsuranceRate;
    },

    toFinance() {
      const difference = this.amount - this.downPayment;

      return (difference + this.administrationCost) / (1 - this.insuranceRate - this.tax);
    },

    vfmg() {
      const vfmgRatio = this.configuration.vfmgRatio;

      return this.intelligent ? (vfmgRatio * this.amount) : 0;
    },

    monthlyPayment() {
      return this.intelligent ?
        this.monthlyPayments.intelligentMonthlyPayment(this.amount, this.downPayment, this.term) :
        this.monthlyPayments.conventionalMonthlyPayment(this.amount, this.downPayment, this.term);
    },

    equivalentAnnualCharge() {
      const flows = Array(this.term).fill(this.monthlyPayment);
      if (this.intelligent) flows.push(this.vfmg);
      const initialInvestment = this.amount - this.downPayment;
      const eac = Math.abs(this.irr(-initialInvestment, ...flows)) * 12;

      return isFinite(eac) ? Math.round(eac * 10) / 10 : '-';
    },

    totalCost() {
      return this.monthlyPayment * this.term + this.vfmg;
    },

    minAmount() {
      const min = Math.min(...this.product.configurations.map(config => config.minAmount));

      return Math.max(Math.ceil(min / this.AMOUNT_INTERVAL) * this.AMOUNT_INTERVAL, this.userMinAmount);
    },

    maxAmount() {
      let max = Math.max(...this.product.configurations.map(config => config.maxAmount));
      max = max * (1 - this.insuranceRate - this.tax) - this.administrationCost;

      return Math.min(Math.floor(max / this.AMOUNT_INTERVAL) * this.AMOUNT_INTERVAL, this.userMaxAmount);
    },

    sliderOptions() {
      return {
        width: '100%',
        height: 8,
        dotSize: 20,
        tooltip: false,
        sliderStyle: {
          border: '2px solid #FF520F',
          boxShadow: 'none',
        },
        processStyle: {
          backgroundColor: '#FF520F',
        },
        formatter: '{value}%',
        realTime: true,
      };
    },

    amountSliderOptions() {
      return {
        ...this.sliderOptions,
        min: this.userMinAmount,
        max: this.maxAmount,
        interval: this.AMOUNT_INTERVAL,
      };
    },

    downPaymentSliderOptions() {
      return {
        ...this.sliderOptions,
        ...this.downPaymentRange,
      };
    },
    validSimulation() {
      return this.downPaymentPercentage >= this.initialDownPayment;
    },
    validConfigurationsOrNotPeru() {
      return this.$i18n.locale !== 'es-PE' || this.amountValidConfigs.length > 0;
    },
    defaultConventionalTermOptions() {
      if (this.$i18n.locale === 'es-PE') {
        return CONVENTIONAL_TERM_OPTIONS.concat(PERU_EXTRA_TERMS);
      }

      return CONVENTIONAL_TERM_OPTIONS;
    },
  },
  watch: {
    intelligent() {
      this.$nextTick(() => {
        if (this.intelligent && this.termOptions.indexOf(this.term) === -1) {
          this.term = this.termOptions[0];
        }
      });
    },
    amount: debounce(function () {
      if (this.amount < this.minAmount) {
        this.amount = this.minAmount;
      }
    }, DEBOUNCE_RATE),
  },
};
</script>

<style lang="scss" scoped>
  // stylelint-disable property-no-vendor-prefix
  @import '../styles/app/variables';

  .container {
    --font-size: 20px;
    --spacing: 17.5px;
  }

  .simulator {

    display: flex;
    background-color: $contrast-color;
    border-radius: 10px;
    flex-direction: column;
    padding: 20px;
    font-size: var(--font-size);
    color: $text-color;

    &__row {
      margin-bottom: var(--spacing);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--result {
        color: $primary-color;
        font-weight: 500;
        margin-bottom: 0;
      }

      &--info {
        margin-top: -15px;
        font-size: .7em;
        color: $light-color;
      }

      &--summary {
        font-size: .8em;
        margin: 0 0 20px;
        color: $light-color;
      }

      &--error {
        margin-top: -20px;
        font-size: .6em;
        color: $error-color;
      }

      &--button {
        margin-top: 1em;
        text-align: center;
        width: 100%;
      }

      &--disclaimer {
        font-size: .7em;
        color: $light-color;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__slider-container {
      margin: 0 -10px;
      width: calc(100% + 20px);
    }

    &__input-container {
      position: relative;
    }

    &__input-suffix {
      position: absolute;
      right: 0;
      top: 1px;
      pointer-events: none;
    }

    &__input {
      background: transparent;
      width: 6.25em;
      text-align: right;
      font-size: 1em;
      border: 0;
      border-bottom: .1em solid $primary-color;
      border-radius: 0;
      margin: 0;
      padding: 0;

      &--with-suffix {
        padding-right: .8em;
      }

      &--down-payment {
        width: 2.5em;
      }
    }

    &__select {
      position: relative;

      select {
        padding-right: .9em;
        font-size: 1em;
        background: none;
        border: 0;
        border-bottom: .1em solid $primary-color;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        cursor: pointer;
      }
    }

    &__select-arrows {
      position: absolute;
      right: -6px;
      font-size: 1.2em;
      pointer-events: none;
      color: $primary-color;

      &::before {
        content: 'arrow_drop_up';
        font-family: 'Material Icons';
        position: absolute;
        top: -.167em;
        right: 0;
      }

      &::after {
        content: 'arrow_drop_down';
        font-family: 'Material Icons';
        position: absolute;
        top: .125em;
        right: 0;
      }
    }

    &__separator {
      width: 100%;
      border: .05em solid $very-light-color;
      background-color: $very-light-color;
      margin: 24px 0;
    }

    &__cae {
      position: relative;
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      margin-left: auto;
    }

    &__total {
      margin-right: 20px;
    }

    &__cae-icon {
      cursor: pointer;
      margin-left: 5px;
      font-size: 1.25em;
    }

    &__cae-bubble {
      position: absolute;
      right: 0;
      bottom: 32px;
      width: 418px;
      white-space: normal;
      font-size: .8125em;
      line-height: 1.307em;
      background-color: #eee;
      padding: 14px;
      border-radius: 4px;
      pointer-events: none;
      opacity: 0;
      transition: opacity .2s ease;

      &--visible {
        opacity: 1;
      }
    }

    &__cae-bubble-arrow {
      position: absolute;
      font-size: 1.65em;
      bottom: -19px;
      right: -5px;
    }
  }

  @media only screen and (max-width: 768px) {
    .simulator {
      &__cae-bubble {
        width: calc(100vw - 58px);
      }
    }
  }
</style>
