<template>
  <ValidationObserver ref="form">
    <div class="form">
      <div class="form__icon form__icon--success"></div>
      <div class="form__row form__row--title">
        <div class="form__title">
          <b>¡Ya seleccionaste tu opción de crédito!</b>
        </div>
        <div class="form__subtitle">
          Completa los siguientes datos para continuar con tu <b>simulación de crédito.</b>
        </div>
      </div>
      <div class="form__horizontal form__horizontal--input">
        <form-input
          rules="rut|required"
          name="identifier"
        >
          <text-input
            v-model="form.identifier"
            placeholder="Rut"
            :is-rut="true"
          />
        </form-input>
        <form-input
          rules="required|email"
          name="Correo Electrónico"
        >
          <text-input v-model="form.email" placeholder="Email"/>
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|alpha_spaces"
          name="nombre"
        >
          <text-input v-model="form.name" placeholder="Nombre"/>
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|alpha_spaces"
          name="Apellido Paterno"
        >
          <text-input v-model="form.fatherSurname" placeholder="Apellido Paterno"/>
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required|alpha_spaces"
          name="Apellido Materno"
        >
          <text-input v-model="form.motherSurname" placeholder="Apellido Materno"/>
        </form-input>
      </div>
      <div class="form__horizontal form__horizontal__input">
        <form-input
          rules="required"
          name="Móvil"
        >
          <text-input v-model="form.phone" v-mask="'+(56)#########'" placeholder="Móvil"/>
        </form-input>
        <form-input
          :rules="`required|min_income_cl_mask:${minIncome}`"
          name="Renta Líquida"
        >
          <text-input placeholder="Renta Líquida" v-model.lazy="incomeMask" v-money="incomeMask !== null ? money : null"/>
        </form-input>
      </div>
      <div class="form__row form__row--input">
        <form-input
          rules="required"
          name="Nacionalidad"
        >
          <select-input :options="['Chileno', 'Extranjero']" v-model="form.nationality" placeholder="Seleccione nacionalidad"/>
        </form-input>
      </div>
      <div
        class="form__row form__row--input"
        v-if="showVehicleCondition === 'show'"
      >
        <form-input
          rules="required"
          name="Estado del auto"
        >
          <select-input
            :placeholder="'Seleccione Tipo de Auto'"
            :options="vehicleConditions"
            :disabled="disableType"
            v-model="selectedVehicleCondition"
          />
        </form-input>
      </div>
      <div class="form__row form__row--input" v-show="!disableBrand">
        <form-input
          rules="required"
          name="Marca del auto"
        >
          <select-input
            :placeholder="'Seleccione la Marca'"
            :optgroups="computedBrands"
            :disabled="disableBrand"
            v-model="selectedBrand"
          />
        </form-input>
      </div>
      <div
        v-if="vehiclePrice"
        class="form__row form__row--value"
      >
        <div class="form__input">
          Precio del auto:
        </div>
        <span class="form__amount">
          {{ $n(vehiclePrice, 'currency') }}
        </span>
      </div>
      <div
        v-if="downPayment"
        class="form__row form__row--value"
      >
        <div class="form__input">
          Monto del Pie:
        </div>
        <span>{{ $n(downPayment, 'currency') }}</span>
      </div>
      <div class="form__row form__row--terms">
        <input
          class="amicar-checkbox amicar-checkbox--gray"
          v-model="agreedTerms"
          type="checkbox"
          autocomplete="off"
        >
        <span class="form__terms">
          Estoy de acuerdo con los
          <a
            class="form__link"
            href="/terminos-y-condiciones"
            target="_blank"
          >Términos y Condiciones</a>
        </span>
        <span
          class="form__error form__error--left"
          v-if="!validTerms"
        >
          Debes aceptar los términos y condiciones
        </span>
      </div>
      <div class="form__row form__row--terms">
        <input
          class="amicar-checkbox amicar-checkbox--gray"
          v-model="agreedPolicies"
          type="checkbox"
          autocomplete="off"
        >
        <span class="form__terms">
          Autorizo el
          <a
            class="form__link"
            href="/politicas-de-privacidad"
            target="_blank"
          >Tratamiento de mis datos</a>
        </span>
        <span
          class="form__error form__error--left"
          v-if="!validPolicies"
        >
          Debes aceptar el tratamiento de datos
        </span>
      </div>
      <div class="form__row form__row--button">
        <button
          v-if="simulatorFirst"
          class="amicar-wrapper__button amicar-wrapper__button--back"
          :class="{'amicar-wrapper__button--no-margin': !simulatorFirst}"
          @click="$emit('toggle-form')"
        >
          VOLVER
        </button>
        <button
          id="simulator-form-submit"
          class="amicar-wrapper__button amicar-wrapper__button--send"
          :class="{'amicar-wrapper__button--no-margin': !simulatorFirst}"
          @click="sendData()"
        >
          ENVIAR
        </button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { VMoney } from 'v-money'
import { ValidationObserver } from 'vee-validate';
import './form/validation-rules';
import FormInput from '../components/form/form-input';
import TextInput from '../components/form/text-input';
import SelectInput from '../components/form/select-input';
import { MIN_INCOME } from '../constants/es-CL';
import { VEHICLE_CONDITION_OPTIONS } from '../constants/shared';

export default {
  components: {
    ValidationObserver,
    FormInput,
    TextInput,
    SelectInput,
    VMoney
  },
  props: {
    simulatorFirst: {
      type: Boolean,
      default: null,
    },
    vehiclePrice: {
      type: Number,
      default: 0,
    },
    downPayment: {
      type: Number,
      default: 0,
    },
    showVehicleCondition: {
      type: String,
      default: 'show',
    },
    brands: {
      type: Object,
      default: () => {},
    },
    initialForm: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    let queryParams = this.$route.query;
    if(queryParams.campaign === 'economicos'){
      this.selectedVehicleCondition = this.vehicleConditions.find(cond => cond.id === 'U');
      this.selectedBrand = this.brands.other[0];
      this.disableType = true;
      this.disableBrand = true;
    }else{
      this.selectedVehicleCondition = this.showVehicleCondition === 'show' ? "" : this.showVehicleCondition;
    }

    const cache = this.$cookies.get('answers');
    if (cache) {
      this.form = {
        ...this.form,
        identifier: cache.identifier,
        name: cache.name,
        fatherSurname: cache.fatherSurname,
        motherSurname: cache.motherSurname,
        email: cache.email,
        phone: cache.phone,
        income: cache.income,
        vehicleBrandId: cache.vehicleBrandId,
      };
    }
  },
  data() {
    return {
      form: {
        from: 'AMICAR.CL',
        identifier: '',
        nationality: "",
        name: '',
        fatherSurname: '',
        motherSurname: '',
        email: '',
        phone: '',
        income: null,
        workingCondition: 'dependiente',
        vehicleCondition: null,
        vehicleBrandId: '',
        vehicleBrand: '',
        workEntryDate: 2,
        vehicleYear: new Date().getFullYear(),
        tradeInCarValue: 0,
        vehicleType: 'LIVIANO-NUEVO'
      },
      minIncome: MIN_INCOME,
      vehicleConditions: VEHICLE_CONDITION_OPTIONS,
      loading: false,
      feedback: false,
      approved: false,
      agreedTerms: false,
      agreedPolicies: false,
      validTerms: true,
      validPolicies: true,
      selectedBrand: null,
      selectedVehicleCondition: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '$ ',
        suffix: '',
        precision: 0,
        masked: false
      },
      incomeMask: null,
      directives: { money: VMoney },
      disableType: false,
      disableBrand: false
    };
  },
  methods: {
    sendData() {
      this.$refs.form.validate().then(success => {
        if (!this.agreedTerms &&!this.agreedPolicies) {
          this.validTerms = false
          this.validPolicies = false
          return
        }else if (!this.agreedTerms) {
          this.validTerms = false
          return
        }else if (!this.agreedPolicies) {
          this.validPolicies = false
          return
        }
        if (!success) return;
        this.scrollToTop();
        const event = this.simulatorFirst ? 'send-info' : 'proceed';
        this.$emit(event, this.form);
      });
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
  },
  computed: {
    computedBrands() {
      const brands = Object.keys(this.brands)
      .filter(key => key.toLowerCase() !== 'other')
      .map(key => ({
        key,
        options: this.brands[key].filter(brand => brand.show)
      }));
      return brands;
    },
  },
  watch: {
    agreedTerms() {
      this.validTerms = this.agreedTerms;
    },
    agreedPolicies() {
      this.validPolicies = this.agreedPolicies;
    },
    incomeMask() {
      this.form.income = this.incomeMask.replace(/[^0-9]/g,'');
    },
    selectedBrand() {
      this.form.vehicleBrand = this.selectedBrand.name;
      this.form.vehicleBrandId = this.selectedBrand.indexaId;
    },
    selectedVehicleCondition() {
      this.form.vehicleCondition =
        this.selectedVehicleCondition && this.selectedVehicleCondition.id;
      if(this.selectedVehicleCondition){
        this.form.vehicleType = 'LIVIANO-' + this.selectedVehicleCondition.name.toUpperCase()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/app/variables';
@import './form/form';

.form {
  --font-size: 20px;
  --spacing: 17.5px;

  &__link {
    color: unset;
  }

  &__row {
    &--terms {
      color: $gray-color;
      display: flex;
      font-size: 1em;
      line-height: 30px;
    }
  }

  &__amount {
    color: $primary-color;
  }

  &__terms {
    font-size: .65em;
    padding-left: .6em;
  }

  &__icon {
    width: 60px;
    height: 60px;

    &--success {
      background: url("../assets/engranaje_azul.png") no-repeat center;
      background-size: cover;
    }
  }
}
</style>
