<template>
  <div class="credit-step">
    <div class="credit-step__title">
      Responde las siguientes preguntas
    </div>
    <div class="credit-step__subtitle">
      {{ question }}
    </div>
    <div class="credit-step-choice__choices">
      <div
        v-for="choice in choices"
        :key="choice"
        class="credit-step-choice__choice"
      >
        <input
          type="radio"
          :value="choice"
          v-model="selectedChoice"
          class="amicar-checkbox amicar-checkbox--primary credit-step-choice__checkbox"
        >
        <span class="credit-step-choice__label">{{ choice }}</span>
      </div>
    </div>
    <div class="credit-step__buttons">
      <div
        v-if="showBackButton"
        @click="$emit('back')"
        class="credit-step__button credit-step__button--small credit-step__button--gray"
      >
        back
      </div>
      <div
        @click="$emit('next')"
        class="credit-step__button credit-step__button--small"
      >
        {{ buttonPlaceholder }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonPlaceholder: {
      type: String,
      default: 'Continuar',
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      question: '¿Cuál es el nombre de tu madre?',
      choices: ['Romina', 'María José', 'Eugenia', 'Ángela', 'Ninguna de las Anteriores'],
      selectedChoice: '',
    };
  },
};
</script>
<style lang="scss" scoped>
.credit-step-choice {
  text-align: left;

  &__choices {
    padding-left: 14px;
  }

  &__choice {
    display: flex;
    font-size: 16px;
    line-height: 22px;
  }

  &__label {
    padding-left: 12px;
  }

  &__checkbox {
    margin: 3px 0;
  }
}
</style>
