import dayjs from 'dayjs';
import humps from 'humps';

class FormService {
  constructor() {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.defaultOptions = { headers: this.headers, method: 'POST' };
  }

  checkClientApproval(form) {
    return fetch(`${window.location.origin}/credit_evaluations`, {
      ...this.defaultOptions,
      body: JSON.stringify(
        {
          ...humps.decamelizeKeys(form),
          ...form,
          rut: form.identifier,
          lastname: form.fatherSurname,
          workType: form.workingCondition,
          loanType: form.creditType,
          channel: form.from,
          brandName: form.vehicleBrand,
          importer: this.getImporter(form.vehicleBrand),
          }
        ),
    })
      .then(res => res.json());
  }

  approveTermsDocument(form) {
    return fetch(`${window.location.origin}/approve_terms`, {
      ...this.defaultOptions,
      body: JSON.stringify(form),
    })
      .then(res => res.json());
  }

  simulateMonthlyPayments(form) {
    return fetch(`${window.location.origin}/monthly_payments`, {
      ...this.defaultOptions,
      body: JSON.stringify(this.normalizeSimulation(form)),
    })
      .then(res => res.json());
  }

  updateLead(body) {
    const url = `${window.location.origin}/lead`;
    return fetch(url, {
      ...this.defaultOptions,
      body: JSON.stringify(body),
    })
      .then(res => res.json());
  }

  normalizeSimulation(body) {
    const vehicleType = (body.vehicleCondition.localeCompare('N')==0) ? 'NU' : 'US';
    const loanType = (body.creditType.localeCompare('CC')==0) ? 'CONVENTIONAL' : 'SMART';
    const loanRateType = (body.creditType.localeCompare('CC')==0) ? 'CONVENTIONAL_NORMAL' : 'SMART_NORMAL';
    return {
      sellerIdentificationValue: '764137477',
      amicarExecutiveIdentificationValue: '764137477',
      customer: {
        identificationValue: '5337416-6',
        identificationTypeId: 1,
        email: 'integraciones@amicar.cl',
        contactEmail: 'integraciones@amicar.cl',
        workPhone: '+562 2495 1600',
        phone: '+562 2495 1600',
      },
      vehicle: {
        type: vehicleType,
        price: body.vehiclePrice
      },
      loan: {
        type: loanType,
        downPayment: body.downPayment,
        accessories: 0,
        tradeInCarValue: 0,
        term: body.term,
        startDate: dayjs().format('YYYY-MM-DD'),
        firstDueDate: dayjs().add(30, 'day').format('YYYY-MM-DD'),
        rateType: loanRateType,
        saleChannel: '829957000',
        saleChannelType: 'WEB',
        salesRoomId: 1,
        immediateOption: false,
        modifyTerm: [],
      }
    }
  }

  getImporter(brand) {
    const agBrands = ["BAIC", "BRILLIANCE", "MAHINDRA", "HYUNDAI"]
    const dercoBrands = ["HAVAL", "CHANGAN", "GREAT WALL", "JAC", "MAZDA", "SUZUKI", "RENAULT"]
    if(agBrands.includes(brand.toUpperCase())){
      return '79649140K'
    }else if(dercoBrands.includes(brand.toUpperCase())){
      return '829957000'
    }else{
      return ''
    }
  }
}

export default FormService;
